//-----------------------------------------------------------------
// Licensed Materials - Property of IBM
//
// WebSphere Commerce
//
// (C) Copyright IBM Corp. 2016 All Rights Reserved.
//
// US Government Users Restricted Rights - Use, duplication or
// disclosure restricted by GSA ADP Schedule Contract with
// IBM Corp.
//-----------------------------------------------------------------

var catalog = {
    init: function() {
        $(".js-hook-row").each(function () { 
            var quantity = $(this).find(".js-hook-multiple").html();
            var price = $(this).find(".js-hook-price").html();
            var compare = price / quantity;
            $(this).find(".js-hook-compare").html(compare.toFixed(2));
        });
        $(window).on('resize', function () {
            catalog.overflowArea();
        });
        catalog.overflowArea();
        
    	$('.js-hook-enter').bind('keypress',function(event){
    		if(event.keyCode==13){
    			  $(this).trigger('click');
        	}
    	});
        
    },
    /*catalogListView: function(catId, prodId, el){
        var div = $(el).closest('.js-hook-catEntry');
        var table = $(div).find('.js-hook-toggleTable');
        if ($(table).html().length > 0) {
            $('.js-hook-toggleTable').not($(table)).each(function(){
                if(!$(this).hasClass('flex__table-close')){
                    $(this).addClass('flex__table-close');
                }
            });
            $(table).toggleClass('flex__table-close');
            var btn = $(el).find('.js-hook-articleToggle').toggle();
            $('.js-hook-articleHide').not(btn).hide();
            $('.js-hook-articleShow').not(btn).show();
        } else {
            var timeOut = setTimeout(function() {
                $('.js-hook-spinner').show();
            }, 200);
            services.catalogListView(catId, prodId, catalog.catalogListViewCallback.bind(null, $(table), $(el), timeOut));
        }
    },
    catalogListViewCallback: function(el, button, timeOut, returnedAjaxData){
        clearTimeout(timeOut);
        setTimeout(function() {
            $('.js-hook-spinner').hide();
            $(el).html(returnedAjaxData);
            $(el).removeClass('flex__table-close');
            $('.js-hook-toggleTable').not($(el)).each(function(){
                if(!$(this).hasClass('flex__table-close')){
                    $(this).addClass('flex__table-close');
                }
            });
            $('.js-hook-articleHide').hide();
            $('.js-hook-articleShow').show();
            $(button).find('.js-hook-articleShow').hide();
            $(button).find('.js-hook-articleHide').show();
        }, 200);
    },  
    */
    
    catalogListView: function(catId, prodId, el){
        var div = $(el).closest('.js-hook-catEntry');
        var table = $(div).find('.js-hook-toggleTable');
        var showDiscontinuedProducts=true
        
        $('.js-hook-toggleTable').not($(table)).each(function(){
            if(!$(this).hasClass('hide')){
                $(this).addClass('hide');
            }
        });
        $(table).addClass('has-shadow');

        if ($(table).html().length > 0) {
            $(table).removeClass('hide');
            $('.js-hook-closeOverlay').show();
        } else {
            var timeOut = setTimeout(function() {
                $('.js-hook-spinner').show();
            }, 200);
            if(arguments.length>3) showDiscontinuedProducts=arguments[3];
            // console.log('catalogListView - showDiscontinuedProducts: '+showDiscontinuedProducts);
            services.catalogListView(catId, prodId, 'true', catalog.catalogListViewCallback.bind(null, $(table), $(el), timeOut), '', showDiscontinuedProducts);
        }
    },
    catalogListViewCallback: function(el, button, timeOut, returnedAjaxData){
        clearTimeout(timeOut);
        setTimeout(function() {
            $('.js-hook-toggleTable').addClass('hide');
            $('.js-hook-spinner').hide();
            $(el).removeClass('hide');
            $(el).html(returnedAjaxData);
            $('.js-hook-closeOverlay').show();
        }, 200);
    },
    customerProductItemsView: function(catId, prodId){
        var showDiscontinuedProducts=true
        if(arguments.length>2) showDiscontinuedProducts=arguments[2];
        services.catalogListView(catId, prodId, 'false', catalog.customerProductItemsViewCallback, '', showDiscontinuedProducts);
    },
    customerProductItemsViewCallback: function(returnedAjaxData) {
        $('.js-hook-catalog-list-ajax').html(returnedAjaxData);
    },
    updateArticleTable: function(el){
        var selected = $(el).find(":selected");
        var gitn = selected.attr("data-gitn");
        var quantity = selected.attr("data-quantityMultiple");
        var buyable = selected.attr("data-buyable");
        var price = selected.attr("data-price");
        var pack = selected.attr("data-package");
        var qtyunit = selected.attr("data-qtyunit");
        
        var row = $(el).parents(".js-hook-row");
        row.find(".js-hook-gitn").val(gitn);
        row.find(".js-hook-multiple").val(quantity);
        row.find(".js-hook-price").html(price);
        row.find(".js-hook-package").html(pack);
        row.find(".js-hook-qtyunit").html(qtyunit);

        var compare = price / quantity;
        row.find(".js-hook-compare").html(compare.toFixed(2));
        
        if(buyable === "true"){
            row.find(".js-hook-buy").show();
        }else{
            row.find(".js-hook-buy").hide();
        }
    },
    updateComparePrice: function(){
        var row = $(".js-hook-row");
        var p = price / quantity;
        row.find(".js-hook-compare").html(p);
    },
    selectArticleTable: function(el){
        $(".js-hook-row").not($(el)).removeClass("flex-table__active");
        $(el).toggleClass("flex-table__active");
    },
    toggleRowArticleTable: function(el){
        $(el).closest('.js-hook-row').toggleClass('flex-table__close_mobile', 250);
    },
    toggleGtinArticleTable: function(el, productId){
        $(el).closest('.js-hook-gtin-row').toggleClass('flex-table__close', 100);
        $(el).closest('.js-hook-row').find('.js-hook-gtin-plus').toggle();
        $(el).closest('.js-hook-row').find('.js-hook-gtin-minus').toggle();
    },
    toggleGtinArticleRows: function(el) {
        var productId = $(el).closest('.js-hook-gtin-row').data('productid');

        $('.js-hook-gtin-row[data-productId="' + productId + '"]')
            .not($(el).closest('.js-hook-gtin-row'))
            .toggleClass('standard-table__row_gtin_closed');

        $(el).closest('.js-hook-gtin-row').find('.js-hook-gtin-plus').toggle();
        $(el).closest('.js-hook-gtin-row').find('.js-hook-gtin-minus').toggle();

        product.loadAdditionalArticleData();
    },
    addItemToCart: function(el){
        var productId = $(el).attr('productId');
        var row = $(el).closest(".js-hook-row");
        var quantity = row.find(".js-hook-quantity").val();
        var partnumber = row.find(".js-hook-partnumber").val();
        var gitn = row.find(".js-hook-gitn").val()
        
        if(quantity.length == 0){
            quantity=1;
        }
        if(isNaN(quantity) || quantity < 0){
            MessageHelper.displayErrorMessage(MessageHelper.messages["QUANTITY_INPUT_ERROR"]);
            return false;
        }
                
        var orderMultiple = row.find(".js-hook-quantity").data('ordermultiple');
        if(jQuery.isEmptyObject(orderMultiple)){
            orderMultiple=1;
        }
        
        quantity = quantity * orderMultiple;
        
        if(partnumber === row.find(".js-hook-purchase-number").val() || typeof gitn  === "undefined") {
        	gitn = "";
        }
        
        var goodsMark = row.find(".js-hook-goodsmark").val();
        console.log(productId, quantity,  gitn, goodsMark, orderMultiple);
        // row.find('.js-hook-buy').removeClass('button_buy_added');
        // row.find('.js-buy-check').hide();
        row.find('.js-buy-spinner').css('display','inline-block');
        
        try {
            var GA4Url = $(el).attr('ga4Url');
            var GA4Item = $(el).attr('ga4Item');
            if(typeof GA4Url != "undefined" && GA4Url != "false" && typeof GA4Item != "undefined" && GA4Item != "false") {
    	        var ga4Bypass = $(el).attr('ga4Bypass');
    	        var ga4MId = $(el).attr('ga4MId');
    	        var currency = WCParamJS.commandContextCurrency;
    	        var priceHook = row.find(".js-hook-price");
    	        var price = "0.0";
    	        if(priceHook.length > 0) {
    	        	var priceex = /[^0-9\,\.]+/g;
    		        price = priceHook[0].textContent;
    		        price = price.replace(priceex, "");
    		        price = price.replace(",", ".");
    	        }
    	    	var ga4Data = {};
    	    	ga4Data["url"] = GA4Url;
    	    	ga4Data["item"] = GA4Item;
    	    	ga4Data["price"] = +price;
    	    	ga4Data["currency"] = currency;
    	    	ga4Data["quantity"] = quantity;
    	    	ga4Data["bypass"] = ga4Bypass != "";
    	    	ga4Data["ga4MId"] = ga4MId;
    	    	analytics.ga4EventStart(ga4Data, true);
            }
        } catch(error){
        	console.log("Ga4 failed: ", error);
        }
        services.addItemToCart(productId, partnumber, quantity, goodsMark, gitn, catalog.addItemToCartCallback);
    },
    addAllItemsToCart: function(el){
    	var table = $(el).closest(".js-hook-table");
    	var buyCounter = 0;
    	var url = "";
    	var gaData = {items:[]};
    	var animationSpinner = $(el).find('.js-buy-spinner');
    	var animationCheck = $(el).find('.js-buy-check');
    	var animationCross = $(el).find('.js-buy-cross');
    	
    	$(animationCheck).css('display','none');
		$(animationCross).css('display','none');
    	//$(animationSpinner).css('display','inline-block');
    	
    	$('.js-hook-row', $(table)).each(function () { 
            var row = $(this);
            var productId = row.find('.js-hook-buy').attr('productId');
            var quantity = row.find(".js-hook-quantity").val();
            var partnumber = row.find(".js-hook-partnumber").val();
            
            if(quantity.length == 0 || isNaN(quantity) || quantity < 0){
                return;
            }
            
            var orderMultiple = row.find(".js-hook-quantity").data('ordermultiple');
            if(jQuery.isEmptyObject(orderMultiple)){
            	orderMultiple=1;
            }
            buyCounter++;
            quantity = quantity * orderMultiple;

            var gitn = row.find(".js-hook-gitn").val();
            var goodsMark = row.find(".js-hook-goodsmark").val();
           	goodsMark = encodeURI(goodsMark);
 
           	console.log(productId, quantity,  gitn, goodsMark, orderMultiple);
            //row.find('.js-buy-spinner').css('display','inline-block');
            
            // GA
            gaData.items.push(
            		{id: partnumber, quantity: quantity}
            );
            
            // Parameters
            url +=  "&productId_" + buyCounter  + "=" + productId
            + "&partnumber_" + buyCounter + "=" + partnumber
            +  "&quantity_" + buyCounter  + "=" + quantity;
            
            if(partnumber === row.find(".js-hook-purchase-number").val() || typeof gitn  === "undefined") {
            	gitn = "";
            } else {
            	 url += "&gtin_" + buyCounter  + "=" + gitn;
            }
                    
            if(typeof goodsMark  !== "undefined")
            {
            	url += "&goodsmark_" + buyCounter  +"=" + goodsMark;
            }
            
        });
    	if(buyCounter==0)
    	{
    		MessageHelper.displayErrorMessage(MessageHelper.messages["ARTICLE_LIST_BUY_ALL_ERROR_EMPTY"]);
    		$(animationSpinner).css('display','none');
    		$(animationCheck).css('display','none');
    		//$(animationCross).css('display','inline-block');
    		return false;
    	} else {
    		var promise = services.addItemsToCart(url,function(){});
            promise.done(function(data){
            	$(animationSpinner).css('display','none');
            	//$(animationCheck).css('display','inline-block');
            	
            	// GA
        		GoogleAnalytics.addAllToCart(gaData);
                analytics.publishCartAction();
        		
        		if(buyCounter==1) {
            		catalog.messageHandler('addCart', data);
        		} else {
        			catalog.messageHandler('addCartAll', data);
            	}
            });
	     }
    
    },
    
    addItemToCartCallback: function(returnedAjaxData, id) {
        /*if(success){
            setTimeout(function() {
                $('.js-hook-buy[productid=' + id + '] .js-buy-spinner').hide();
                $('.js-hook-buy[productid=' + id + '] .js-buy-check').css('display','inline-block');
            }, 1000);
            
            setTimeout(function() {
                $('.js-hook-buy[productid=' + id + '] .js-buy-check').fadeOut();
            }, 2500);
        }
        else {
            var data = services.JSONParse(returnedAjaxData);
            MessageHelper.displayErrorMessage(data.errorMessage);
            setTimeout(function() {
                $('.js-hook-buy[productid=' + id + '] .js-buy-spinner').hide();
                $('.js-hook-buy[productid=' + id + '] .js-buy-cross').css('display','inline-block');
                $('.js-hook-buy[productid=' + id + '] .js-buy-cross').css('display','inline-block');
                if(data.errorMessageParam[0] === 'quantity'){
                    $('.js-hook-row[data-productid=' + id + '] .js-hook-quantity').focus();
                    $('.js-hook-errorMultiple').html(data.errorMessageParam[1]);
                    $('.js-hook-tableError').show();
//                  $('.js-hook-row[data-productid=' + id + ']').before('<div>' + data.errorMessageKey + '</div>');
                }
            }, 1000);
            
            setTimeout(function() {
                $('.js-hook-buy[productid=' + id + '] .js-buy-cross').fadeOut();
            }, 2500);
        }*/
        $('.js-hook-buy[productid=' + id + '] .js-buy-spinner').hide();
        // $('.js-hook-buy[productid=' + id + '] .js-buy-check').css('display','inline-block');
        // $('.js-hook-buy[productid=' + id + ']').addClass('button_buy_added');
        catalog.messageHandler('addCart', returnedAjaxData); 
    },
    createRequisitionList: function(el){
        var status = $(el).closest(".js-hook-table").find(".js-hook-reqListStatus").val();
        var name = $(el).closest(".js-hook-table").find(".js-hook-reqListName").val();
        // $(el).find(".js-createRequisition-spinner").show();
        if(name != undefined){
        	services.createRequisitionList(status, name, catalog.messageHandler.bind(null, 'createList'));
        } else {
        	alert('!');
        }
    },
    createRequisitionListCallback: function(data){
	    $(".js-hook-reqList").append($('<option>', {
	        value: data.requisitionListId,
	        text: data.name
	    }));
	    $(".js-hook-reqListName").val("");
	},
    createAddRequisitionList: function(el){
    	var msg = "createList";
        var status = $(el).closest(".js-hook-reqListContainer").find(".js-hook-reqListStatus").val();
        var name = $(el).closest(".js-hook-reqListContainer").find(".js-hook-reqListName").val();
        
        console.log(name);
        if(name != ""){
        	var promise = services.createRequisitionList(status, name, catalog.createRequisitionListCallback);
            promise.done(function(data){
            	if(data.errorMessageKey === undefined){
            		//catalog.addItemToRequisitionList($(el), data.requisitionListId);
            		var params = catalog.getRequisitionListItems($(el), data.requisitionListId);
        			if(!$.isEmptyObject(params)){
            			var promise = services.addItemToRequisitionList($.param(params), data.requisitionListId, catalog.calculateOrderItems);
            	        promise.done(function(returnedAjaxData){
            	        	catalog.messageHandler("addItem", returnedAjaxData);
            	        });
            		} else {
            			MessageHelper.displayConfirmMessage(MessageHelper.messages["REQUISITIONLIST_CREATE_SUCCESS"]);
            		}
            	} else {
            		catalog.messageHandler(msg, data);
            	}
            });
        } else {
        	MessageHelper.displayErrorMessage(MessageHelper.messages["LIST_TABLE_NAME_EMPTY"]);
        }
    },
    addItemToRequisitionList: function(el){
    	var msg = "addItem";
		var orderId = $(el).closest('.js-hook-reqListContainer').find('.js-hook-reqList').val();
		var params = catalog.getRequisitionListItems($(el), orderId);

		if(!$.isEmptyObject(params)){
			var promise = services.addItemToRequisitionList($.param(params), orderId, catalog.calculateOrderItems);
	        promise.done(function(returnedAjaxData){
	        	catalog.messageHandler(msg, returnedAjaxData);
	        });
		} else {
			MessageHelper.displayErrorMessage(MessageHelper.messages["QUANTITY_INPUT_ERROR"]);
		}
    },
    getRequisitionListItems: function(el, orderId){
    	var msg = "addItem";
		var params = {};
		var index = 0;
		
		$(el).closest(".js-hook-table").find(".js-hook-row").each(function(){
			var quantity = $(this).find(".js-hook-quantity").val();
			if(isNaN(quantity)){
                MessageHelper.displayErrorMessage(MessageHelper.messages["QUANTITY_INPUT_ERROR"]);
                return false;
            } else if(quantity !== "" && quantity > 0){
				params["catEntryId_" + index] = $(this).data("productid");
    			var gtin = $(this).find(".js-hook-gitn").val();
    			var goodsmark = $(this).find(".js-hook-goodsmark").val();
    			if(gtin != undefined && gtin != ""){
    				params["field2_" + index] = gtin;
    				params["field1_" + index] = quantity;
    				params["quantity_" + index] = $(this).find(".js-hook-multiple").val();
    			} else {
    				params["quantity_" + index] = quantity;
    			}
				params["description_" + index] = $(this).find(".js-hook-goodsmark").val();
    			index++;
			}
		});

		return params;
    },
    calculateOrderItems: function(data){
    	services.calculateOrderItems(data.orderId.toString(), function(){});
    },
    messageHandler: function(msg, data){
        if(typeof data !== 'object'){
            data = services.JSONParse(data);
        }
        var msgKey = data.errorMessageKey;
        if(msgKey !== undefined){
            if (msgKey == '_ERR_CMD_BAD_PARAMETER'){
                MessageHelper.displayErrorMessage(MessageHelper.messages["ERROR_QUANTITY_MULTIPLE"] + ' ' + data.errorMessageParam[1] + '.');
            } else if (msgKey == '_ERR_GETTING_SKU' || msgKey =='_ERR_PROD_NOT_EXISTING') {
                MessageHelper.displayErrorMessage(MessageHelper.messages["ERROR_REQUISITION_LIST_INVALID_SKU"]);
            } else if (msgKey == '_ERR_PROD_NOT_ORDERABLE') {
                MessageHelper.displayErrorMessage(MessageHelper.messages["NOT_ORDERABLE_ERROR"]);
            } else if (msgKey == '_ERR_RETRIEVE_PRICE') {
                MessageHelper.displayErrorMessage(MessageHelper.messages["NOT_RETRIEVED_PRICE_ERROR"]);
            } else if (msgKey == '_ERR_ORDER_IS_LOCKED') {
                MessageHelper.displayErrorMessage(MessageHelper.messages["ORDER_LOCKED_ERROR"]);
            } else if (msgKey == '_ERR_ORDER_IS_NOT_LOCKED') { 
                MessageHelper.displayErrorMessage(MessageHelper.messages["ORDER_NOT_LOCKED_ERROR"]);
            } else {
                MessageHelper.displayErrorMessage(data.errorMessage);
            }
        } else if(msg == "addItem"){
            MessageHelper.displayConfirmMessage(MessageHelper.messages["REQUISITIONLIST_ADD_SUCCESS"]);
        } else if(msg == "createList"){
            MessageHelper.displayConfirmMessage(MessageHelper.messages["REQUISITIONLIST_CREATE_SUCCESS"]);
        } else if(msg == "addCart"){
            MessageHelper.displayConfirmMessage(MessageHelper.messages["ADDED_TO_ORDER"]);
            console.log(data);
            GoogleAnalytics.addToCart(data, msg);
            analytics.publishCartAction();
        } else if(msg == "addCartAll"){
            MessageHelper.displayConfirmMessage(MessageHelper.messages["ADDED_ALL_TO_ORDER"]);
        }
        
    },
    toggleRequisitionList: function(el){
        var div = $(el).closest(".js-hook-table").find(".js-hook-reqListContainer");
        div.toggleClass('hide');
        $(".js-hook-reqListContainer").not(div).addClass('hide');
        $(".js-createRequisition-spinner").hide();
        $(".js-createRequisition-check").hide();
        $(".js-createRequisition-cross").hide();
        $(".js-addRequisition-spinner").hide();
        $(".js-addRequisition-check").hide();
        $(".js-addRequisition-cross").hide();
    },
    toggleOverflowArea: function(el) {
        var overflowArea = $('#' + $(el).data('id'));
        $(overflowArea).toggleClass('active');
        $('a[data-id=' + $(el).data('id') + ']').toggleClass('hide');
    },
    overflowArea: function() {
        $('.js-overflow-area').each(function() {
            if(catalog.hasOverflow(this)) {
                $(this).find('.js-overflow-fade-out').addClass('active');
                $(this).siblings('.js-show-more').removeClass('hide');
                $(this).siblings('.js-show-more').addClass('active');
            }
        });
    },
    hasOverflow: function(el) {
        return el.offsetHeight + 5 < el.scrollHeight;
    },
    scrollToTabs: function() {
        //TODO: Fixa så den animerar sig ner till tabbarna
        $('#tab')[0].scrollIntoView();
    },
    toggleTab: function(el) {
        var tab = $(el).parent(),
            tabs = $('.tab__button').not(tab);

        tab.toggleClass('active');
        tabs.removeClass('active');
    },
    toggleTableTab: function(el, id) {
        var wrapper = $(el).closest('.tab__content_tech-table'),
            tab = wrapper.find('.js-tech-table[data-identifier="' + id + '"]'),
            index = tab.data('index'),
            tabs = wrapper.find('.js-tech-table').not(tab),
            table = wrapper.find('.js-tech-table-body[data-identifier="' + id + '"]'),
            tables = wrapper.find('.js-tech-table-body').not(table);

        tab.addClass('selected');
        tabs.removeClass('selected');
        table.addClass('selected');
        tables.removeClass('selected');
    },
    slide: function(index, active, next){
        $('.js-hook-slider').css({'transform': 'translateX(-' + index * 100 + '%)'});
        active.removeClass('js-hook-active');
        next.addClass('js-hook-active');
        $('.js-hook-sliderThumb').removeClass('slider__active');
        $('.js-hook-sliderThumb[data-index=' + index + ']').addClass('slider__active');
    },
    slidePos: function(index){
        var active = $('.js-hook-active');
        var next = $('.js-hook-slider [data-index=' + index + ']');
        catalog.slide(index,active, next);
    },
    slideLeft: function(){
        var active = $('.js-hook-active');
        var index = parseInt(active.attr('data-index')) - 1;
        var next = $('.js-hook-slider [data-index=' + index + ']');
        if(next.length === 0){
            index = $('.js-hook-slider').children().length - 1;
            next = $('.js-hook-slider [data-index=' + index + ']');
        }
        catalog.slide(index,active, next);
    },
    slideRight: function(){
        var active = $('.js-hook-active');
        var index = parseInt(active.attr('data-index')) + 1;
        var next = $('.js-hook-slider [data-index=' + index + ']');
        if(next.length === 0){
            index = 0;
            next = $('.js-hook-slider [data-index=' + index + ']');
        }
        catalog.slide(index,active, next);
    },
    showSliderImage: function(el){
        var src = $(el).find('img').attr('src');
        services.showSliderImage(src, catalog.showSliderImageCallback);
        $('.js-hook-closeOverlay').show();
    },
    hideSliderImage: function(){
        $('.js-hook-sliderImage').html('');
        $('.js-hook-closeOverlay').hide();
    },
    showSliderImageCallback: function(returnedAjax){
        $('.js-hook-sliderImage').html(returnedAjax);
    },

    slideCarousel: function(el) {
        var slider = $(el).closest('.js-hook-carouselSlider').find('.slider__items'),
            controlPrev = $(el).closest('.js-hook-carouselSlider').find('.js-hook-sliderControl-prev'),
            controlNext = $(el).closest('.js-hook-carouselSlider').find('.js-hook-sliderControl-next'),
            id = slider.data('id') || 'slideCarousel' + util.increment(),
            itemCount = slider.children().length;

        slider.data('id', id);

        $(window)
            .off('resize.'+id)
            .on('resize.'+id, _moveSlider());

        function _moveSlider(action) {
            return function() {
                var index = slider.data('index') || 0,
                    sliderOuterWidth = slider[0].getBoundingClientRect().width,
                    itemWidth = slider.children('li')[0].getBoundingClientRect().width,
                    itemsPerPage = Math.floor(sliderOuterWidth / itemWidth);

                slider.toggleClass('slider__items_animated', typeof action === 'string');

                if (typeof action === 'string') {
                    index += action === 'next' ? itemsPerPage : -Math.abs(itemsPerPage);
                }

                if (index > itemCount - itemsPerPage) {
                    index = itemCount - itemsPerPage;
                } else if (index < 0) {
                    index = 0;
                }

                controlPrev.toggleClass('slider__control_inactive', index === 0);
                controlNext.toggleClass('slider__control_inactive', index === itemCount - itemsPerPage);

                slider.data('index', index);
                slider.css({ transform: 'translateX(-' + (index / itemsPerPage) * 100 + '%)' });
            }
        }

        return {
            next: _moveSlider('next'),
            prev: _moveSlider('prev')
        }
    }

    /*,
    countLines: function(){
        $(".media-container__left-box_info").addClass("media-container__static-width");
        var divHeight = $(".media-container__left-box_info > p").prop('scrollHeight');
        var lineHeight = $(".media-container__left-box_info").css('line-height');
        lineHeight = lineHeight.replace(/[^-\d\.]/g, '');
        var lines = divHeight / lineHeight;
        if(!isNaN(lines) &&  lines > 4){
            $(".js-hook-showMore").removeClass("hide");
        }
        $(".media-container__left-box_info").removeClass("media-container__static-width");
    }*/
};
