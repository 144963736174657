//-----------------------------------------------------------------
// Licensed Materials - Property of IBM
//
// WebSphere Commerce
//
// (C) Copyright IBM Corp. 2016 All Rights Reserved.
//
// US Government Users Restricted Rights - Use, duplication or
// disclosure restricted by GSA ADP Schedule Contract with
// IBM Corp.
//-----------------------------------------------------------------

var espot = {
    /**
     * Adjusts the free flow absolut width.
     */

    freeFlowBackgroundSize: function(element) {
        $(document).on('ready', function() {
            _adjustEspotWidth();
            $(window).resize(_adjustEspotWidth);
        });

        function _adjustEspotWidth() {
            var windowWidth = $(window).width();

            $(element).css({
                width: windowWidth,
                left: 'calc(50% - ' + windowWidth / 2 + 'px)'
            });
        }
    },
    mobileToggleList: function(element) {
        if ($(element).hasClass('show')) {
            $('.espot__list__title').removeClass('show');
        } else {
            $('.espot__list__title').removeClass('show');
            $(element).addClass('show');
        }
    }
    
};
