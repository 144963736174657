//-----------------------------------------------------------------
// Licensed Materials - Property of IBM
//
// WebSphere Commerce
//
// (C) Copyright IBM Corp. 2016 All Rights Reserved.
//
// US Government Users Restricted Rights - Use, duplication or
// disclosure restricted by GSA ADP Schedule Contract with
// IBM Corp.
//-----------------------------------------------------------------

var userpage = {
    orderId: '',
    widgetName: '',
    timeOut: undefined,
    msg: null,
    highestIndex: 0,
    templates: {},

    saveTemplate: function(id) {
        $(document).ready(function() {
            userpage.templates[id] = _.template($('#' + id).html());
            $('#' + id).remove();
        });
    },

    init: function() {
        userpage.organizationBuyerView($('.js-hook-organizationDropDown').val(), $('.js-hook-organizationDropDown option:first').text());

        $('.js-hook-sob .js-hook-sob-logonIdSearch').on('input', function() {
            userpage.shopOnBehalfAutoSuggest();
        });

        $('.js-hook-sob .js-hook-sob-firstNameSearch').on('input', function() {
            userpage.shopOnBehalfAutoSuggest();
        });

        $('.js-hook-sob .js-hook-sob-lastNameSearch').on('input', function() {
            userpage.shopOnBehalfAutoSuggest();
        });
        
        $('#WC_ReturnAndComplaintsPage_FormInput_phoneNumber').keypress(function(e) {
        	if(e.which == 13) {
        		userpage.contactInfoEntered();
        	}
        });
    },

    setCommonParams: function(orderId, widgetName) {
        userpage.orderId = orderId;
        userpage.widgetName = widgetName;
    },

    updateList: function() {
        $('.js-hook-listTitle').html($('.js-hook-infoName').val());
    },

    addListToCart: function(orderId) {
        this.timeOut = setTimeout(function() {
            $('.js-hook-spinner').show();
        }, 200);

        services.addListToCart(orderId, userpage.addListToCartCallback);
    },

    addItemsToCart: function() {
        this.timeOut = setTimeout(function() {
            $('.js-hook-spinner').show();
        }, 200);

        services.addItemsToCart($('.standard-table__row_article input, .standard-table__row_article select').serialize(), userpage.addListToCartCallback);
    },

    setCurrentPendingOrder: function() {
        this.timeOut = setTimeout(function() {
            $('.js-hook-spinner').show();
        }, 200);

        services.setCurrentPendingOrder(userpage.orderId, userpage.addListToCartCallback);
    },

    addListToCartCallback: function(returnedAjaxData) {
        clearTimeout(this.timeOut);

        setTimeout(function() {
            var data = services.JSONParse(returnedAjaxData),
                msgKey = data.errorMessageKey;

            $('.js-hook-spinner').hide();

            if (msgKey !== undefined) {
                if (msgKey === '_ERR_GETTING_SKU' || msgKey ==='_ERR_PROD_NOT_EXISTING') {
                    MessageHelper.displayErrorMessage(MessageHelper.messages['ERROR_INVALID_SKU']);
                } else if (msgKey === '_ERR_RETRIEVE_PRICE' || msgKey === '_ERR_PROD_NOT_ORDERABLE') {
                    MessageHelper.displayErrorMessage(MessageHelper.messages['NOT_ORDERABLE_ERROR']);
                } else if (msgKey === '_ERR_CMD_BAD_PARAMETER') {
                    MessageHelper.displayErrorMessage(MessageHelper.messages['ERROR_ARTICLE'] + ' ' + data.errorMessageParam[0] + ': ' + MessageHelper.messages['ERROR_QUANTITY_MULTIPLE'] + ' ' + data.errorMessageParam[1] + '.');
                } else {
                    MessageHelper.displayErrorMessage(data.errorMessage);
                }
            } else {
                MessageHelper.displayStatusMessage(MessageHelper.messages['ADDED_TO_ORDER']);
                // analytics.publishCartAction().done(document.location.href='RESTOrderCalculate?calculationUsage=-1,-2,-3,-4,-5,-6,-7&updatePrices=1&doConfigurationValidation=Y&errorViewName=AjaxOrderItemDisplayView&orderId=.&langId=' + data.langId + '&storeId=' + data.storeId + '&catalogId=' + data.catalogId + '&URL=AjaxOrderItemDisplayView');
                analytics.publishCartAction().done();
            }
        }, 200);
    },

    filterOrderHistory: function() {
        var validDate = /(19|20)[0-9]{2}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])/,
            url = document.location.href,
            baseId = '#filterOrderHistory',
            element = {
                from: $(baseId + 'From'),
                to: $(baseId + 'To'),
                errorFrom: $(baseId + 'ErrorFrom'),
                errorTo: $(baseId + 'ErrorTo'),
                errorNone: $(baseId + 'ErrorNone')
            },
            from = typeof element.from.val() === 'string' && element.from.val().length > 0 ? element.from.val() : null,
            to   = typeof element.to.val()   === 'string' && element.to.val().length   > 0 ? element.to.val()   : null;

        $('.input-error-message').hide();

        if (from !== null && !validDate.test(from)) {
            element.errorFrom.show();
            return;
        }

        if (to !== null && !validDate.test(to)) {
            element.errorTo.show();
            return;
        }

        if (from === null && to === null && !/(start|end)Date/.test(url)) {
            element.errorNone.show();
            return;
        }

        setParam('startDate', from);
        setParam('endDate', to);

        window.location = url;

        function setParam(key, val) {
            var pattern = new RegExp('&' + key + '=[0-9]{4}-[0-9]{2}-[0-9]{2}');

            if (pattern.test(url)) {
                url = url.replace(pattern, val ? '&' + key + '=' + val : '');
            } else {
                url += val ? '&' + key + '=' + val : '';
            }
        }
    },

    setOrderHistoryParams: function() {
        var from = document.location.href.match(/\&startDate\=([0-9]{4}-[0-9]{2}-[0-9]{2})(\&|)/),
            to   = document.location.href.match(/\&endDate\=([0-9]{4}-[0-9]{2}-[0-9]{2})(\&|)/);

        $('#filterOrderHistoryFrom').val(from && from[1] ? from[1] : '');
        $('#filterOrderHistoryTo').val(to && to[1] ? to[1] : '');
    },

    filterOrderHistoryByUser: function(){
	    var selectedName = $('.input_select').val().toLowerCase();
	    if(selectedName === 'show all'){
	    	$(".standard-table__main ul").show();
	    }else{
	    $(".standard-table__row_article").filter(function() {
	      $(this).toggle($(this).text().toLowerCase().indexOf(selectedName) > -1)
	    });
	 }},
    addOrderItem: function() {
        var partNumber, gtin, gtinQty, quantity,
            input = $('.js-hook-autoPartNumber').val().trim(),
            el = $('.js-hook-skuAddSearch[data-partNumber="' + input + '"]');

        this.msg = 'addItem';
        this.timeOut = setTimeout(function() {
            $('.js-hook-spinner').show();
        }, 200);
        
        
        
        partNumber = $('.js-hook-autoPartNumber').attr('data-partnumber');
        gtin = $('.js-hook-autoPartNumber').attr('data-gtin');
        if (el[0] === undefined) {
            el = $('.js-hook-skuAddSearch[data-gtin="' + gtin + '"]');
        }
        
        //gtinQty = $(el).data('qtymultiple');
      
        /* Om partnumber eller gtin inte är satt ges avslag */
        if (input != partNumber && gtin != input || input.length === 0 ) {
        	clearTimeout(this.timeOut);
            $('.js-hook-spinner').hide();
        	MessageHelper.displayErrorMessage("ERROR_REQUISITION_LIST_INPUT_NOT_VALID");   /* Ett fel meddelande  */
            return false;
        }
        
        if (partNumber === '' || partNumber === undefined) {
            clearTimeout(this.timeOut);
            $('.js-hook-spinner').hide();
            MessageHelper.displayErrorMessage(MessageHelper.messages['ERROR_REQUISITION_LIST_SKU_EMPTY']);
            return false;
        }

        quantity = $('.js-hook-autoQuantity').val();

        if (quantity === '') {
            quantity = 1;
        } else if (isNaN(quantity) || quantity < 0) {
            clearTimeout(this.timeOut);
            $('.js-hook-spinner').hide();
            MessageHelper.displayErrorMessage(MessageHelper.messages['ERROR_REQUISITION_LIST_QUANTITY_ONE_OR_MORE']);
            return false;
        }
        
        multiple = $(el).attr('data-qtymultiple');
        if(multiple != 0 && gtin == input){
        	gtinQty = quantity;
            quantity = multiple * quantity;
        } else {
        	gtinQty = "";
        	gtin = "";
        }
        
        if (userpage.widgetName === 'RequisitionListItems') {
            $.when(services.reqListAddItemByPartnumber(userpage.orderId, partNumber, quantity, gtin, gtinQty, userpage.addOrderListCallback));
        } else if (userpage.widgetName === 'SavedOrderItems') {
            $.when(services.savedOrderAddItem(userpage.orderId, partNumber, quantity, gtin, gtinQty, userpage.addOrderListCallback));
        }
    },

    removeOrderItem: function(orderItemId) {
        this.timeOut = setTimeout(function() {
            $('.js-hook-spinner').show();
        }, 200);

        this.msg = 'deleteItem';

        if (userpage.widgetName === 'RequisitionListItems') {
            $.when(services.reqListDeleteItem(userpage.orderId, orderItemId, userpage.orderListCallback));
        } else if (userpage.widgetName === 'SavedOrderItems') {
            $.when(services.savedOrderRemoveItem(userpage.orderId, orderItemId, userpage.orderListCallback));
        }
    },

    updateOrderItem: function(el, orderItemId, productId) {
    	//console.log("SIMON");
        this.timeOut = setTimeout(function() {
            $('.js-hook-spinner').show();
        }, 200);

        this.msg = 'updateQty';

        var gitnQty,
            orderId = $('.js-hook-reqList').val(),
            row = $(el).closest('.js-hook-row'),
            //gitn = $(row).find('.js-hook-gitn').val(),
            goodsmark = $(row).find('.js-hook-goodsmark').val(),
            quantity = $(row).find('.js-hook-quantity').val(),
            multiple = $(row).find('.js-hook-multiple').val();

        
        var select = $(row).find('.js-hook-gtinmenu');
        var partNumber = $(select).find(':selected').attr('data-partNumber');
        var purchaseNumber = $(select).find(':selected').attr('data-purchase-number');
        var gtin = $(select).find(':selected').attr('data-gitn');
        console.log(gtin);
        if (goodsmark === undefined) {
            goodsmark = '';
        }

        if (quantity === '') {
            quantity = 1;
        } else if (isNaN(quantity) || quantity < 0) {
            clearTimeout(this.timeOut);
            $('.js-hook-spinner').hide();
            MessageHelper.displayErrorMessage(MessageHelper.messages['QUANTITY_INPUT_ERROR']);

            return false;
        }
       
        if(typeof gtin  === "undefined") {
        	gtin = '';
        	gtinQty = '';
        	//gitnQty = quantity;
        	//console.log("SimonQ" + quantity);
        	console.log('gtinMenu');
        } else {
        	gtinQty = quantity;
            quantity = quantity * (multiple > 1 ? multiple : 1);
            //console.log('multiple' + $(row).find('.js-hook-multiple').val() + ' gtinQty : ' + gtinQty);
        }
       
        if (userpage.widgetName === 'RequisitionListItems') {
            $.when(services.reqListUpdateItem(userpage.orderId, orderItemId, productId, quantity, gtin, gtinQty, goodsmark, userpage.orderListCallback.bind(row, undefined)));
        } else if (userpage.widgetName === 'SavedOrderItems') {
            $.when(services.savedOrderUpdateItem(userpage.orderId, orderItemId, productId, quantity, gtin, gtinQty, goodsmark, userpage.orderListCallback.bind(row, undefined)));
        }
    },

    orderListCallback: function(itemid, returnedAjaxData){
    	//console.log("Simon1" + itemid);
        //console.log(returnedAjaxData);
        var row = $(this);
        if($(row)) {
	        var data = returnedAjaxData;
	        
	        if(typeof data !== 'object'){
	            data = services.JSONParse(returnedAjaxData);
	        }
	        	        
	        if(data && !data.errorMessageKey ) {
	        	var purchaseNumber = $(row).find('.js-hook-gtinmenu').find(':selected').attr('data-purchase-number');
	        	var gtin = $(row).find('.js-hook-gtinmenu').find(':selected').attr('data-gtin');
	        	
	            $(row).find('.js-hook-purchase-number').text(purchaseNumber);
	            if(gtin !== purchaseNumber) {
	            	 $(row).find('.js-hook-gtin').text(gtin);
	            } else {
	            	$(row).find('.js-hook-gtin').text('');
	            }
	        }
        }
        if (userpage.errorMessageHandler(returnedAjaxData)){
            services.calculateOrderItems(userpage.orderId, function() {}).done(function() {
                services.requisitionListView(userpage.orderId, userpage.widgetName, userpage.updateOrderListCallback);
            });
        }
    },

    addOrderListCallback: function(returnedAjaxData) {
        var category, conversionEventData,
            data = services.JSONParse(returnedAjaxData);

        if (userpage.widgetName === 'RequisitionListItems') {
            category = 'Ordermall';
        } else if (userpage.widgetName === 'SavedOrderItems') {
            category = 'Sparade Order';
        }

        if (data.partNumber !== undefined) {
            conversionEventData = {
                eventId: 'Artikelnummer: ' + data.partNumber,
                category: category,
                points: 10
            };
        } else {
            conversionEventData = {
                eventId: 'Ogiltig Artikelnummer: ' + $('.js-hook-autoPartNumber').val(),
                category: category,
                points: 0
            };
        }

        analytics.publishConversionEvent(conversionEventData, 1).done(function() {
            if (userpage.errorMessageHandler(returnedAjaxData)) {
                analytics.publishConversionEvent(conversionEventData, 2).done(function() {
                    services.calculateOrderItems(userpage.orderId, function() {}).done(function() {
                        services.requisitionListView(userpage.orderId, userpage.widgetName, userpage.updateOrderListCallback);
                    });
                });
            }
        });
    },
    
    updateOrderListCallback: function(returnedAjaxData) {
    	//console.log(returnedAjaxData);
        clearTimeout(this.timeOut);
        setTimeout(function() {
            $('.js-hook-reqListTable').html(returnedAjaxData);
            $('.js-hook-spinner').hide();
            //userpage.messageHandler(returnedAjaxData);
            MessageHelper.displayStatusMessage(MessageHelper.messages['QUANTITY_ITEM_UPDATE_SUCCESS']);
            //$('.js-hook-autoPartNumber').val('');
            //$('.js-hook-autoQuantity').val('');
        }, 200);
    },

    errorMessageHandler: function(returnedAjaxData) {
        var data = services.JSONParse(returnedAjaxData),
            msgKey = data.errorMessageKey;

        if (msgKey !== undefined) {
            if (msgKey === '_ERR_GETTING_SKU' || msgKey === '_ERR_PROD_NOT_EXISTING') {
                MessageHelper.displayErrorMessage(MessageHelper.messages['ERROR_INVALID_SKU']);
            } else if (msgKey === '_ERR_RETRIEVE_PRICE' || msgKey === '_ERR_PROD_NOT_ORDERABLE') {
                MessageHelper.displayErrorMessage(MessageHelper.messages['NOT_ORDERABLE_ERROR']);
            } else if (msgKey === '_ERR_CMD_BAD_PARAMETER') {
                MessageHelper.displayErrorMessage(MessageHelper.messages['ERROR_ARTICLE'] + ' ' + data.errorMessageParam[0] + ': ' + MessageHelper.messages['ERROR_QUANTITY_MULTIPLE'] + ' ' + data.errorMessageParam[1] + '.');
            } else {
                MessageHelper.displayErrorMessage(data.errorMessage);
            }

            clearTimeout(this.timeOut);

            $('.js-hook-spinner').hide();

            return false;
        }

        return true;
    },

    messageHandler: function(returnedAjaxData) {
        var data = data = services.JSONParse(returnedAjaxData),
            msgKey = data.errorMessageKey;

        if (msgKey !== undefined) {
            MessageHelper.displayErrorMessage(data.errorMessage);
        } else if (this.msg === 'addItem') {
            MessageHelper.displayStatusMessage(MessageHelper.messages['ADD_SUCCESS']);
        } else if (this.msg === 'deleteItem') {
            MessageHelper.displayStatusMessage(MessageHelper.messages['DELETE_SUCCESS']);
        } else if (this.msg === 'updateQty') {
            MessageHelper.displayStatusMessage(MessageHelper.messages['QUANTITY_ITEM_UPDATE_SUCCESS']);
        }
    },

    addOrderItemClick: function() {
        if (event.keyCode === 13) {
            userpage.addOrderItem();
        }
    },

    organizationBuyerView: function(organizationId, organizationName) {
        services.organizationBuyerView(organizationId, organizationName, userpage.organizationBuyerCallback);
    },

    organizationBuyerCallback: function(returnedAjaxData) {
        $('.js-hook-organizationSummary').html(returnedAjaxData);
    },

    loadAddressFormStatesUI: function(formName,paramPrefix,stateDiv, id, keepCurrentState, copyValue, addEmptyOption) {
        AddressHelper.getCountryArray();

        var currentCountryCode, select, statesData, input, opt,
            newid = paramPrefix + id,
            form = document.getElementById(formName),
            stateDivObj = document.getElementById(stateDiv),
            stateSelect = document.getElementById(newid),
            required = stateSelect.getAttribute('aria-required'),
            stateClass = stateSelect.getAttribute('class');

        if (copyValue != null && copyValue != 'undefined' && copyValue != '') {
            currentState = copyValue;
        }

        if (id === '_state1') {
            AddressHelper.setStateClass('form_input');
            currentCountryCode = form[paramPrefix + '_country'].value;
        } else {
            currentCountryCode = form[paramPrefix + 'country'].value;
        }

        if (countries[currentCountryCode] && countries[currentCountryCode].statesArray) {
            // switch to state list
            statesData = countries[currentCountryCode].statesArray;
            select = document.createElement('select');

            stateDivObj.innerHTML = '';
            select.id = newid;
            select.className = stateClass;
            select.name = 'state';
            select.setAttribute('aria-required', required);
            stateDivObj.appendChild(select);

            for (i = 0; i < statesData.length; i++) {
                opt = document.createElement('option');
                opt.innerHTML = statesData[i].label;
                opt.value = statesData[i].value;
                select.appendChild(opt);
            }
        } else {
            input = document.createElement('input');

            stateDivObj.innerHTML = '';
            input.id = newid;
            input.className = stateClass;
            input.name = 'state';
            input.setAttribute('aria-required', required);
            stateDivObj.appendChild(input);
        }
    },

    toggleRequisitionList: function(el) {
        var div = $(el).closest('.js-hook-row').find('.js-hook-reqListContainer');

        $('html').off('click.toggleRequisitionList');

        div.toggleClass('hide');

        $('.js-hook-reqListContainer').not(div).addClass('hide');

        if (div.hasClass('hide')) {
            $('.js-hook-closeOverlay').hide();
        } else {
            $('.js-hook-closeOverlay').show();

            setTimeout(function() {
                $('html').off('click.toggleRequisitionList').on('click.toggleRequisitionList', function() {
                    $('.js-hook-closeOverlay').hide();
                    $('html').off('click.toggleRequisitionList');
                    $(".js-hook-reqListContainer").addClass('hide');
                });
            });
        }
    },

    hideRequisitionList: function() {
        $('.js-hook-reqListContainer').addClass('hide');
        $('.js-hook-closeOverlay').hide();
    },

    contactInfoEntered: function() {
        var email = $('.js-hook-email').val(),
            phone = $('.js-hook-phone').val();

        if (!(email)) {
            MessageHelper.displayErrorMessage(MessageHelper.messages['ERROR_EmailEmpty']);
            return false;
        }

        if (!(phone)) {
            MessageHelper.displayErrorMessage(MessageHelper.messages['ERROR_PhonenumberEmpty']);
            return false;
        }

        if (!MessageHelper.isValidEmail(email)) {
            MessageHelper.displayErrorMessage(MessageHelper.messages['ERROR_INVALIDEMAILFORMAT']);
            return false;
        }

        if (!MessageHelper.IsValidPhone(phone)) {
            MessageHelper.displayErrorMessage(MessageHelper.messages['ERROR_INVALIDPHONE']);
            return false;
        }

        $('#returnSuccess').hide();
        $('#returnMixed').hide();
        $('#returnFailed').hide();

        $('.js-hook-contactInfoEntered').show();
        $('#WC_ReturnAndComplaintsPage_FormInput_number').focus();
    },

    searchDeliveredOrderLines: function() {
        var sku = $('#searchOrderLinesForm input[name=sku]').val(),
            orderNo = $('#searchOrderLinesForm input[name=orderNo]').val();

        //console.log('userpage.searchDeliveredOrderLines - sku: "' + sku + '" orderNo: "' + orderNo + '"');

        if (sku.length > 0 || orderNo.length > 0) {
            this.timeOut = setTimeout(function() {
                $('.js-hook-spinner').show();
            }, 200);

            services.searchOrderLinesForReturn(sku, orderNo, '', userpage.deliveredOrderLinesCallback);
        } else {
            console.log('userpage.searchDeliveredOrderLines TODO message that you have to search on something!');
        }
    },

    deliveredOrderLinesCallback: function(returnedAjaxData) {
        clearTimeout(this.timeOut);
        $('.js-hook-spinner').hide();
        if(returnedAjaxData.totalOrderLines > 0 && returnedAjaxData.orderLines) {
            $('#searchDeliveredOrderlinesNoItems').hide();
            $('#searchDeliveredOrderlinesListItems .standard-table__row_article').remove();
            $('#searchDeliveredOrderlinesList').show();

            for (i = returnedAjaxData.orderLines.length-1; i >= 0; i--) {
                var ol = returnedAjaxData.orderLines[i],
                    template = userpage.templates['template_returnRows'];

                ol.i = i;

                $('#searchDeliveredOrderlinesListItems').append(template(ol));

                highestIndex = i;
            }
            $('#add_button_0').focus();
            
            userpage.disableSelectedRows();

        } else {
            $('#searchDeliveredOrderlinesNoItems').show();
            $('#searchDeliveredOrderlinesList').hide();
        }
    },

    navigateAutoSuggest: function(e, callback) {
        var current, partNumber, el,
            autoSuggest = $('.js-hook-skuSearch:visible');

        if (e.keyCode == 9) {
            if ($(autoSuggest).length) {
                e.preventDefault();
            }
        }

        if (e.keyCode == 13) {
            if (!$(autoSuggest).length) {
                callback();
            } else {
                partNumber = $('.media-container__searchActive').attr('data-partNumber');

                if (partNumber !== undefined) {
                    $('.js-hook-autoPartNumber').val(partNumber);
                }
            }
        } else if (e.keyCode == 9 || e.keyCode == 38 || e.keyCode == 40) {
            if (e.keyCode == 9 || e.keyCode == 40) {
                el = $('.js-hook-skuAddSearch').first();
            }

            $('.js-hook-skuAddSearch').each(function() {
                if ($(this).hasClass('media-container__searchActive')) {
                    $(this).removeClass('media-container__searchActive');

                    if (e.keyCode == 38) {
                        //Up
                        el = $(this).prev('.js-hook-skuAddSearch');
                    } else if (e.keyCode == 9 || e.keyCode == 40) {
                        //Down
                        el = $(this).next('.js-hook-skuAddSearch');

                        if (!$(el).length) {
                            el = $('.js-hook-skuAddSearch').first();
                        }
                    }
                }
            });

            el.addClass('media-container__searchActive');
        }
    },

    mouseEnterAutoSuggest: function(el) {
        $('.js-hook-skuAddSearch').removeClass('media-container__searchActive');
        $(el).addClass('media-container__searchActive');
    },

    mouseExitAutoSuggest: function(el) {
        $('.js-hook-skuAddSearch').removeClass('media-container__searchActive');
    },

    copyToEmail: function(el) {
        $('.js-hook-email1').val($(el).val());
    },

    deleteOrderLinesCallback: function(returnedAjaxData){
    	$('.js-hook-row[data-orderno="' + returnedAjaxData.orderNo[0] + '"][data-orderline="' + returnedAjaxData.orderLineNo[0] + '"]').remove();
    },

    searchOrderLines: function() {
        services.searchOrderLines($('#SearchOrderLinesView').serialize(), userpage.searchOrderLinesCallback);
    },

    searchOrderLinesCallback: function(data) {
    	$('.js-search-orderlines').html(data);
    },

    shopOnBehalfAutoSuggest: function() {
        var logonIdTerm= $('.js-hook-sob .js-hook-sob-logonIdSearch').val().trim(),
            firstNameTerm= $('.js-hook-sob .js-hook-sob-firstNameSearch').val().trim(),
            lastNameTerm= $('.js-hook-sob .js-hook-sob-lastNameSearch').val().trim();

        if (logonIdTerm.length > 2 || firstNameTerm.length > 2 || lastNameTerm.length > 2) {
            services.shopOnBehalfSearch(logonIdTerm, firstNameTerm, lastNameTerm, header.shopOnBehalfAutoSuggestCallback);
        } else {
            header.shopOnBehalfAutoSuggestClear();
        }
    },

    applyReturnLinesAdded: function(i) {
        var $tr = $('#searchDeliveredOrderlinesListItems #standard-table__row_article_' + i),
            $clone = $tr.clone(),
            qty = $clone.find('.js-hook-quantity').html(),
            packSize = $('#searchDeliveredOrderlinesListItems  #packageSize_' + i).html();

        if(doAddRowWithQtyEmpty) qty = '';
        
        if($clone.hasClass('standard-table__disabled-row')) {
        	console.log('Row already added '+i);
	        $('#WC_ReturnAndComplaintsPage_button_more').focus();
        } else {
	        // Hide and show
	        $('#noReturnLines').hide();
	        $('#currentReturnLines').show();
	        $('#currentReturnLines_button').show();
	        $('.js-hook-OrderEntered').show();
	
	        // Appends returnText textarea
	        $clone.find('.js-hook-returnText')
	              .html('<textarea maxlength="400" class="standard-table__textarea input_table returnText" id="returnText_' + i + '"></textarea>')
	              .removeClass('hide');
	
	        // $clone.removeClass('');
	
	        // Appends returnQty textarea
	        $clone.find('.js-hook-returnQty')
	              .html('<input class="input_table" id="reqQty_' + i + '" value="' + qty + '">')
	              .removeClass('hide');
	
	        // Add/delete buttons
	        // TODO should we hid this ? $('#searchDeliveredOrderlinesListItems #add_button_' + i).hide();
	        $clone.find('#add_button_' + i).html('<i class="fa fa-minus" aria-hidden="true"></i>');
	        $clone.find('#add_button_' + i).attr('onclick', 'javascript:userpage.removeReturnLinesAdded(' + i + ');return false;');
	
	        $('#currentReturnLines').append($clone);

	        $clone.find('#returnText_'+i).focus();
	
	        // Disables rows that are selected for return.
	        userpage.disableSelectedRows();
	
	        // Alright we set, just reindex all indexs in the table
	        userpage.reIndexCurrentLines();
	
	        // SUCCESS_ADDEDRETURNLINE
	        MessageHelper.displayConfirmMessage(MessageHelper.messages['SUCCESS_ADDEDRETURNLINE']);
        }
    },

    disableSelectedRows: function() {
        $('#searchDeliveredOrderlinesListItems .js-hook-row').each(function() {
            var id = $(this).data('uniqueId'),
                hasRow = !!$('#currentReturnLines').find('.js-hook-row[data-unique-id="'+id+'"]').length;

            $(this).toggleClass('standard-table__disabled-row', hasRow);
        });
    },

    removeReturnLinesAdded: function(i) {
        //Hide and show
        $('#currentReturnLines #standard-table__row_article_' + i).remove();
        $('#searchDeliveredOrderlinesListItems #add_button_' + i).show();

        userpage.resetCurrentReturnLines();
        userpage.disableSelectedRows();
        MessageHelper.displayConfirmMessage(MessageHelper.messages['SUCCESS_REMOVEDRETURNLINE']);
    },

    sendReturnOrder: function() {
        //"Serialize"
        var formValues = $('#returnForm').serialize(),
            array = $('#currentReturnLines .standard-table__item').map(userpage.onListMap).get().filter(userpage.onListFilter),
            serializedValues = array.join('&'),
            returnCode = $('.js-hook-returnType').val(),
            allOk = true;

        console.log('formValues', formValues);
        console.log('serializedValues', serializedValues);

        console.log(array);

        $('#currentReturnLines .js-hook-row').each(function() {
            var deliveredQty = parseInt($(this).find('.js-hook-quantity').text()),
                returnQty    = $(this).find('.js-hook-returnQty input').val(),
                unitOfMeasure = $(this).find('.js-hook-unitOfMeasure').text(),
                breakablePackage = $(this).find('.js-hook-break-package').text(),
                nonStockItem = $(this).find('.js-hook-non-stock-item').text(),
                packageSize = $(this).find('.js-hook-package-size').text(),
                itemNo = $(this).find('.js-hook-itemNo').text();

            if (doValidateNonStockItem && nonStockItem == 'true') { // OPLC = 0
                MessageHelper.displayErrorMessage(MessageHelper.messages['ERROR_Non_stock_item']+' ('+itemNo+')');
                allOk = false;
                return false;
            }

            if (!/^[1-9][0-9]*?$/.test(returnQty)) {
                MessageHelper.displayErrorMessage(MessageHelper.messages['ERROR_invalid_numeric']);
                allOk = false;
                return false;
            }

            if (doValidateNonBreakablePackage && breakablePackage == 'false') { // false if SPLIT_PACKAGE=0
            	var rq = parseInt(returnQty),
            	    ps = parseInt(packageSize);
            	if(rq % ps) { // Have a reminder, not a multiple of packageSize (QuantityMultiple)
            		MessageHelper.displayErrorMessage(MessageHelper.messages['ERROR_Break_package']+' '+packageSize+' '+unitOfMeasure+' ('+itemNo+')');
                	allOk = false;
                	return false;
            	}
            }

            if (parseInt(returnQty) > deliveredQty) {
                MessageHelper.displayErrorMessage(MessageHelper.messages['ERROR_invalid_amount']);
                allOk = false;
                return false;
            }
        });

        if (allOk) {
            $('.js-hook-returnText textarea').each(function() {
                if (!(this.value)) {
                    MessageHelper.displayErrorMessage(MessageHelper.messages['ERROR_ReturnTextEmpty']);
                    allOk = false;
                    return false;
                }
            });

            if (allOk) {
                if ((array.length > 0)) {
                    userpage.disableReturnComponents(true);

                    services.sendReturnOrder(returnCode, formValues, serializedValues, userpage.sendReturnOrderCallback());
                    $('#searchDeliveredOrderlinesListItems [id^="add_button_"]').show();
                } else {
                    console.log('userpage.sendReturnOrder TODO message that you have to add at least one row!');
                }
            }
        }
    },

    resetReturnPage: function() {
        $('#WC_ReturnAndComplaintsPage_FormInput_email').val('');
        $('#WC_ReturnAndComplaintsPage_FormInput_phoneNumber').val('');
        $('#WC_ReturnAndComplaintsPage_FormInput_orderNumber').val('');
        $('#WC_ReturnAndComplaintsPage_FormInput_sku').val('');

        userpage.removeAllSentLines();
        userpage.resetCurrentReturnLines();

        $('#searchDeliveredOrderlinesNoItems').hide();
        $('#searchDeliveredOrderlinesList').hide();
        $('.js-hook-contactInfoEntered').hide();
        $('.js-hook-OrderEntered').hide();
        $('#searchDeliveredOrderlinesListItems .js-hook-row').remove();
    },

    disableReturnComponents: function(disable) {
        disable = disable === true;

        $('.js-hook-OrderEntered button').prop('disabled', disable);
        $('.js-hook-OrderEntered textarea').prop('disabled', disable);
        $('.js-hook-OrderEntered input').prop('disabled', disable);
        $('#searchDeliveredOrderlinesListItems button').prop('disabled', disable);
    },

    onListMap: function(idx, el) {
        // Captures returnText id
        if ($(el).hasClass('js-hook-returnText')) {
            return $(el).children('textarea').attr('id') + '=' + $(el).children('textarea').val();
        }

        // Captures returnQty id
        if ($(el).hasClass('js-hook-returnQty')) {
            return $(el).children('input').attr('id') + '=' + $(el).children('input').val();
        }

        // Captures regular id
        return el.id + '=' + $(el).text();
    },

    onListFilter: function(val) {
        // Keep only the values that matches the correct id format
        return /^[a-zA-Z0-9]{1,}_[0-9]{1,}=/.test(val);
    },

    resetCurrentReturnLines: function() {
        //Hide and show if nothing to show
        var $countLi = $('#currentReturnLines > *').length;

        if ($countLi <= 1) {
            $('#noReturnLines').show();
            $('#currentReturnLines').hide();
            $('#currentReturnLines_button').hide();
        }

        userpage.reIndexCurrentLines();
    },

    reIndexCurrentLines : function() {
        // This function will go through all rows and assign a  new index starting from zero (the indexes might be unordered since the user add them randomly)

        $('#currentReturnLines .standard-table__row_article').each(function(index, obj) {
            var oldIndex = $(this).attr('data-index');

            // The row
            $(this).attr('data-index', index);
            $(this).prop('id', 'standard-table__row_article_' + index);

            // Columns (only required)
            $(this).find('#orderNo_' + oldIndex).prop('id', 'orderNo_' + index);
            $(this).find('#orderLineNo_' + oldIndex).prop('id', 'orderLineNo_' + index);
            $(this).find('#itemNo_' + oldIndex).prop('id', 'itemNo_' + index);
            $(this).find('#reqQty_' + oldIndex).prop('id', 'reqQty_' + index);
            $(this).find('#returnText_' + oldIndex).prop('id', 'returnText_' + index);
            $(this).find('#warehouse_' + oldIndex).prop('id', 'warehouse_' + index);

            // Change the remove button
            $(this).find('#add_button_' + oldIndex).html('<i class="fa fa-minus" aria-hidden="true"></i>');
            $(this).find('#add_button_' + oldIndex).attr('onclick', 'javascript:userpage.removeReturnLinesAdded(' + index + ');return false;');
            $(this).find('#add_button_' + oldIndex).prop('id', 'add_button_' + index);

            highestIndex = index;
        });
    },

    setReturnOrderLinks: function(data) {
        var wrapper = $('.js-hook-returnUrls'),
            baseHref = wrapper.data('base-href');

        if (data) {
            wrapper.html(_.map(data, makeReturnLink).join(', '));
            wrapper.show();
        } else {
            wrapper.hide();
        }

        function makeReturnLink(returnNo, warehouse) {
            var url = baseHref.replace('__RETURNNO__', returnNo).replace('__WAREHOUSELOCATION__', warehouse);
            return '<a href="' + url + '" class="default-anchor">' + returnNo + '</a>';
        }
    },

    sendReturnOrderCallback: function() {
        var orderLinesTable = $('#currentReturnLines').clone().prop({ id: 'confirmedLines' });

        return function(returnedAjaxData) {
            var message;

            $('#returnFailed').hide();
            $('#returnMixed').hide();
            $('#returnSuccess').hide();
            $('#debug').hide();

            userpage.resetReturnPage();
            userpage.disableReturnComponents(false);

            if (returnedAjaxData.totalFailedLines === returnedAjaxData.totalLines) {
                // Only error
                message = $('#returnFailed .js-hook-confirm-message').text();

                MessageHelper.displayErrorMessage(message);

                $('#returnFailed').show();

            } else if (returnedAjaxData.totalFailedLines > 0) {
                // Both error and success
                message = $('#returnMixed .js-hook-confirm-message')
                    .data('base-message')
                    .replace('__CREATEDLINES__', returnedAjaxData.totalCreatedLines)
                    .replace('__TOTALLINES__', returnedAjaxData.totalLines);

                $(orderLinesTable).children('.js-hook-row').filter(function() {
                    var failedLines = returnedAjaxData.failedLines.split(',').map(function(i) { return parseInt(i) }),
                        lineNo = parseInt($(this).data('index')) + 1;

                    return failedLines.indexOf(lineNo) < 0;
                }).remove();

                $('#returnMixed').find('.js-hook-confirm-message').html(message);
                $('#returnMixed').find('.js-hook-failed-lines').html(orderLinesTable);

                MessageHelper.displayStatusMessage(message);
                userpage.setReturnOrderLinks(returnedAjaxData.returnNumbers);

                $('#returnMixed').show();

            } else {
                // Only success
                message = $('#returnSuccess .js-hook-confirm-message').text();

                MessageHelper.displayConfirmMessage(message);
                userpage.setReturnOrderLinks(returnedAjaxData.returnNumbers);

                $('#returnSuccess').show();

            }
        }
    },

    removeAllSentLines: function() {
        $('#currentReturnLines .standard-table__row_article').each(function(i, obj) {
            $(this).remove();
        });
    },

    handleReturnOrderCallbackError: function(errorCode) {
        switch(errorCode) {
            case '9910':
                MessageHelper.displayErrorMessage(MessageHelper.messages['ERROR_EmailEmpty']);
                break;
            case '9920':
                MessageHelper.displayErrorMessage(MessageHelper.messages['ERROR_PhonenumberEmpty']);
                break;
            case '9930':
                MessageHelper.displayErrorMessage(MessageHelper.messages['ERROR_ReturnCodeEmpty']);
                break;
            case '9964':
                MessageHelper.displayErrorMessage(MessageHelper.messages['ERROR_ReturnTextEmpty']);
                break;
            default:
                MessageHelper.displayErrorMessage(MessageHelper.messages['ERROR_ReturnOtherError'] + ' ' + errorCode);
        }
    },

    priceListSendLink: function(baseURL) {
    	var dpe = document.getElementById("PriceListPageDropdown");
    	var listName = dpe.value;
    	const queryString = window.location.search;
    	const urlParams = new URLSearchParams(queryString);
    	var StoreId = "storeId=" + urlParams.get("storeId");
    	var CatalogId = "catalogId=" + urlParams.get("catalogId");
    	var LangId = "langId=" + urlParams.get("langId");
    	var URL = baseURL + "/PriceListDisplay?" + CatalogId + "&" + LangId + "&" + StoreId + "&" + "listname=" + listName;
		document.getElementById("RequestPriceListButton").href=URL;
    },
    
    priceListSend: function() {
    	var dpe = document.getElementById("PriceListPageDropdown");
    	var listName = dpe.value;
    	if(listName == "") {
    		MessageHelper.displayErrorMessage(MessageHelper.messages['PriceListError']);
    	}
    },

    priceListCleanURL: function(baseURL) {
    	const queryString = window.location.search;
    	const urlParams = new URLSearchParams(queryString);
    	var StoreId = "storeId=" + urlParams.get("storeId");
    	var CatalogId = "catalogId=" + urlParams.get("catalogId");
    	var LangId = "langId=" + urlParams.get("langId");
    	var URL = baseURL + "/PriceListDisplay?" + CatalogId + "&" + LangId + "&" + StoreId;
        window.history.replaceState({}, "document.title", URL );
    },
};
