//-----------------------------------------------------------------
// Licensed Materials - Property of IBM
//
// WebSphere Commerce
//
// (C) Copyright IBM Corp. 2016 All Rights Reserved.
//
// US Government Users Restricted Rights - Use, duplication or
// disclosure restricted by GSA ADP Schedule Contract with
// IBM Corp.
//-----------------------------------------------------------------

var footer = {

    /**
     * Adjusts the separators in the footer brand list.
     */
    adjustFooterBrandList: function() {
        var currentItem, nextItem, prevItem,
            list = $('.js-hook-footerBrands li');

        _adjustFooterBrandList();
        $(window).resize(_adjustFooterBrandList);

        function _adjustFooterBrandList() {
            list.each(function(i) {
                currentItem = $(list[i]);
                prevItem = i > 0 ? $(list[i - 1]) : null;
                nextItem = list[i + 1] ? $(list[i + 1]) : null;

                currentItem.removeClass('hide');

                if (prevItem && nextItem && currentItem.hasClass('footer__list_brand_separator')) {
                    if (currentItem.position().top < nextItem.position().top || currentItem.position().top > prevItem.position().top) {
                        currentItem.addClass('hide');
                    }
                }
            });
        }
    },

    mobileToggleList: function(element) {
        if ($(element).hasClass('show')) {
            $('.footer__list__title').removeClass('show');
        } else {
            $('.footer__list__title').removeClass('show');
            $(element).addClass('show');
        }
    }
};
