//-----------------------------------------------------------------
// Licensed Materials - Property of IBM
//
// WebSphere Commerce
//
// (C) Copyright IBM Corp. 2016 All Rights Reserved.
//
// US Government Users Restricted Rights - Use, duplication or
// disclosure restricted by GSA ADP Schedule Contract with
// IBM Corp.
//-----------------------------------------------------------------

var heroCarousel = {

    settings: {
        slideDuration: 1000
    },

    instances: {},

    new: function(id) {
        this.instances[id] = new this.createInstance(id);
        this.instances[id].wrapperInit();
    },

    select: function(id) {
        return this.instances[id];
    },

    createInstance: function(id) {
        var currentIndex = 0,
            itemCount = 0,
            element = '.js-hook-heroCarousel-' + id,
            slideInProgress = false,
            sliderInterval,
            videos = {};

        var public = {

            /**
             * Adjusts width of the hero carousel wrapper.
             */
            wrapperInit: function() {
                $(document).on('ready', function() {
                    itemCount = $(element).find('.js-hook-heroCarousel-item').length;

                    // Removes slide controls if number of slides is less than 2.
                    if (itemCount < 2) {
                        $(element).find('.js-hook-heroCarousel-dotts').remove();
                        $(element).find('.js-hook-heroCarousel-nav-next').remove();
                        $(element).find('.js-hook-heroCarousel-nav-prev').remove();
                    }
                });
            },

            /**
             * Slides to next item.
             */
            next: function() {
                this.slideTo(currentIndex + 1, 'next');
            },

            /**
             * Slides to previous item.
             */
            prev: function() {
                this.slideTo(currentIndex - 1, 'prev');
            },

            /**
             * Slides to item with given index.
             */
            slideTo: function(index, direction) {
                direction = direction || null;

                if (slideInProgress || index === currentIndex) return false;

                slideInProgress = true;

                var items, oldItem, currentItem, dotts, dott,
                    go = {}
                    oldIndex = currentIndex,
                    setIndex = function(i) {
                        return i < 0 ? itemCount - 1 : i >= itemCount ? 0 : i;
                    };

                currentIndex = setIndex(index);
                
                if (direction === null) {
                    direction = currentIndex > oldIndex ? 'next' : 'prev';
                }

                go.in = direction + '_in';
                go.inAnim = go.in + '_anim';
                go.out = direction + '_out';
                go.outAnim = go.out + '_anim';

                currentItem = $(element).find('.js-hook-heroCarousel-item:nth-child(' + (currentIndex + 1) + ')');
                oldItem = $(element).find('.js-hook-heroCarousel-item:nth-child(' + (oldIndex + 1) + ')');
                currentDott = $(element).find('.js-hook-heroCarousel-dott:nth-child(' + (currentIndex + 1) + ')');

                items = $(element).find('.js-hook-heroCarousel-item');
                dotts = $(element).find('.js-hook-heroCarousel-dott');

                items.removeClass('prev_in prev_out prev_in_anim prev_out_anim next_in next_out next_in_anim next_out_anim');

                dotts.removeClass('current');
                currentDott.addClass('current');

                oldItem.addClass(go.out).removeClass('current');
                currentItem.addClass(go.in);

                setTimeout(function() {
                    oldItem.addClass(go.outAnim);
                    currentItem.addClass(go.inAnim);
                });

                $(element).find('.hero-carousel__nav_prev').addClass('disabled');
                $(element).find('.hero-carousel__nav_next').addClass('disabled');
                $(element).find('.js-hook-heroCarousel-dotts').addClass('disabled');

                setTimeout(function() {
                    currentItem.addClass('current');
                }, heroCarousel.settings.slideDuration * .9);

                setTimeout(function() {
                    items.removeClass('prev_in prev_out prev_in_anim prev_out_anim next_in next_out next_in_anim next_out_anim');

                    $(element).find('.js-hook-heroCarousel-dotts').removeClass('disabled');
                    $(element).find('.hero-carousel__nav_prev').removeClass('disabled');
                    $(element).find('.hero-carousel__nav_next').removeClass('disabled');

                    slideInProgress = false;
                }, heroCarousel.settings.slideDuration);
                header.lazyLoad.update();
            },

            /**
             * Scrolls down below the hero carousel.
             */
            scrollDown: function() {
                $('html, body').animate({ scrollTop: $(element).offset().top + $(element).height() }, { duration: 800, easing: 'easeOutQuart' });
            },

            saveVideo: function(id) {
                videos['js-hook-video-' + id] = $(element).find('.js-hook-videoElement-' + id).html().trim();
                $(element).find('.js-hook-videoElement-' + id).remove();
            },

            /*
            playVideo: function(id) {
                if ($(window).width() >= 768) {
                    $(element).find('.js-hook-video').addClass('show');
                    $(element).find('.js-hook-videoContainer').html(videos['js-hook-video-' + id]);
                } else {
                    window.open($(videos['js-hook-video-' + id]).attr('src'), '_blank');
                }
            },
            */
            
            playVideo: function(o) {
            	var src = $(o).data("src");	
            	if ($(window).width() >= 768) {
                    $(element).find('.js-hook-video').addClass('show');
                	$(element).find('.js-hook-videoContainer').html('<iframe frameborder="0" height="100%" width="100%" src="' + src + '" allowfullscreen="" allowfullscreen=""></iframe>');
                } else {
                    window.open(src, '_blank');
                }
            },

            closeVideo: function() {
                $(element).find('.js-hook-video').removeClass('show');
                setTimeout(function() {
                    $(element).find('.js-hook-videoContainer').html('');
                }, 300);
            },
            
            setButtonLayout: function() {
            	$(element).find('.js-hook-heroCarousel-item').each(function() {
            		var content = $(this).find('.js-hook-heroCarousel-placement')
            		var contentPlacement = "left";
            		
            		if(!content.hasClass("hero-carousel__item_placement-left") && !content.hasClass("hero-carousel__item_placement-right") && !content.hasClass("hero-carousel__item_placement-center")){
            			$(this).find('.js-hook-slide-body').children().each(function() {
            		        if($(this).css('text-align') == "center"){
            		            contentPlacement = "center";
            		        } else if($(this).css('text-align') == "right"){
            		            contentPlacement = "right";
            		        } else if($(this).html() !== "") {
            		            contentPlacement = "left";
            		        }
            		    });
            			
            			content.addClass("hero-carousel__item_placement-" + contentPlacement);
            		}
            	});
            }

        };

        return public;
    }

};
