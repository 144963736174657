/**
 * InteractiveMap
 * 
 * @param {string} id 
 * @param {string} file 
 */
function InteractiveMap(id, data) {
    var self = this;

    self.ready = false;
    self.id = id;
    self.canvas = Snap(self.id + ' .js-hook-interactiveMap-canvas');
    self.listElement = $(self.id + ' .js-hook-interactiveMap-lists-wrapper');
    self.canvasElement = $(self.id + ' .js-hook-interactiveMap-canvas');
    self.personnelList = [];
    self.zones = [];
    self.file = $(self.id + ' .js-hook-interactiveMap-canvas').data('file');
    self.init = null;
    self.selected = null;
    self.events = [];
    self.label = $('<div class="interactive-map__area-label js-hook-interactiveMap-label"></div>');

    var listTemplate = $('.js-hook-interactiveMap-list-template'),
        listMarkup = $('.js-hook-interactiveMap-list-template').html();

    listTemplate.remove();

    self.listTemplate = _.template(listMarkup);

    $(self.id).append(self.label);

    Snap.load(self.file, function(fragment) {
        self.canvas.attr('viewBox', fragment.select('svg').attr('viewBox'));
        self.canvas.append(fragment.selectAll('.interactive-map__zone'));
        self.init = fragment.select('svg').attr('data-init');

        for (var key in data) {
            data[key].personnel.map(function(person) {
                person.image = person.image.replace(/(^\'|\'$)/g, '').trim();
            });

            self.personnelList.push({
                zone: key,
                zoneName: self.canvasElement.find('path#IM_' + key).data('counties'),
                personnel: data[key].personnel
            });

            self.listElement.append(self.listTemplate(_.last(self.personnelList)));
        }

        /**
         * Event bindings
         */
        self.canvasElement.find('.interactive-map__zone').on('mousemove', function(event) {
            self.label.css({
                left: event.pageX + 20,
                top: event.pageY - (self.label.outerHeight() / 2),
            });
        });

        self.canvasElement.find('.interactive-map__zone').on('mouseenter', function(event) {
            var counties = $(this).data('counties');

            self.label.html(counties.replace(/\s*\/\s*/g, '<br>'));
            self.label.show();
        });

        self.canvasElement.find('.interactive-map__zone').on('mouseleave', function(event) {
            self.label.hide();
        });

        self.canvasElement.find('.interactive-map__zone').on('click', function(event) {
            self.select($(this).attr('id').replace('IM_', ''));
        });

        for (var i = 0; i < self.events.length; i++) {
            if (self.events[i].event === 'ready') {
                self.events[i].callback();
            }
        }

        self.ready = true;

        self.select();
    });
}

/**
 * Selects a geo zone and prints all peronnel in the area.
 * 
 * @param {string} zoneId
 */
InteractiveMap.prototype.select = function(zoneId) {
    var self = this, zone, zones;

    zoneId = zoneId || self.init;

    $(self.id + ' .js-hook-interactiveMap-personnel').not('[data-zone="' + zoneId + '"]').removeClass('interactive-map__personnel-wrapper_show');
    $(self.id + ' .js-hook-interactiveMap-personnel[data-zone="' + zoneId + '"]').addClass('interactive-map__personnel-wrapper_show');

    zone = $(self.id).find('#IM_' + zoneId),
    zones = $(self.id).find('.interactive-map__zone')

    zones.removeClass('selected');
    zone.addClass('selected');

    self.selected = {
        id: zoneId,
        zone: zone.data('counties')
    };

    for (var i = 0; i < self.events.length; i++) {
        if (self.events[i].event === 'select') {
            self.events[i].callback(self.selected);
        }
    }
};

/**
 * DESC
 * 
 * @param {string}   event
 * @param {Function} callback
 */
InteractiveMap.prototype.on = function(event, callback) {
    if (event === 'ready' && this.ready) {
        callback();
    } else {
        this.events.push({
            event: event,
            callback: callback
        });
    }
};

