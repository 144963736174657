/**
 * Place polyfill script inits here.
 */

$(document).ready(function() {

    // Initiate object-fit
    objectFitImages(null, { watchMQ: true });

});
