
var GoogleAnalytics = {

    addToCart: function (product) {
    	if (typeof ga !== 'undefined' && ga !== undefined && ga !== null) {
	        ga('require', 'ec');
	        ga('ec:addProduct', {
	        	 'id': product.partnumber[0],
	             // 'name': 'test',
	             // 'category': product.category,
	             // 'brand': product.brand,
	             // 'variant': product.variant,
	             // 'price': product.price,
	             'quantity': product.quantity[0]
	          });
	        ga('ec:setAction', 'add');
	        ga('send', 'event', 'Cart', 'click', 'add to cart');
	        console.log(product);
    	}
    },
    addAllToCart: function (product) {
    	if (typeof ga !== 'undefined' && ga !== undefined && ga !== null) {
	        ga('require', 'ec');
	        
	        for (var i = 0, il = product.items.length; i < il; i++) {
	      		ga('ec:addProduct', {
		        	'id': product.items[i].id,
		            // 'name': 'test',
		            // 'category': product.category,
		            // 'brand': product.brand,
		            // 'variant': product.variant,
		            // 'price': product.price,
		        	'quantity': product.items[i].quantity
	      		});
	        }
	        
	        ga('ec:setAction', 'add');
	        ga('send', 'event', 'Cart', 'click', 'add to cart');
    	}
    }
	,
//    removeFromCart: function(product, outcome) {
//        ga("require", "ec");
//        ga('ec:addProduct', {
//            'id': product.catentryId,
//            'name': product.name,
//            // 'category': product.category,
//            // 'brand': product.brand,
//            // 'variant': product.variant,
//            // 'price': product.price,
//            'quantity': Math.abs(product.delta)
//        });
//        ga("ec:setAction", "remove");
//        if (outcome === 'success') {
//            ga("send", "event", "Cart", "click", "remove from cart");
//        } else {
//            ga("send", "event", "Cart", "click", "remove from cart failure");
//        }
//    }
//	  ,
//    detailsView: function(product) {
//        ga('require', 'ec');
//
//        ga('ec:addProduct', {
//            'id': product.catentryId,
//            'name': product.name,
//            // 'category': product.category,
//            // 'brand': product.brand,
//            'variant': product.variant
//        });
//
//        ga('ec:setAction', 'detail');
//
//        ga('send', 'pageview');   
//    }
//	  ,
    
	checkoutConfirmation: function(orderId, revenue) {
		if (typeof ga !== 'undefined' && ga !== undefined && ga !== null) {
			ga('require', 'ec');
			
			ga('set', 'currencyCode', 'SEK'); // fix this at some point 
			ga('ec:setAction', 'purchase', {
				'id': orderId,
				//'affiliation': wcGlobal.storeId,
				'revenue': revenue 
				//'shipping': confirmationData.totalShippingCharge
			});
	
			ga('send', 'event', 'Cart', 'click', 'send order'); 
		}
	}
//	,
//	checkoutstep: function() {
//		ga('require', 'ec');
//		
//		for(var i = 0; i < cart.order.orderItem.length; i++) {
//		    var product = cart.order.orderItem[i];
//		    var productData = $('#orderItemId_' + product.orderItemId + ' .js-ua-data').data() || {};
//		    ga('ec:addProduct', {
//		      	'id': product.productId,
//		      	'name': productData.productname || 'no name found',
//		      	'variant': productData.itemname || 'no variant name found',
//		      	'price': Number(product.unitPrice),
//		      	'quantity': Number(product.quantity)
//		    });
//	  	}
//		
//		ga('ec:setAction','checkout', {
//		    'step': $('.js-ua-checkoutstep').data('step')
//		});
//	}

}