//-----------------------------------------------------------------
// Licensed Materials - Property of IBM
//
// WebSphere Commerce
//
// (C) Copyright IBM Corp. 2016 All Rights Reserved.
//
// US Government Users Restricted Rights - Use, duplication or
// disclosure restricted by GSA ADP Schedule Contract with
// IBM Corp.
//-----------------------------------------------------------------

var services = {
	messages: {
        invalidEmail: 'An error has occurred',
        confirmEmail: 'Mail was sent',
    },
		
    //header services
    headerSearch: function(searchTerm, searchBass, callback) {
    	$.ajax({
            url: urlRoot + 'SearchComponentAutoSuggestView',
            dataType: 'html',
            data: {
                langId: WCParamJS.langId,
                storeId: WCParamJS.storeId,
                catalogId: WCParamJS.catalogId,
                initbass: searchBass,
                USINGAJAX: true,
                showHeader: true,
                term: searchTerm,
                requesttype: 'ajax'
            },
            success: function(data) {
                if(services.serviceValidation(data)){
                    callback(data);
                }
            }
        });
    },
    shopOnBehalfSearch: function(logonIdTerm, firstNameTerm, lastNameTerm, callback) {
        // console.log("service.shopOnBehalfSearch, urlRoot: "+urlRoot);
        // console.log("service.shopOnBehalfSearch, header.buyerSearchURL: "+header.buyerSearchURL);

        //The REST service uses a GET.
        require(["dojo/request/xhr"], function(xhr){
            xhr(header.buyerSearchURL, {
            handleAs: "json",
            method: "GET",
            query: {
            'accountStatus' : 1,
            'logonId' : logonIdTerm,
            'logonIdSearchType': 3,
            'firstName' : firstNameTerm,
            'firstNameSearchType' : 1,
            'lastName' : lastNameTerm,
            'lastNameSearchType' : 1,
            'orderByTableName' : 'address',
            'orderByFieldName' : 'lastname'
            },
            headers : {'Content-Type': 'text/plain; charset=utf-8' }
            }).then(function(response){
                    //console.log("Has search response: "+response);
                    //cursor_clear();
                    callback(response);
            }, function(err){
                    //cursor_clear();
                    // TODO Handle error
                    err['errorCode'] = 'GENERICERR_MAINTEXT';
                    GlobalLoginShopOnBehalfJS.handleError(err, searchCriteria);
            });
        });
    },
    runAsUserSetInSessionService: function(rootUrl,runAsUserId,authToken,callback) {
        // console.log("service.runAsUserSetInSessionService, rootUrl: \""+rootUrl+"\"");
        dojo.xhrPost({
            url: urlRoot + 'AjaxRunAsUserSetInSession',             
            handleAs: "json-comment-filtered",
            content: {
                langId: WCParamJS.langId,
                storeId: WCParamJS.storeId,
                catalogId: WCParamJS.catalogId,
                USINGAJAX: true,
                runAsUserId: runAsUserId,
                authToken: authToken
            },
            error: function(errObj,ioArgs) {
                console.log("Error in service.runAsUserSetInSessionService!");
                // TODO Handle error
            },
            load: function (data) {             
                //console.log("Success on service.runAsUserSetInSessionService!");
                callback(data);
            }    
        });
    },
    clearUserSetInSession : function(rootUrl,callback) {
        // console.log("service.clearUserSetInSession, rootUrl: \""+rootUrl+"\"");
        dojo.xhrPost({
            url: urlRoot + 'AjaxRestoreOriginalUserSetInSession',               
            handleAs: "json-comment-filtered",
            content: {
                langId: WCParamJS.langId,
                storeId: WCParamJS.storeId,
                catalogId: WCParamJS.catalogId,
                USINGAJAX: true
            },
            error: function(errObj,ioArgs) {
                console.log("Error in service.clearUserSetInSession!");
                // TODO Handle error
            },
            load: function (data) {             
                // console.log("Success on service.clearUserSetInSession!");
                callback(data);
            }    
        });
    },
    loginView: function(rootUrl, callback) {
        $.ajax({
            url: rootUrl + 'GlobalLoginSignInView',
            dataType: 'html',
            data: {
                langId: WCParamJS.langId,
                storeId: WCParamJS.storeId,
                catalogId: WCParamJS.catalogId,
                USINGAJAX: true
                
            },
            success: function(data) {
                callback(data);
            }
        });
    },
    mobileMenuView: function(rootUrl, callback) {
    	$.ajax({
            url: rootUrl + "AjaxMobileMenu",
            dataType: "html",
            data: {
                langId: WCParamJS.langId,
                storeId: WCParamJS.storeId,
                catalogId: WCParamJS.catalogId,
//                menuCategoryId: $("body").data("categoryid"),
//                menuProductId: $("body").data("productid"),
                USINGAJAX: true,
                requesttype: "ajax"
            },
            success: function(data) {
                if(services.serviceValidation(data)){
                    callback(data);
                }
            }
        });
    },
    
    megaMenuCategoryList: function(urlRoot, element) {
    	
    	var bassId = $(element).data("bassid");
		var categoryId = $(element).data("basscategoryid");
    	
    	return $.ajax({
            method: "GET",
            url: urlRoot + "AjaxMegaMenuCategoryList",
            dataType: "html",
            context: element,
            data: {
                langId: WCParamJS.langId,
                storeId: WCParamJS.storeId,
                catalogId: WCParamJS.catalogId,
                categoryId: categoryId,
                menuCategoryId: $("body").data("categoryid"),
                menuProductId: $("body").data("productid"),
                initbass: bassId,
                USINGAJAX: true,
                requesttype: "ajax"
            },
            success: function(data) {
            	if(services.serviceValidation(data)){
            		$(this).html(data);
            	}
            },
            error: function(data, textStatus) {
            	console.log("Error in AjaxMegaMenuCategoryList: "+textStatus);
            	console.log(data);
            }
        });
    	
    },    
    
    megaMenuView: function(catId, callback) {
        $.ajax({
       
        	url: urlRoot + 'AjaxMegaMenu',
            dataType: 'html',
            data: {
                langId: WCParamJS.langId,
                storeId: WCParamJS.storeId,
                catalogId: WCParamJS.catalogId,
                cass: WCParamJS.cass,
                categoryId: catId,
                menuCategoryId: $('body').data('categoryid'),
                menuProductId: $('body').data('productid'),
                USINGAJAX: true,
                requesttype: 'ajax'
            },
            success: function(data) {
                if(services.serviceValidation(data)){
                    callback(data);
                }
            }
        });
    },
    megaMenuViewSub: function(catId, callback) {
        $.ajax({
            url: urlRoot + 'AjaxMegaMenu',
            dataType: 'html',
            data: {
                langId: WCParamJS.langId,
                storeId: WCParamJS.storeId,
                catalogId: WCParamJS.catalogId,
                cass: WCParamJS.cass,
                categoryId: catId,
                menuCategoryId: $('body').data('categoryid'),
                menuProductId: $('body').data('productid'),
                subMenu: true,
                USINGAJAX: true
            },
            success: function(data) {
                callback(data);
                $('.mega-menu__groupItem.active-hidden').removeClass('mega-menu__hidden').siblings().each(function() {
                    $(this).removeClass('mega-menu__hidden');
                });
            }
        });
    },
    catalogListView: function(catId, prodId, usingAjax, callback, massocType) {
    	var showDiscontinuedProducts=true;
    	if(arguments.length>5) showDiscontinuedProducts=arguments[5];
    	$.ajax({
            url: urlRoot + 'AjaxProductItemList',
            dataType: 'html',
            data: {
                langId: WCParamJS.langId,
                storeId: WCParamJS.storeId,
                catalogId: WCParamJS.catalogId,
                cass: WCParamJS.cass,
                categoryId: catId,
                productId: prodId,
                merchandisingAssociation: massocType,
                USINGAJAX: usingAjax,
                requesttype: 'ajax',
                showDiscontinuedProducts: showDiscontinuedProducts
            },
            success: function(data) {
                if(services.serviceValidation(data)){
                    callback(data);
                }
            }
        });
    },
    catalogView: function(targetUrl, currentFilterList, currentPageSize, callback, beginIndex, pageView, orderBy) {
        var paramString = "";
        var showDiscontinuedProducts = false;
        for(var i=0; i < currentFilterList.length; i++) {
            // console.log("services.catalogView currentFilterList[i].filterId: "+currentFilterList[i].filterId);
            // console.log("targetUrl: "+targetUrl);
            if(currentFilterList[i].filterId=='showDiscontinuedProducts') {
            	showDiscontinuedProducts = true;
            }
        	paramString = paramString + "&facetId=" + currentFilterList[i].filterId;
        	paramString = paramString + "&facet=" + currentFilterList[i].filterName;
        }
        if(showDiscontinuedProducts) { 
        	paramString = paramString + "&showDiscontinuedProducts=true"; 
        } else {
        	paramString = paramString + "&showDiscontinuedProducts=false";
        }
        
        paramString = paramString + "&pageSize=" + currentPageSize;
        
        if(!beginIndex){
            beginIndex = 0;
        }
        paramString = paramString + "&beginIndex=" + beginIndex;
        
        if(!pageView){
            pageView = 'grid';
        }
        paramString = paramString + "&pageView=" + pageView + "&historyPV="+pageView;
        
        if(!orderBy){
            orderBy = '';
        }
        paramString = paramString + "&orderBy=" + orderBy;
        
        $.ajax({
            url: targetUrl + paramString,
            dataType: 'html',
            success: function(data) {
                callback(data);
            }
        });
    },
    deleteFromCart: function(orderId, orderItemId, callback){
    	return $.ajax({
            url: urlRoot + 'AjaxRESTOrderItemDelete',
            dataType: 'html',
            data: {
                storeId: WCParamJS.storeId,
                catalogId: WCParamJS.catalogId,
                langId: WCParamJS.langId,
                orderId: orderId,
                orderItemId: orderItemId,
                USINGAJAX: true         
            },
            success: function (data) {
                callback(data, orderItemId);
                services.updateMiniCart();
            }
        });  
    },
    addItemToCart: function(productId, partnumber, quantity, goodsmark, gtin, callback) {
        return $.ajax({
            url: urlRoot + 'AjaxAddItemToShoppingCart',
            dataType: 'html',
            data: {
                storeId: WCParamJS.storeId,
                partnumber: partnumber,
                catalogId: WCParamJS.catalogId,
                langId: WCParamJS.langId,
                productId: productId,
                quantity: quantity,
                goodsmark: goodsmark,
                gtin: gtin,     
                USINGAJAX: true         
            },
            success: function (data) {
                /*if(data.indexOf('errorCode') > 0){
                    console.log('addItemToCart error:' + data);
                    catalog.addItemToCartCallback(data, productId, false);
                }
                else {
                    services.updateMiniCart();
                    catalog.addItemToCartCallback(data, productId, true);    
                }*/
                //console.log('addItemToCart error:' + data);
                services.updateMiniCart();
                callback(data, productId);
            }
        });        
    },
    addItemsToCart: function(url, callback) {
        return $.ajax({
            url: urlRoot + 'AjaxAddItemToShoppingCart?' + url,
            dataType: 'html',
            data: {
                storeId: WCParamJS.storeId,
                catalogId: WCParamJS.catalogId,
                langId: WCParamJS.langId,  
                USINGAJAX: true         
            },
            success: function (data) {
                /*if(data.indexOf('errorCode') > 0){
                    console.log('addItemToCart error:' + data);
                    catalog.addItemToCartCallback(data, productId, false);
                }
                else {
                    services.updateMiniCart();
                    catalog.addItemToCartCallback(data, productId, true);    
                }*/
                console.log('addItemToCart error:' + data);
                services.updateMiniCart();
                callback(data);
            }
        });        
    },
    updateCartTotalprice: function(callback){       
        $.ajax({
            url: 'CurrentOrderInformationView',
            dataType: 'html',
            data: {
                storeId: WCParamJS.storeId,
                catalogId: WCParamJS.catalogId,
                langId: WCParamJS.langId
            },
            success: function (data) {
                callback(data);
            }
        });
    },
    updateOrderItemView: function(orderId, callback){
        $.ajax({
            url: 'AjaxOrderItemDetail',
            dataType: 'html',
            data: {
                storeId: WCParamJS.storeId,
                catalogId: WCParamJS.catalogId,
                langId: WCParamJS.langId,
                orderId: orderId
            },
            success: function (data) {
                callback(data);
            }
        });
    },
    addListToCart: function(orderId, callback) {
        $.ajax({
            url: 'AjaxAddReqListToShoppingCart',
            dataType: 'html',
            data: {
                storeId: WCParamJS.storeId,
                catalogId: WCParamJS.catalogId,
                langId: WCParamJS.langId,
                orderId: orderId,
                USINGAJAX: true         
            },
            success: function (data) {
                console.log('addItemToCart error:' + data);
                services.updateMiniCart();
                callback(data);
            }
        });        
    },
    setCurrentPendingOrder: function(orderId, callback) {
        $.ajax({
            url: 'AjaxSetPendingOrder',
            dataType: 'html',
            data: {
                storeId: WCParamJS.storeId,
                catalogId: WCParamJS.catalogId,
                langId: WCParamJS.langId,
                orderId: orderId,
                USINGAJAX: true         
            },
            success: function (data) {
                console.log('addItemToCart error:' + data);
                services.updateMiniCart();
                callback(data);
            }
        });        
    },
    removeSelectedAddrInSelector: function() {
        $.each($(".js-hook-preDefAltAddrSelector option:selected"), function () {
            $(this).prop('selected', false);
        });
    },
    onlyNumbers: function() {
    	const $phonenr = $("[name='phoneNumber']")[0];
    	var oldval = $phonenr.value;
    	$phonenr.value = $phonenr.value.replace(/\D/g,'');
    	if(oldval != $phonenr.value) {
        	MessageHelper.displayErrorMessage(MessageHelper.messages["ERROR_WRONG_PHONE"]);
    	}
    	
    },
    updateShopCart: function(orderId, callback) {
		//console.log($('div.js-order-header-info input, div.js-order-header-info select').serialize());
		var forcedAddr = $(document.getElementById("js-hook-forced-alt-addr")).attr("isForced");
		var altaddr = "";
		if(forcedAddr) {
			altaddr = "&alternativeaddress=on";
		}
		dojo.xhrPost({
		        url: urlRoot + 'AjaxUpdateShopCart?'+$('div.js-order-header-info input, div.js-order-header-info select').serialize()+altaddr,              
		        handleAs: "json-comment-filtered",
	            content: {
	                storeId: WCParamJS.storeId,
	                catalogId: WCParamJS.catalogId,
	                langId: WCParamJS.langId,
	                orderId: orderId,
	                USINGAJAX: true         
	            },
	            error: function(errObj,ioArgs) {
	                console.log(errObj);
	                console.log(ioArgs);
	            },
	            load: function (data) {     
	                if(callback !== undefined){
	                    callback(data);
	                }
	            }    
	    });
    },
    updateAllItems: function(orderId, callback) {
     //  console.log($('ul.js-orderlines-info input, ul.js-orderlines-info select').serialize());
       var itemURL = '';
        dojo.xhrPost({
                url: urlRoot + 'AjaxUpdateItem?'+$('ul.js-orderlines-info input, ul.js-orderlines-info select').serialize(),                
                handleAs: "json-comment-filtered",
                content: {
                    storeId: WCParamJS.storeId,
                    catalogId: WCParamJS.catalogId,
                    langId: WCParamJS.langId,
                    orderId: orderId,
                    USINGAJAX: true         
                },
                error: function(errObj,ioArgs) {
                    console.log("Error!");
                },
                load: function (data) {  
                    console.log("Success!");
                    callback(data);
                }    
          });
    },
    updateItem: function(itemId ,productId, orderItemsId, quantity, goodsmark, gtin, callback) {
        if(quantity === '0'){
            CheckoutHelperJS.deleteFromCart(orderItemsId, 'false');
            return;
        }
          dojo.xhrPost({
                url: urlRoot + 'AjaxUpdateItem',
                handleAs: "json-comment-filtered",
                content: {
                    storeId: WCParamJS.storeId,
                    catalogId: WCParamJS.catalogId,
                    langId: WCParamJS.langId,
                    productId: productId,
                    orderItemsId: orderItemsId,
                    quantity: quantity,
                    goodsmark: goodsmark,
                    gtin: gtin,
                    USINGAJAX: true         
                },
                error: function(errObj,ioArgs) {
                    console.log("Error!");
                },
                load: function (data) {    
                    console.log(data);
                    services.updateMiniCart();
                    callback(data);
                }
            });
    },
    updateMiniCart: function() {
        $.ajax({
            url: urlRoot + 'AjaxQuickCartDisplay',
            datatype: 'html',
            data: {
                storeId: WCParamJS.storeId,
                catalogId: WCParamJS.catalogId,
                langId: WCParamJS.langId,
                USINGAJAX: true
            },
            success: function (data) {
            	if (data != null && data != "") {
            		$(".js-hook-minicart").html(data); 
            	}
            }
        });
    },
    addItemToRequisitionList: function(params, requisitionListId, callback) {
        return $.ajax({
            url: urlRoot + 'AjaxRequisitionListUpdateItem?' + params,
            dataType: 'json',
            dataFilter: services.jsonCommentFilter,
            data: {
                storeId: WCParamJS.storeId,
                catalogId: WCParamJS.catalogId,
                langId: WCParamJS.langId,
                requisitionListId: requisitionListId
            },
            success: function (data) {
                callback(data);
            }
        });        
    },
    reqListAddItemByPartnumber: function(requisitionListId, partNumber, quantity, gitn, gitnQty, callback) {
        return $.ajax({
            url: 'AjaxRequisitionListUpdateItem',
            dataType: 'html',
            data: {
                storeId: WCParamJS.storeId,
                catalogId: WCParamJS.catalogId,
                langId: WCParamJS.langId,
                requisitionListId: requisitionListId,
                partNumber: partNumber,
                quantity: quantity,
                field1: gitnQty,
                field2: gitn 
            },
            success: function (data) {
                //console.log('addItemToRequisitionListTable error:' + data);
                callback(data);
            }
        });        
    },
    savedOrderAddItem: function(orderId, partNumber, quantity, gitn, gitnQty, callback) {
        return $.ajax({
            url: 'AjaxOrderItemAdd',
            dataType: 'html',
            data: {
                storeId: WCParamJS.storeId,
                catalogId: WCParamJS.catalogId,
                langId: WCParamJS.langId,
                orderId: orderId,
                partNumber: partNumber,
                quantity: quantity,
                field1: gitnQty,
                field2: gitn
            },
            success: function (data) {
                console.log('addItemToSavedOrderTable error:' + data);
                callback(data);
            }
        });        
    },
    reqListDeleteItem: function(requisitionListId, orderItemId, callback) {
        return $.ajax({
            url: 'AjaxRequisitionListItemDelete',
            dataType: 'html',
            data: {
                storeId: WCParamJS.storeId,
                catalogId: WCParamJS.catalogId,
                langId: WCParamJS.langId,
                requisitionListId: requisitionListId,
                orderItemId: orderItemId,
                quantity: 0
            },
            success: function (data) {
                console.log('removeItemFromRequisitionListTable error:' + data);
                callback(data);
            }
        });        
    },
    savedOrderRemoveItem: function(orderId, orderItemId, callback) {
        return $.ajax({
            url: 'AjaxOrderItemDelete',
            dataType: 'html',
            data: {
                storeId: WCParamJS.storeId,
                catalogId: WCParamJS.catalogId,
                langId: WCParamJS.langId,
                orderId: orderId,
                orderItemId: orderItemId,
                quantity: 0
            },
            success: function (data) {
                console.log('removeItemFromSavedOrderTable error:' + data);
                callback(data);
            }
        });        
    },
    reqListUpdateItem: function(requisitionListId, orderItemId, productId, quantity, gitn, gitnQty, goodsmark, callback) {
        return $.ajax({
            url: 'AjaxRequisitionListUpdateItem',
            dataType: 'html',
            data: {
                storeId: WCParamJS.storeId,
                catalogId: WCParamJS.catalogId,
                langId: WCParamJS.langId,
                requisitionListId: requisitionListId,
                orderItemId: orderItemId,
                catEntryId: productId,
                quantity: quantity,
                description: goodsmark,
                field1: gitnQty,
                field2: gitn 
                
            },
            success: function (data) {
                console.log('updateItemRequisitionList error:' + data);
                callback(data);
            }
        });        
    },
    savedOrderUpdateItem: function(orderId, orderItemId, productId, quantity, gitn, gitnQty, goodsmark, callback) {
        return $.ajax({
            url: 'AjaxOrderItemUpdate',
            dataType: 'html',
            data: {
                storeId: WCParamJS.storeId,
                catalogId: WCParamJS.catalogId,
                langId: WCParamJS.langId,
                orderId: orderId,
                orderItemId: orderItemId,
                quantity: quantity,
                description: goodsmark,
                field1: gitnQty,
                field2: gitn
            },
            success: function (data) {
                console.log('updateItemSavedOrder error:' + data);
                callback(data);
            }
        });        
    },
    requisitionListView: function(orderId, widgetName, callback) {
        $.ajax({
            url: 'AjaxRequisitionListTable',
            dataType: 'html',
            data: {
                storeId: WCParamJS.storeId,
                catalogId: WCParamJS.catalogId,
                langId: WCParamJS.langId,
                orderId: orderId,
                widgetName: widgetName
            },
            success: function (data) {
                callback(data);
            }
        });        
    },
    calculateOrderItems: function(orderId, callback) {
        return $.ajax({
            url: urlRoot + 'AjaxOrderCalculate',
            dataType: 'html',
            data: {
                async: false,
                storeId: WCParamJS.storeId,
                catalogId: WCParamJS.catalogId,
                langId: WCParamJS.langId,
                orderId: orderId,
                updatePrices: 1,
                calculationUsageId: -1,
            },
            success: function (data) {
                callback(data);
            }
        });        
    },
    createRequisitionList: function(status, name, callback) {
    	return $.ajax({
            url: urlRoot + 'AjaxRequisitionListCreate',
            dataType: 'json',
            dataFilter: services.jsonCommentFilter,
            data: {
            	storeId: WCParamJS.storeId,
                catalogId: WCParamJS.catalogId,
                langId: WCParamJS.langId,
                status: status,
                name: name,
                USINGAJAX: true         
            },
            error: function(errObj,ioArgs) {
                console.log(errObj);
                console.log(ioArgs);
            },
	        success: function (data) {
	            callback(data);
	        }
        });
    },
    /*createRequisitionList: function(status, name, callback) {
    	dojo.xhrPost({
            url: urlRoot + 'AjaxRESTRequisitionListCreate',
            handleAs: "json-comment-filtered",
            content: {
            	storeId: WCParamJS.storeId,
                catalogId: WCParamJS.catalogId,
                langId: WCParamJS.langId,
                status: status,
                name: name,
                USINGAJAX: true         
            },
            load: function (data) {    
            	callback(data);
            }
        });
    },*/
    organizationBuyerView: function(organizationId, organizationName, callback) {
        $.ajax({
            url: 'AjaxOrganizationSummary',
            dataType: 'html',
            data: {
                langId: WCParamJS.langId,
                storeId: WCParamJS.storeId,
                catalogId: WCParamJS.catalogId,
                orgEntityId: organizationId,
                OrgEntityName: organizationName
            },
            success: function(data) {
                callback(data);
            }
        });
    },
    deleteOrderLine: function(orderLineNo, orderNo, artNo, description, qty, callback) {
        //content           : 'Vill du verkligen makulera följande orderrad från ordernr '+orderNo+'?<br> <br>Radnr: '+orderLineNo+' <br>Artnr: '+artNo+'<br>Benämning: '+description+' <br>Antal: '+qty+' <br>',
        
        var choice = $.confirm({
            title           : '',
            content         : dsoContent+' '+orderNo+'?<br> <br>'+dsoRowNo+': '+orderLineNo+' <br>'+dsoArtNo+': '+artNo+'<br>'+dsoDescription+': '+description+' <br>'+dsoQuantity+': '+qty+' <br>',
            confirmButton   : dsoBtnYes,
            cancelButton    : dsoBtnNo,
            animation       : 'opacity',
            closeAnimation  : 'opacity',
            animationSpeed  : '1000',
            confirm : function(){
                  dojo.xhrPost({
                        url: urlRoot + 'AjaxDeleteOrderLine',               
                        handleAs: "json-comment-filtered",
                        content: {
                            storeId: WCParamJS.storeId,
                            catalogId: WCParamJS.catalogId,
                            langId: WCParamJS.langId,
                            orderLineNo: orderLineNo,
                            orderNo: orderNo,
                            USINGAJAX: true         
                        },
                        error: function(data) {
                            console.log("Error: " + JSON.stringify(data));
                        },
                        load: function (data) {             
                            
                            if(JSON.stringify(data.orderLineSuccessfullyDeleted) === 'true') {
                                $.alert({
                                    title           : '',
                                    content         : dsoSuccessMsg_1 + ' ' + orderNo + ' ' + dsoSuccessMsg_2,
                                    confirmButton   : dsoBtnOk,
                                    animation       : 'opacity',
                                    closeAnimation  : 'opacity',
                                    animationSpeed  : '1000',
                                });
                                callback(data);
                            } else if(JSON.stringify(data.orderLineSuccessfullyDeleted) === 'false') {
                                $.alert({
                                    title           : dsoErrorTitle,
                                    content         : dsoErrorMsg + ' ' + orderNo,
                                    confirmButton   : dsoBtnOk,
                                    animation       : 'opacity',
                                    closeAnimation  : 'opacity',
                                    animationSpeed  : '1000',
                                });
                            }else {
                                $.alert({
                                    title           : dsoErrorTitle,
                                    content         : dsoErrorUnknownMsg,
                                    confirmButton   : dsoBtnOk,
                                    animation       : 'opacity',
                                    closeAnimation  : 'opacity',
                                    animationSpeed  : '1000',
                                });
                            }   
                        }    
                      });
            },
            cancel  : function() {
                
            }
        });
    },
    orderDetailView: function(orderNo){
        $.ajax({
            url: 'AjaxOrderDetail',
            dataType: 'html',
            data: {
                langId: WCParamJS.langId,
                storeId: WCParamJS.storeId,
                catalogId: WCParamJS.catalogId,
                orderNo: orderNo,
                requesttype: 'ajax'
            },
            success: function(data) {
                if(services.serviceValidation(data)){
                    userpage.OrderDetailViewCallback(data);
                }
            }
        });
    },
    searchOrderLinesView: function(orderNo){
        $.ajax({
            url: 'AjaxSearchOrderLines',
            dataType: 'html',
            data: {
                langId: WCParamJS.langId,
                storeId: WCParamJS.storeId,
                catalogId: WCParamJS.catalogId,
                orderNo: orderNo,
                requesttype: 'ajax'
            },
            success: function(data) {
                if(services.serviceValidation(data)){
                    userpage.SearchOrderLinesCallback(data);
                }
            },
        });
    },
    showSliderImage: function(url, callback) {
        $.ajax({
            url: 'AjaxSliderImage',
            dataType: 'html',
            data: {
                langId: WCParamJS.langId,
                storeId: WCParamJS.storeId,
                catalogId: WCParamJS.catalogId,
                url: url,
                requesttype: 'ajax'
            },
            success: function(data) {
                if(services.serviceValidation(data)){
                    callback(data);
                }
            }
        });
    },
    searchOrderLinesForReturn: function(searchKeyPartNo, searchKeyOrderNo, searchKeyOrderLineNo, callback){
        //console.log("services.searchOrderLinesForReturn, searchKeyPartNo: \""+searchKeyPartNo+"\" searchKeyOrderNo: \""+searchKeyOrderNo+"\" searchKeyOrderLineNo: \""+searchKeyOrderLineNo+"\"");
        dojo.xhrPost({
            url: urlRoot + 'AjaxSearchOrderLinesForReturn',             
            handleAs: "json-comment-filtered",
            content: {
                langId: WCParamJS.langId,
                storeId: WCParamJS.storeId,
                catalogId: WCParamJS.catalogId,
                searchKeyPartNo: searchKeyPartNo,
                searchKeyOrderNo: searchKeyOrderNo,
                searchKeyOrderLineNo: searchKeyOrderLineNo,
                USINGAJAX: true
            },
            error: function(errObj,ioArgs) {
                console.log("Error in services.searchOrderLinesForReturn!");
                // TODO Handle error
            },
            load: function (data) {             
                console.log("Success on services.searchOrderLinesForReturn!");
                console.log(data);
                callback(data);
            }    
        });
        
    },
    sendReturnOrder: function(returnCode,formValues, serializedValues,callback){
//      console.log("services.sendReturnOrder, searchKeyPartNo: \"+serialzedValues+"\");
        dojo.xhrPost({
            url: urlRoot + 'AjaxCreateReturnOrder',             
            handleAs: "json-comment-filtered",
            content: {
                langId: WCParamJS.langId,
                storeId: WCParamJS.storeId,
                catalogId: WCParamJS.catalogId,
                returnCode: returnCode,
                formValues: formValues,
                serializedValues: serializedValues,
                USINGAJAX: true
            },
            error: function(errObj,ioArgs) {
                console.log("Error in services.sendReturnOrder!");
                callback(null);
                // TODO Handle error
            },
            load: function (data) {             
                console.log("Success on services.sendReturnOrder!");
                console.log(data);
                callback(data);
            }    
        });
        
    },
    searchAutoSuggest: function(searchTerm, callback) {
        $.ajax({
            url: 'AjaxAutoSuggest',
            dataType: 'html',
            data: {
                langId: WCParamJS.langId,
                storeId: WCParamJS.storeId,
                catalogId: WCParamJS.catalogId,
                searchTerm: searchTerm,
                requesttype: 'ajax'
            },
            success: function(data) {
                if(services.serviceValidation(data)){
                    callback(data);
                }
            }
        });
    },
    /*sendEmail: function(messageType, subject, param) {
        $.ajax({
            url: urlRoot + 'genericCMSEmail?' + param,
            dataType: 'html',
            data: {
                langId: WCParamJS.langId,
                storeId: WCParamJS.storeId,
                catalogId: WCParamJS.catalogId,
                authToken: WCParamJS.authToken,
                USINGAJAX: true,
                messageType: messageType,
                subject: subject,
            },
            success: function(data) {
                console.log(data);
            }
        });
    },*/
    sendEmail: function(form) {
    	form.langId = WCParamJS.langId; 
    	form.storeId = WCParamJS.storeId; 
    	form.catalogId = WCParamJS.catalogId; 
    	form.authToken = WCParamJS.authToken; 
    	form.USINGAJAX = true; 
    	
        dojo.xhrPost({
            url: urlRoot + 'genericCMSEmail',            
            handleAs: "json-comment-filtered",
            content: form,
            error: function(errObj,ioArgs) {
            	MessageHelper.displayErrorMessage(services.messages.invalidEmail);
            },
            load: function (data) {             
            	if(data.sentSuccessfully === true){
            		MessageHelper.displayConfirmMessage(services.messages.confirmEmail);
            		$('.js-cke-input').val('');
            	} else {
            		MessageHelper.displayErrorMessage(services.messages.invalidEmail);
            	}
            }    
        });
        
    },
    createNewOrder: function(){
    	$.ajax({
            url: urlRoot + 'AjaxOrderCreate',
            dataType: 'html',
            data: {
                langId: WCParamJS.langId,
                storeId: WCParamJS.storeId,
                catalogId: WCParamJS.catalogId,
                USINGAJAX: true,
            },
            success: function(data) {
                console.log(data);
            }
        });
    },
    searchOrderLines: function(param, callback){
    	$.ajax({
    	    url: urlRoot + 'AjaxSearchOrderLines?' + param,
    	    dataType: 'html',
    	    data: {
    	        langId: WCParamJS.langId,
    	        storeId: WCParamJS.storeId,
    	        catalogId: WCParamJS.catalogId,
    	        USINGAJAX: true,
    	    },
    	    success: function(data) {
    	        if(services.serviceValidation(data)){
    	        	callback(data);
    	        }
    	    }
    	});
    },
    
    becomeSelf: function(){
    	var url = urlRoot+"AjaxRestoreOriginalUserSetInSession";
    	$.ajax({
			type: "GET",
			url: url,
			data: {
				authToken: WCParamJS.authToken
			},
			dataType: "html",
			success: function (data) {
				//location.reload();
				
				var url = location.search.substring(1);
		        if (location.search.substring(1).length != 0) {
		            var params = JSON.parse('{"' + decodeURI(url).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');
		        } else {
		            var params = {};
		        }
		        params.hideNetPrice = WCParamJS.hideNetPrice;
		        params.cass=false;
		        window.location = location.origin + location.pathname + '?' + $.param(params);
			}
		});
    },

    tabContent: function(ajaxCommand, params, callback) {
        callback = typeof params === 'function' ? params : callback;
        params = typeof params === 'function' ? {} : params;

        var data = $.extend({}, {
            langId: WCParamJS.langId,
            storeId: WCParamJS.storeId,
            catalogId: WCParamJS.catalogId,
            USINGAJAX: false,
        }, params);

        $.ajax({
            url: urlRoot + ajaxCommand,
            dataType: 'html',
            data: data,
            success: function(data) {
                callback(null, data);
            },
            error: function(err) {
                callback(err);
            },
        });
    },

    productProperties: function(product, params, callback) {
        product = product || {};
        callback = typeof params === 'function' ? params : callback;
        params = typeof params === 'function' ? {} : params;

        // Merge params and product info with default params.
        var data = $.extend({}, {
            langId: WCParamJS.langId,
            storeId: WCParamJS.storeId,
            catalogId: WCParamJS.catalogId,
            USINGAJAX: true,
            requestType: 'ajax'
        }, params, product);

        $.ajax({
            url: urlRoot + 'AjaxItemOnlineInfo',
            dataType: 'html',
            data: data,
            success: function(data) {
                callback(null, data);
            },
            error: function(err) {
                callback(err);
            },
        });
    },

    additionalArticleData: function(productIds, callback) {
        productIds = productIds || [];
        callback = typeof callback === 'function' ? callback : function() {};

        var data = {
            productIds: productIds
        };

        var url = '/wcs/resources/store/' + WCParamJS.storeId + '/productinfomulti/getRequestedProductListQtyOne';

        $.ajax({
            url: url,
            method: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify(data),
            success: function(data) {
                callback(null, data);
            },
            error: function(err) {
                callback(err);
            },
        });
    },
    
    serviceValidation: function(returnedAjaxData){
        var data = returnedAjaxData;
        if(typeof data !== 'object'){
            data = services.JSONParse(returnedAjaxData);
        }
        if(data !== null && data.errorMessageKey !== undefined){
            console.log(data);
            if(data.errorMessageKey === '_ERR_INVALID_COOKIE'){
                MessageHelper.displayErrorMessage(MessageHelper.messages["INVALID_COOKIE_ERROR"]);
            }else if(data.errorMessageKey === '_ERR_DIDNT_LOGON'){
                MessageHelper.displayErrorMessage(MessageHelper.messages["USER_SESSION_TIMEOUT_DETAILS"]);
            } else{
                MessageHelper.displayErrorMessage(data.errorMessage);
            }           
            setTimeout(function(){
                window.location.reload(); 
            }, 3000);
        }else{
            return true; 
        }
    },
    jsonCommentFilter: function(returnedAjaxData, type){
    	var data = returnedAjaxData.trim();
		return data.replace(/^\/\*|\*\/$/g, '');
    },
    JSONParse: function(returnedAjaxData){
        if(typeof returnedAjaxData !== 'object' && returnedAjaxData !== undefined){
	    	var data = returnedAjaxData.trim();
            data = data.toString().replace(/^\/\*|\*\/$/g, '');
            try {
            	return JSON.parse(data);
            }
            catch(err) {
            	return null;
            }
        }
        return returnedAjaxData;
    }
};
