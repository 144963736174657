//-----------------------------------------------------------------
// Licensed Materials - Property of IBM
//
// WebSphere Commerce
//
// (C) Copyright IBM Corp. 2016 All Rights Reserved.
//
// US Government Users Restricted Rights - Use, duplication or
// disclosure restricted by GSA ADP Schedule Contract with
// IBM Corp.
//-----------------------------------------------------------------

var conversionEventText = {
    cart: "Orderblock", 
    wishist: "Sparade order",
    requisitionList: "Ordermall",
    catalog: "Katalog"
};

var analytics = {
    conversionEvent: "AnalyticsConversionEventView",
    element: "AnalyticsElementView",
    shopAction: "AnalyticsShopcartView",
    
    analyticsView: function(jsonViewName, urlParams, callback) {
        return $.ajax({
            url: getAbsoluteURL() + jsonViewName  + '?' + $.param(urlParams),
            dataType: 'html',
            data: {
                langId: WCParamJS.langId,
                storeId: WCParamJS.storeId,
                catalogId: WCParamJS.catalogId,
                USINGAJAX: true,
            },
            success: function(data) {
                callback(data);
            }
        });
    },

    publishConversionEvent: function(conversionEventData, actionType){
        conversionEventData["actionType"] = actionType;
        return analytics.analyticsView(analytics.conversionEvent, conversionEventData, analytics.createConversionEvent);
    },
    
    publishElement: function(elementData){
        return analytics.analyticsView(analytics.element, elementData, analytics.createElement);
    },
    
    publishCartAction: function(){
        return analytics.analyticsView(analytics.shopAction, {}, analytics.createShopAction);
    },
    
    createConversionEvent: function(returnedAjaxData){
        var data = analytics.JSONFormat(returnedAjaxData);
        if(data != undefined){
            data = data[0];
            cmCreateConversionEventTag(
                data.eventId,
                data.actionType,
                data.category,
                data.points,
                data.extraparms
            );
            $('#cm-conversionevent').html($('#cm-conversionevent').html() + returnedAjaxData + '<br>');
            $('#cm-element').html('cmCreateConversionEventTag(' + data.eventId + ',' + data.actionType + ',' + data.category + ',' + data.points + ',' + data.extraparms + ');<br>');
        }
    },
    
    createElement: function(returnedAjaxData){
        var data = analytics.JSONFormat(returnedAjaxData);
        if(data != undefined){
            data = data[0];
            cmCreateElementTag(
                data.elementId,
                data.category
            );
            $('#cm-element').html($('#cm-element').html() + returnedAjaxData + '<br>');
            $('#cm-element').html('cmCreateElementTag(' + data.elementId + ',' + data.category + ');<br>');
        }
    },
    
    createShopAction: function(returnedAjaxData){
        var data = analytics.JSONFormat(returnedAjaxData);
        if(data != undefined){
            data = data[0].cart;
            for(var i = 0; i < data.length; i++){
                cmCreateShopAction5Tag(
                    data[i].productId,
                    data[i].name,
                    data[i].quantity,
                    data[i].price,
                    data[i].category,
                    data[i].storeId,
                    data[i].currency
                );
            }
            cmDisplayShop5s();
            $('#cm-shopAction').html($('#cm-shopAction').html() + returnedAjaxData + '<br>');
            $('#cm-element').html('cmCreateConversionEventTag(' + data.productId + ',' + data.name + ',' + data.quantity + ',' + data.price+ ',' + data.category+ ',' + data.storeId + ',' + data.currency + ');<br>cmDisplayShop5s();<br>');
        }
    },
    
    JSONFormat: function(returnedAjaxData) {
        try {
            /*var JSONString = returnedAjaxData
                    .replace(/([\$\w]+)\s*:/g, function(_, $1){return '"'+$1+'":'})    
                    .replace(/'([^']+)'/g, function(_, $1){return '"'+$1+'"'});
            var data = JSON.parse(JSONString); */
            var data = eval(returnedAjaxData);
            return data;
        } catch(e) {
            console.log(e);
            return undefined;
        }  
    },
    
    ga4EventStart: function(data, sendCartEvent) {
    	var goAhead = data["bypass"];
    	if(!goAhead){
    		try{
    			if(window.CookieInformation.getConsentGivenFor('cookie_cat_statistic')) {
    				goAhead = true;
    			}
    		} catch (error){
    			goAhead = false;
    		}
    	}
    	if(goAhead) {
        	data["item"] = analytics.ga4GetItemsHelper(data["item"]);
        	data["client_id"] = analytics.ga4GetClientIDHelper();
        	data["session_id"] = analytics.ga4GetSessionIDHelper(data["ga4MId"]);
        	analytics.ga4ViewItem(data);
        	if(sendCartEvent) {
            	analytics.ga4AddToCart(data);
        	}
    	}
    },
    
    ga4ViewItem: function(data) {
    	var url = data["url"];
    	var item = data["item"];
    	var currency = data["currency"];
    	item["price"] = data["price"];
    	item["quantity"] = 1;
    	var items = [];
    	items.push(item);

		const params = {
			"engagement_time_msec": "100",
		    "session_id": data["session_id"],
			"value": data["price"],
			"currency": currency,
			"items": items
		};

		const event = {
			"name": "view_item",
			"params": params
		};

		const events = [event];
		
		const JSONObj = {
			"client_id": data["client_id"],
			"events": events
		};

		fetch(url, {
			method: "POST",
			body: JSON.stringify(JSONObj)
		});
    },
    
    ga4AddToCart: function(data) {
    	var url = data["url"];
    	var currency = data["currency"];
    	var price = data["price"];
    	var quantity = data["quantity"];
    	var value = price*quantity;
    	var item = data["item"];
    	item["price"] = price;
    	item["quantity"] = quantity;
    	var items = [];
    	items.push(item);
    	
		const params = {
			"engagement_time_msec": "100",
		    "session_id": data["session_id"],
			"value": value,
			"currency": currency,
			"items": items
		};

		const event = {
			"name": "add_to_cart",
			"params": params
		};

		const events = [event];
		
		const JSONObj = {
			"client_id": data["client_id"],
			"events": events
		};

		fetch(url, {
			method: "POST",
			body: JSON.stringify(JSONObj)
		});
    },
    
    ga4GetItemsHelper: function(GA4Item) {
    	var itemTmp = GA4Item.split(";");
    	var items = {};
    	for(var i in itemTmp){
    		var tmp = itemTmp[i].split(":");
    		items[tmp[0].trim()] = tmp[1].trim();
    	}
    	return items;
    },
    
    ga4GetClientIDHelper: function() {
    	var client_id = "";
		var cookieName = "_ga";
    	var cookieContent = analytics.ga4CookieHelper(cookieName);
		var tmpid = cookieContent.split('.');
		if(tmpid.length > 3) {
			client_id = tmpid[2] + '.' + tmpid[3];
		}
		if(!client_id.length > 0) {
			client_id = "unknownUser" + Math.floor(Math.random() * 1000000000);
		}
		return client_id;
    },
    
    ga4GetSessionIDHelper: function(measId) {
    	var cookieName = "_ga_" + measId.split('-')[1];
    	var cookieContent = analytics.ga4CookieHelper(cookieName);
    	var session_id = "";
    	if (cookieContent != "") {
    		session_id = cookieContent.split('.')[2];
    	}
		return session_id;
    },
    
    ga4CookieHelper: function(cookieName) {
    	var gaCookie = document.cookie.match('(^|;)\\s*' + cookieName + '\\s*=\\s*([^;]+)');
    	var cookieContent = "";
    	if(gaCookie){
    		cookieContent = gaCookie.pop();
    	}
    	return cookieContent;
    },
    
    ga4ViewItemHelper: function() {
    	var el = document.getElementById("ga4-item-data");

        try {
            var GA4Url = $(el).attr('ga4Url');
            var GA4Item = $(el).attr('ga4Item');
            if(typeof GA4Url != "undefined" && GA4Url != "false" && typeof GA4Item != "undefined" && GA4Item != "false") {
    	        var ga4Bypass = $(el).attr('ga4Bypass');
    	        var ga4MId = $(el).attr('ga4MId');
    	        var currency = WCParamJS.commandContextCurrency;

    	    	var priceEl = document.getElementById("ga4-price");
    	        var price = "0.0";
    	        if(typeof GA4Item != "undefined") {
    		        price = $(priceEl).attr("ga4price");
    		        price = price.replace(",", ".").replace(String.fromCharCode(160),"");
    	        }
    	    	var ga4Data = {};
    	    	ga4Data["url"] = GA4Url;
    	    	ga4Data["item"] = GA4Item;
    	    	ga4Data["price"] = +price;
    	    	ga4Data["currency"] = currency;
    	    	ga4Data["bypass"] = ga4Bypass != "";
    	    	ga4Data["ga4MId"] = ga4MId;
    	    	analytics.ga4EventStart(ga4Data, false);
            }
        } catch(error){
        	console.log("Ga4 failed: ", error);
        }
    },

//  JSONFormat: function(returnedAjaxData){
//      try {
//          var regexKeys = /({|,)(?:\s*)(?:')?([A-Za-z_$\.][A-Za-z0-9_ \-\.$]*)(?:')?(?:\s*):/g;
//          var JSONString = returnedAjaxData.replace(regexKeys, "$1\"$2\":");
//          JSONString = JSONString.replace("'", "2");
//          console.log(JSONString);
//          return JSON.parse(JSONString);
//      } catch(e) {
//          console.log(e);
//      }
//  },
    
    facet: "Facetter",
    pdf: "Pdf",
    document: "Dokument",
    safety: "Säkerhetsdokument",
    error: "Felmeddelande",
    product: "Produkt",
    article: "Artikel",
    showArticle: "Visa Artiklar",
    compare: "Jämför",
    wishist: "Sparade order",
    requisitionList: "Ordermall",
};
