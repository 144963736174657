//-----------------------------------------------------------------
// Licensed Materials - Property of IBM
//
// WebSphere Commerce
//
// (C) Copyright IBM Corp. 2016 All Rights Reserved.
//
// US Government Users Restricted Rights - Use, duplication or
// disclosure restricted by GSA ADP Schedule Contract with
// IBM Corp.
//-----------------------------------------------------------------

var cart = {
    msg: null,
    gtin: {},
    orderTypes: [],
    
    init: function() {
        $(document).ready(function() {
            $('html').on("click", function() {
               $('.js-hook-quickOrder').hide(); 
            });
            
           if($('input[name=alternativeaddress]').prop("checked")){
        	   $("div.js-hook-deliveryAddressDefault").hide();
               $("div.js-hook-deliveryAddress").show();
            }
            /*$('.js-hook-datepicker-input').datepicker();
            $('.js-hook-datepicker-input').datepicker('option', 'prevText', '<i class="fa fa-angle-left fa-2x"></i>');
            $('.js-hook-datepicker-input').datepicker('option', 'nextText', '<i class="fa fa-angle-right fa-2x"></i>');
            $('.js-hook-datepicker-input').datepicker('option', 'showOtherMonths', true);
            $('.js-hook-datepicker-input').datepicker('option', 'selectOtherMonths', true);*/
            
            var selected = $(".js-hook-xOrderTypeSelector option:selected");
            var leadTime = $(selected).attr('data-leadTime');
            var minDate = new Date();
            minDate.setDate(minDate.getDate() + 1);
            var dates = $('.js-hook-datepicker-input').datepicker({
                prevText: '<i class="fa fa-angle-left fa-2x"></i>',
                nextText: '<i class="fa fa-angle-right fa-2x"></i>',
                constrainInput: true,
                showOtherMonths: true,
                selectOtherMonths: true,
                minDate: minDate,
                maxDate: "+6m",
                hideIfNoPrevNext: true,
                beforeShowDay: $.datepicker.noWeekends,
            });
            
            $('#ui-datepicker-div').hide();
            $('.js-hook-datepicker').append($('#ui-datepicker-div'));
            cart.validateDate();
            $(".hasDatepicker").click(function(e){
                $("#ui-datepicker-div").css({'top': 'auto','left':'auto'});
            });
        });
    },
    
    clickAutoSuggest: function(){
        $('.js-hook-quickOrder').show();
        event.stopPropagation();
    },
    
    inputAutoSuggest: function(){
        cart.gtin = {};
        services.searchAutoSuggest( $('.js-hook-autoPartNumber').val(), function(data){
            $('.js-hook-quickOrder').html(data);
            $('.js-hook-quickOrder').show();
            cart.appendAutoSuggest('', $('.js-hook-autoPartNumber').val());
        });
    },
    
    keyDownAutoSuggest: function(e, callback){
        if(e.keyCode == 40){
            e.preventDefault();
            $(".js-hook-skuAddSearch").first().focus();
        } else if(e.keyCode == 13){
            callback();
        }
    },
    
    keyDownMark: function(e, callback){
        if(e.keyCode == 13){
            callback();
        }
    },
    
    navigateAutoSuggest: function(e, el){
        var i = $(el).data('index');
        if(e.keyCode == 40){
            e.preventDefault();
            i += 1;
            $('.js-hook-skuAddSearch[data-index="' + i + '"]').focus();
        } else if(e.keyCode == 38){
            e.preventDefault();
            i -= 1;
            $('.js-hook-skuAddSearch[data-index="' + i + '"]').focus();
        } else if(e.keyCode == 13){
            cart.submitAutoSuggest(el);
        }
    },
    appendAutoSuggest: function(gtin, partNumber){
        console.log('append');
        var gtins = cart.gtin[partNumber];
        var selected = '';
        var select = $('.js-hook-quickOrderGtin-select');
        var search = $('.js-hook-autoPartNumber').val().trim();
        $(select).html('');
        
        if(gtins !== undefined){
            for (var key in gtins) {
                if (gtins.hasOwnProperty(key)) { 
                    console.log(key + " -> " + gtins[key]);
                }
                if(gtin == key){
                     selected = 'selected';
                     if(search == gtin) {
                    	 $('.js-hook-autoPartNumber').val(gtin);
                     } else if(search == partNumber) {
                    	 $('.js-hook-autoPartNumber').val(partNumber);
                     }
                }
                select.append('<option data-gtin="' + key + '" data-partnumber="' + partNumber + '" '  + selected + '>' + gtins[key] + '</option>')
                selected = '';
            }
            //$('.js-hook-quickOrderGtin').prop( "disabled", false );
            $('.js-hook-quickOrderGtin').show();
        } else {
            //$('.js-hook-quickOrderGtin').prop( "disabled", true );
            $('.js-hook-quickOrderGtin').hide();
        }
    },
    submitAutoSuggest: function(el){
    	//$('.js-hook-autoPartNumber').val($(el).data('gtin'));
        $('.js-hook-autoPartNumber').focus();
        $('.js-hook-quickOrder').hide(); 
        cart.appendAutoSuggest($(el).data('gtin'), $(el).data('partnumber'));
    },
    moveFocusToQty: function(){
        $(".js-hook-autoQuantity").focus();
    },
    moveFocusToGTIN: function(){
        $(".js-hook-autoPartNumber").focus();
    },
    submitFromQty: function(e){
        if(e.keyCode == 13){
            $('.js-hook-quickOrder').hide(); 
            cart.addItemToCart();
        }
    },
    deleteFromCart: function(orderItemId){
    	this.msg = "removeItem";
        $('.js-hook-spinner').show();
        var orderId= $('.js-hook-orderId').val();
        services.deleteFromCart(orderId, orderItemId, cart.addItemToCartCallback);
    },
    addItemToCart: function(){
        this.msg = "addItem";
        $('.js-hook-spinner').show();
        
        var orderId= $('.js-hook-orderId').val();
        var input = $(".js-hook-autoPartNumber").val().trim();
        var goodsmark = $(".js-hook-autoGoodsmark").val();
        var el = $(".js-hook-skuAddSearch[data-partNumber='" + input + "']");
        if(el[0] === undefined){
            el = $(".js-hook-skuAddSearch[data-gtin='" + input + "']");
        }
        var productId = $(el).data('productid');
        var partNumber = $(el).data('partnumber');
        
        var gtin = $(".js-hook-quickOrderGtin option:selected").data('gtin');
        if(gtin === undefined){
            gtin = $(el).data('gtin');
        }
        var quantityMultiple = $(el).data('qtymultiple');
        if(partNumber === "" || partNumber === undefined){
            $('.js-hook-spinner').hide();
            MessageHelper.displayErrorMessage(MessageHelper.messages["ERROR_SKU_EMPTY"]);
            return false;
        }
        
        var quantity = $(".js-hook-autoQuantity").val();
        if(quantity === ""){
            quantity = 1;
        }else if(isNaN(quantity) || quantity < 0){
            $('.js-hook-spinner').hide();
            MessageHelper.displayErrorMessage(MessageHelper.messages["QUANTITY_INPUT_ERROR"]);
            return false;
        }
        
        if(goodsmark === undefined){
            goodsmark = "";
        }
        
        services.addItemToCart(productId, partNumber, quantity, goodsmark, gtin, cart.addItemToCartCallback);
    },
    addItemToCartCallback: function(returnedAjaxData){
        if(cart.errorMessageHandler(returnedAjaxData)){
            services.calculateOrderItems($('.js-hook-orderId').val(), function(){
                if($('.js-hook-table').length == 0){
                    location.reload();
                } else {
                	//	services.updateCartTotalprice(cart.updateCartTotalpriceCallback);
                    //services.updateMiniCart();
                    cart.updateOrderItemView();
                    $('.js-hook-spinner').hide();
                    cart.messageHandler(returnedAjaxData);
                    $(".js-hook-autoPartNumber").focus(); 
                    $(".js-hook-autoPartNumber").val('');
                    $(".js-hook-autoQuantity").val('');
                }
            });
        }
    },
    
    updateCartTotalpriceCallback: function(returnedAjax){
        $('.js-hook-orderTotals').html(returnedAjax);
    },
    updateOrderItemView: function(){
        var orderId = $('.js-hook-orderId').val();
        services.updateOrderItemView(orderId, cart.updateOrderItemViewCallback);
    },
    updateOrderItemViewCallback: function(returnedAjaxData){
        $('#ShopCartPagingDisplay').html(returnedAjaxData);
        cart.isValidOrderTypeCallback();
        $('.js-hook-spinner').hide();
        cart.errorMessageHandler(returnedAjaxData);
        cart.updateCartTotalprice();
    },
    updateCartTotalprice: function(){
    	var orderId = $('.js-hook-orderId').val(); 
        services.updateAllItems(orderId, cart.updateCartItemCallback.bind(null, undefined));
	},
	
    messageHandler: function(returnedAjaxData){
        var data = returnedAjaxData;
        if(typeof data !== 'object'){
            data = services.JSONParse(returnedAjaxData);
        }
        var msgKey = data.errorMessageKey;
        if(msgKey !== undefined){
            if (msgKey == '_ERR_COMMAND_EXCEPTION') {
                MessageHelper.displayErrorMessage(MessageHelper.messages["QUANTITY_INPUT_ERROR"]);
            } else if (msgKey == '_ERR_CMD_BAD_PARAMETER') {
            	MessageHelper.displayStatusMessage(MessageHelper.messages["QUANTITY_ITEM_UPDATE_FAIL"] + ' ' + data.errorMessageParam[1] + '.');
            } else {
                MessageHelper.displayErrorMessage(data.errorMessage);
            }
        } else if(this.msg == "updateQty"){
            MessageHelper.displayStatusMessage(MessageHelper.messages["QUANTITY_ITEM_UPDATE_SUCCESS"]);
            analytics.publishCartAction();
        } else if(this.msg == "updateAll"){
            MessageHelper.displayConfirmMessage(MessageHelper.messages["UPDATE_ALL_SUCCESS"]);
        } else if(this.msg == "updateShipping"){
            MessageHelper.displayStatusMessage(MessageHelper.messages["UPDATE_SUCCESS"]);
        } else if(this.msg == "addItem"){
            MessageHelper.displayConfirmMessage(MessageHelper.messages["ADDED_TO_ORDER"]);
        } else if(this.msg == "removeItem"){
        	MessageHelper.displayConfirmMessage(MessageHelper.messages["SHOPCART_REMOVEITEM"]);
        }
    },
    errorMessageHandler: function(returnedAjaxData){
        var data = services.JSONParse(returnedAjaxData);
        if(data && data.errorMessageKey  ){
        var msgKey = data.errorMessageKey;
        }
        else{
        	return true;
        }
        if(msgKey !== undefined){
            if (msgKey == '_ERR_GETTING_SKU' || msgKey =='_ERR_PROD_NOT_EXISTING') {
                MessageHelper.displayErrorMessage(MessageHelper.messages["ERROR_INVALID_SKU"]);
            } else if (msgKey == "_ERR_RETRIEVE_PRICE" || msgKey == '_ERR_PROD_NOT_ORDERABLE') {
                MessageHelper.displayErrorMessage(MessageHelper.messages["NOT_ORDERABLE_ERROR"], null, false);
            } else if (msgKey == '_ERR_CMD_BAD_PARAMETER'){
                MessageHelper.displayErrorMessage(MessageHelper.messages["ERROR_QUANTITY_MULTIPLE"] + ' ' + data.errorMessageParam[1] + '.' ,null , false);
            } else if (msgKey == '_ERR_COMMAND_EXCEPTION') {
                MessageHelper.displayErrorMessage(MessageHelper.messages["QUANTITY_INPUT_ERROR"]);
            } else if (msgKey == '_ERR_ORDER_IS_LOCKED') {
                MessageHelper.displayErrorMessage(MessageHelper.messages["ORDER_LOCKED_ERROR"]);
            } else if (msgKey == '_ERR_ORDER_IS_NOT_LOCKED') { 
                MessageHelper.displayErrorMessage(MessageHelper.messages["ORDER_NOT_LOCKED_ERROR"]);
            } else {
                MessageHelper.displayErrorMessage(data.errorMessage);
            }
            $('.js-hook-spinner').hide();
            return false;
        } else {
            return true;
        }
    },
    updateAllItems: function(){
        this.msg = "updateAll";
        $('.js-hook-spinner').show();
        var orderId = $('.js-hook-orderId').val();
        services.updateAllItems(orderId, cart.updateCartItemCallback.bind(null, undefined));
    },
    updateItem: function(el, itemId){
        this.msg = "updateQty";
        
        $('.js-hook-spinner').show();
        // get
        var row = $(el).closest('.js-hook-row');
        var select = $(row).find('.js-hook-gtinmenu');
        var partNumber = $(select).find(':selected').attr('data-partNumber');
        var purchaseNumber = $(select).find(':selected').attr('data-purchase-number');
        var gtin = $(select).find(':selected').attr('data-gitn');
        
        var productId = $(row).attr('data-productId');
        var orderItemsId = $(row).attr('data-orderItemsId');
        var quantity = $(row).find('.js-hook-quantity').val();
        // set
        //$(row).find('.js-hook-purchase-number').text(purchaseNumber);
        
        if(partNumber === purchaseNumber || typeof gtin  === "undefined") {
        	gtin = "";
        }
        var goodsmark = $(row).find(".js-hook-goodsmark").val();
        if(goodsmark === undefined){
            goodsmark = "";
        }
        services.updateItem(purchaseNumber ,productId, orderItemsId, quantity, goodsmark, gtin, cart.updateCartItemCallback.bind(row, itemId));
    },
    updateCartItemCallback: function(itemId, returnedAjaxData) {
    	if(itemId === undefined){
            for (var i = 1; i <= returnedAjaxData.totalNumberOfItems; i++) {
            	var orderItemsId = returnedAjaxData['orderItemsId_'+i];
                $('.js-totalprice-'+i).html(returnedAjaxData['totalProductPrice_'+orderItemsId]);
            }
        }else{
            var orderItemId = $('#orderItemsId_'+itemId).val();
            $('.js-totalprice-'+itemId).html(returnedAjaxData['totalProductPrice_'+orderItemId]);
//            $('.js-price-'+itemId).html(returnedAjaxData['price_'+orderItemId]);
//            $('.js-availability-'+itemId).html(returnedAjaxData['availabilityInSKUMultiple_'+orderItemId]);
            
        }
        
        var orderId = $('.js-hook-orderId').val();
        console.log(returnedAjaxData['orderTotalProductPrice_'+orderId]);
        $('.js-total-product-amount').html(returnedAjaxData['orderTotalProductPrice_'+orderId]);

        if(returnedAjaxData['totalOrderShippingWeight'] !== undefined) {
            console.log(returnedAjaxData['totalOrderShippingWeight']);
        	$('.js-total-product-shipping-weight').html(returnedAjaxData['totalOrderShippingWeight']);
        }
        
        var row = $(this);
        if($(row)) {
	        var data = returnedAjaxData;
	        
	        if(typeof data !== 'object'){
	            data = services.JSONParse(returnedAjaxData);
	        }
	        
	        if(data && !data.errorMessageKey ) {
	        	var purchaseNumber = $(row).find('.js-hook-gtinmenu').find(':selected').attr('data-purchase-number');
	        	var gtin = $(row).find('.js-hook-gtinmenu').find(':selected').attr('data-gtin');
	            $(row).find('.js-hook-purchase-number').text(purchaseNumber);
	            
	            if(gtin !== purchaseNumber) {
	            	 $(row).find('.js-hook-gtin').text(gtin);
	            } else {
	            	$(row).find('.js-hook-gtin').text('');
	            }
	            
	        }
        }
        if(wcGlobal.poUser){ 
        	cart.updatePunchOutForm(function(){
        		$('.js-hook-spinner').hide();
                cart.messageHandler(returnedAjaxData);
            });
        }else{
        	$('.js-hook-spinner').hide();
            cart.messageHandler(returnedAjaxData);
        }
        
        
        
    },
    updatePunchOutForm: function(callback) {
    	if(wcGlobal.poUser){
    		$(cart.submitClass).prop('disabled', true);
	    	$.ajax({
	            url: urlRoot + 'AjaxPunchOutForm',
	            dataType: 'html',
	            data: {
	                langId: WCParamJS.langId,
	                storeId: WCParamJS.storeId,
	                catalogId: WCParamJS.catalogId,
	                USINGAJAX: true,
	                requesttype: 'ajax'
	            },
	            success: function(data) {
	            	$('#PunchOutForm').replaceWith(data);
	            },
	            complete: function(){
	            	$(cart.submitClass).prop('disabled', false);
	            	
	            	if(typeof callback ==='function'){
	                    callback();
	                }
	            }
	        });
    	}
    },
    checkoutPunchOutForm: function(orderId) {
    	$.ajax({
            url: urlRoot + 'PunchOrder',
            dataType: 'html',
            data: {
                langId: WCParamJS.langId,
                storeId: WCParamJS.storeId,
                catalogId: WCParamJS.catalogId,
                orderId: orderId,
                USINGAJAX: true,
                requesttype: 'ajax'
            },
            success: function(data) {
            	//console.log('success',data);
            },
            complete: function(){
            	$('#PunchOutForm').submit();
            }
        });
    	
    },
    xOrderType: function(orderId){
        var selected = $(".js-hook-xOrderTypeSelector option:selected");
        
        var leadTime = $(selected).attr('data-leadTime');
        $(".js-hook-xOrderTypeLeadTime").html(leadTime);
        var orderStop = $(selected).attr('data-orderStop');
        $(".js-hook-xOrderTypeOrderStop").html(orderStop);
        var orderStopDay = $(selected).attr('data-orderStopDay');
        $(".js-hook-xOrderTypeOrderStopDay").html(orderStopDay);
        var weeklyOrder = $(selected).attr('data-weeklyOrder');
        $(".js-hook-xOrderTypeWeeklyOrder").html(weeklyOrder);
        var backOrder = $(selected).attr('data-backOrder');
        $(".js-hook-xOrderTypeBackOrder").html(backOrder);
        var optionalDeliveryAddress = $(selected).attr('data-optionalDeliveryAddress');
        $(".js-hook-xOrderTypeOptionalDeliveryAddress").html(optionalDeliveryAddress);
	    var optionalDeliveryDate = $(selected).attr('data-optionalDeliveryDate');
	    $(".js-hook-xOrderTypeOptionalDeliveryDate").html(optionalDeliveryDate);
        var charge = $(selected).attr('data-charge');
        $(".js-hook-xOrderTypeCharge").html(charge);
        var orderLimit = $(selected).attr('data-orderLimit');
        $(".js-hook-xOrderTypeLimit").html(orderLimit);
        var currency = $(selected).attr('data-currency');
        $(".js-hook-xOrderCurrency").html(currency);
        
        var disableCheckbox = ($(selected).attr('data-optionalDeliveryAddress') == 1) ? false : true;
        var forcedAddr = $(document.getElementById("js-hook-forced-alt-addr")).attr("isForced");
        $("div.js-order-header-info input#checkbox_1").prop('disabled', disableCheckbox);
        $("div.js-order-header-info input#checkbox_1").prop('readonly', disableCheckbox);
        
        if(disableCheckbox){ $('input[name=alternativeaddress]').attr('checked', false);}
        var isAlternativeAddress = $('input[name=alternativeaddress]').is(':checked');
        
        if($(selected).attr('data-optionalDeliveryAddress') == 1 && isAlternativeAddress){
            $("div.js-hook-deliveryAddress").show();
            $("div.js-hook-deliveryAddressDefault").hide();
            
            if(forcedAddr){
                $("#checkbox_1").attr('checked', true);
                $("div.js-order-header-info input#checkbox_1").prop('disabled', true);
                $("div.js-order-header-info input#checkbox_1").prop('readonly', true);
            }
        } else {
            $("div.js-hook-deliveryAddress").hide();
            $("div.js-hook-deliveryAddressDefault").show();
        }
        
        var editableDeliveryDate = ($(selected).attr('data-optionalDeliveryDate') == 1) ? false : true;
        $(".js-hook-deliveryDate-input").prop('readonly', editableDeliveryDate);
        $(".js-hook-deliveryDate-input").prop('disabled', editableDeliveryDate);
        $(".js-hook-optionaldeliverydate-input").val($(selected).attr('data-optionalDeliveryDate'));
        
        if(editableDeliveryDate){
            $(".js-hook-deliveryDate-input").val("");
        }
    },
    preDefAltAddr: function(){
    	var selected = $(".js-hook-preDefAltAddrSelector option:selected");

    	var reciver = $(selected).attr('data-reciver');
        $("input[name='addressname']").val(reciver);
    	var address1 = $(selected).attr('data-address1');
        $("input[name='address1']").val(address1);
    	var address2 = $(selected).attr('data-address2');
        $("input[name='address2']").val(address2);
    	var zipcode = $(selected).attr('data-zipcode');
        $("input[name='zipCode']").val(zipcode);
    	var city = $(selected).attr('data-city');
        $("input[name='city']").val(city);
    	var country = $(selected).attr('data-country');
        $("input[name='country']").val(country);

    },
    editAddress: function(){
        $("div.js-hook-deliveryAddressDefault").toggle();
        $("div.js-hook-deliveryAddress").toggle();
    },
    updateShopCart: function(orderId){
        var selected = $(".js-hook-xOrderTypeSelector option:selected");
        var leadTime = $(selected).attr('data-leadTime');
        
        var date = $('.js-hook-datepicker-input').val();
        if(cart.isValidDate(date, leadTime)){
            $('.js-hook-spinner').show();
            services.updateShopCart(orderId, cart.updateShopCartCallback);
        } else{
            MessageHelper.displayErrorMessage(date + ' ' + MessageHelper.messages["ERROR_INVALID_DATE"],0,false);
        }
    },
    updateShopCartCallback: function(returnedAjaxData){
        cart.msg = "updateShipping";
    	cart.isValidOrderTypeCallback();
        $('.js-hook-spinner').hide();
        cart.messageHandler(returnedAjaxData);
        var selected = $(".js-hook-xOrderTypeSelector option:selected");
        var leadTime = $(selected).attr('data-leadTime');
        var minDate = new Date();
        minDate.setDate(minDate.getDate() + 1);
        
        $('.js-hook-datepicker-input').datepicker('option', 'minDate', minDate);
    },
    validateDate: function(){
        var selected = $(".js-hook-xOrderTypeSelector option:selected");
        var leadTime = $(selected).attr('data-leadTime');
        
        var date = $('.js-hook-datepicker-input').val();
        if(!cart.isValidDate(date, leadTime)){
            var date = new Date();
            date.setDate(date.getDate() + 1);
            
            var day = date.getDay();
            if(day == 6 || day == 0){
                date.setDate(date.getDate() + 2);
            } else if(day == 0){
                date.setDate(date.getDate() + 1);
            }
            
            $('.js-hook-datepicker-input').val(date.toISOString().slice(0,10));
            
            var orderId = $('.js-hook-orderId').val();
            services.updateShopCart(orderId, cart.updateShopCartCallback);
        }
    },
    isValidDate: function(dateString, leadTime) {
        if(dateString === ""){
            return true;
        }
        
        if(typeof dateString === 'undefined'){
            return true;
        }
        
        var regEx = /^\d{4}-\d{2}-\d{2}$/;
        if(!dateString.match(regEx)){
            return false;
        }
        var date = new Date(dateString);
        if(!(date|0)){
            return false;
        }
        
        var day = date.getDay();
        if(day == 6 || day == 0){
            return false;
        }
        
        var minDate = new Date();
        minDate.setDate(minDate.getDate());
        if(!(minDate <=date)){
            return false;
        }
        
        var maxDate = new Date();
        maxDate.setMonth(maxDate.getMonth() + 6);
        if(!(maxDate > date)){
            return false;
        }
        return date.toISOString().slice(0,10) == dateString;
    },
    isValidSeparatePackage: function(orderId) {
        var separatePackage = $('.js-hook-separate-package-input').val();
        if(separatePackage === ""){
            return true;
        }
        if(cart.isNumeric(separatePackage)){        
            /*
                $('.js-hook-spinner').show();
            */
            services.updateShopCart(orderId);
        } else{
            MessageHelper.displayErrorMessage(separatePackage + ' ' + MessageHelper.messages["ERROR_INVALID_SEPARATE_PACKAGE"],0,false);
        }
    }, 
    
    submitClass: ".js-order-submit",
    validationClass: '.js-hook-oplc[data-oplc="0"],.js-hook-oplc[data-oplc="1"]',
    errorMessageClass: 'form-validation-error-message',
    errorClass: 'order-list__error',
    messages: {
        invalidOrderType: 'Markerade rader kan inte levereras med den ordertyp som är vald'
    },
    
    isValidOrderType: function(){
    	var isValid = cart.validateOrderType();
    	if(isValid){
    		cart.setValidOrder();
    	} else {
    		cart.setInvalidOrder();
    	}
    	return isValid;
	},
	
	isValidOrderTypeCallback: function(){
    	if(cart.validateOrderType()){
			cart.setValidOrder();
    	} else if($(cart.submitClass).prop('disabled')){
			cart.setInvalidOrder();
    	}
	},
	
	setValidOrder: function(){
		$(cart.validationClass).closest(".js-hook-row").removeClass(cart.errorClass);
		$(cart.submitClass).prop('disabled', false);
		$(cart.submitClass).prev('.' + cart.errorMessageClass).html("");
	},
	
	setInvalidOrder: function(){
		$(cart.validationClass).closest(".js-hook-row").addClass(cart.errorClass);
		$(cart.submitClass).prev('.' + cart.errorMessageClass).html("");
		$('<div class="' + cart.errorMessageClass + '" style="margin-top:0"><i class="fa fa-remove" aria-hidden="true"></i>' + cart.messages['invalidOrderType'] + '</div>').insertBefore($(cart.submitClass));
		$(cart.submitClass).prop('disabled', true);
	},
	
	validateOrderType: function(){
		var isValid = true;
    	for (i = 0; i < cart.orderTypes.length; i++) {
			if(cart.orderTypes[i] === $('.js-hook-xOrderTypeSelector option:selected').val() &&
				$(cart.validationClass).length > 0){
				return false;
		    }
		}
		return true;
	},
	
	validatePhoneNr: function() {
		var phonenr = document.getElementsByName('phoneNumber');
		if(phonenr.length > 0) {
			var val = phonenr[0].value;
			if(val.length == 0 || val.length > 8 && val.length < 16) {
				return true;
			}
			else {
				MessageHelper.displayErrorMessage(MessageHelper.messages["ERROR_WRONG_PHONE_SHORT"]);
				return false;
			}
		} 
		return true;
	},
	
	validateEmail: function() {
		var email = document.getElementsByName('emailAddress');
		if(email.length > 0) {
			var val = email[0].value;
			if(val.length > 0) {
				if(val.lastIndexOf("@") > -1 && val.lastIndexOf(".") > -1) {
					return true;
				}
				else {
					MessageHelper.displayErrorMessage(MessageHelper.messages["ERROR_WRONG_EMAIL"]);
					return false;
				}
			} else {
				return true;
			}
		} 
		return true;
	},
    
     /**
     * Helper method to test if a value is numeric or not (only 1-9 characters, max three digits (1-999)
     */
    isNumeric: function (number){
        var validRegExp = /^[1-9]{1,3}$/;
        return validRegExp.test(number);
    },
    
    noOrderInfo: function(){
    	if($('.js-hook-noorder').is(":hidden")){
    		$('.js-hook-noorder').show();
    		event.stopPropagation();
    	} else {
    		$('.js-hook-noorder').hide();
    	}
    },
    
    noOrderInfoListener: function(){
    	$(window).click(function() {
	    	$('.js-hook-noorder').hide();
    	});
    }
};
