//-----------------------------------------------------------------
// Licensed Materials - Property of IBM
//
// WebSphere Commerce
//
// (C) Copyright IBM Corp. 2016 All Rights Reserved.
//
// US Government Users Restricted Rights - Use, duplication or
// disclosure restricted by GSA ADP Schedule Contract with
// IBM Corp.
//-----------------------------------------------------------------


//IE polyfill
if (!String.prototype.startsWith) {
  String.prototype.startsWith = function(searchString, position) {
    position = position || 0;
    return this.indexOf(searchString, position) === position;
  };
}

// Fix for IE10 location.origin error
if (!location.origin) {
    location.origin = location.protocol + "//" + location.hostname + (location.port ? ':' + location.port: '');
}

var catalogFilter = {
    currentFilterList: [],
    hiddenFilterList: [],
    currentPageSize: null,
    targetUrl: null,
    historyPV: 'grid',
    orderBy: '',
    currentPageNumber: 1,
    initDone: false,
    globalfacetsJS: {},
    attributeFilterURL: null,
    facetCounter: null,
    
    init: function(pageSize, tUrl, orderBy, pageView) {
        if (!catalogFilter.initDone) {
            //catalogFilter.currentFilterList = [];
        	
        	$('.js-hook-facetButton:checked').each(function(){
        		var filter = $(this);
        		var filterObj = {
        				filterId: filter.data('facetid'),
        				filterName: filter.data('facetname'),
        				attributeFacet: filter.data('attributefacet')
					};
                catalogFilter.currentFilterList.push(filterObj);
        	});
        	$('.js-hook-facetButton[data-hiddenfacet=true]').each(function(){
        		var filter = $(this);
        		var filterObj = {
        				filterId: filter.data('facetid'),
        				filterName: filter.data('facetname'),
        				attributeFacet: filter.data('attributefacet')
					};
                catalogFilter.hiddenFilterList.push(filterObj);
        	});
        	
            catalogFilter.currentPageSize = pageSize;
            catalogFilter.targetUrl = tUrl;
            catalogFilter.orderBy = orderBy;
            catalogFilter.historyPV = pageView;
            catalogFilter.loadHistoryState();
            catalogFilter.countActiveFilters();
            catalogFilter.initDone = true;
            
            if(!history.state){
                history.replaceState({'': ''}, '', location.origin + location.pathname + location.search);
            }
        }
//      if(pageSize == 20) {
//          $('.js-hook-pageSizeButton').removeClass('button__standard_active-pink');
//          $('.js-hook-pageSizeButton[data-pageSize=20]').addClass('button__standard_active-pink');
//      }
//      if(pageSize == 60) {
//          $('.js-hook-pageSizeButton').removeClass('button__standard_active-pink');
//          $('.js-hook-pageSizeButton[data-pageSize=60]').addClass('button__standard_active-pink');
//      }
    },
    
    setAttributeFilterURL: function( tUrl) {
        catalogFilter.attributeFilterURL = tUrl;
    },
    toggleAttributeFilter: function(el) {
    	catalogFilter.removeAttrFromList(catalogFilter.currentFilterList);
        catalogFilter.toggleFilter($(el).data('facetid'), $(el).data('facetname'), $(el).data('allowmultiplevalueselection'), $(el).data('attributefacet'));
        $('.js-hook-facetButton[data-attributefacet=true]').prop("checked", false);
        services.catalogView(catalogFilter.targetUrl, catalogFilter.currentFilterList, catalogFilter.currentPageSize, catalogFilter.catalogViewAttributeCallback, 0, catalogFilter.historyPV, catalogFilter.orderBy);
    },
    toggleCatalogFilter: function(el) {
    	
    	console.log("catalogFilter.initDone="+catalogFilter.initDone);
    	console.log("catalogFilter.targetUrl="+catalogFilter.targetUrl);
    	
        catalogFilter.toggleFilter($(el).data('facetid'), $(el).data('facetname'), $(el).data('allowmultiplevalueselection'), $(el).data('attributefacet'));
        services.catalogView(catalogFilter.targetUrl, catalogFilter.currentFilterList, catalogFilter.currentPageSize, catalogFilter.catalogViewCallback, 0, catalogFilter.historyPV, catalogFilter.orderBy);
    },
    toggleFilter: function(filterId, filterName, allowMultipleValueSelection, attributeFacet) {
        var filterObj = {filterId: filterId, filterName: filterName, attributeFacet: attributeFacet};
        //kolla om filtret redan finns, ta då bort det, annars lägg på det
        if (!catalogFilter.isFilterInList(filterObj, catalogFilter.currentFilterList)) {
            //filtret finns inte i listan, lägg till det
            if (allowMultipleValueSelection) {
                catalogFilter.currentFilterList.push(filterObj);
            } else {
                //remove other filters for same facet
                var i = filterName.indexOf(':')
                if (i>0) {
                    var facet = filterName.substr(0,i); 
                } else {
                    var i = filterName.indexOf('%3A')
                    if (i>0) {
                        var facet = filterName.substr(0,i); 
                    }   
                }
                
                catalogFilter.removeFacetFromList(facet, catalogFilter.currentFilterList);
                    
                catalogFilter.currentFilterList.push(filterObj);
            }
        } else {
            catalogFilter.removeObjFromList(filterObj, catalogFilter.currentFilterList);
            $('.js-hook-facetButton[data-facetid=' + filterId + ']').prop("checked", false);
        }
        
        $('.js-hook-spinner').show();
    },
    isFilterInList: function(filterObj, list) {
        if (list != null) {
            for (var i=0; i < list.length; i++) {
                if (list[i].filterId == filterObj.filterId) {
                    return true;
                }
            }
        }
        return false;
    },
    removeObjFromList: function(filterObj, list) {
        if (catalogFilter.isFilterInList(filterObj, list)) {
            for (var i=0; i < list.length; i++) {
                if (list[i].filterId == filterObj.filterId) {
                    list.splice(i, 1);
                }
            }
        }
        return list;
    },
    removeFacetFromList: function(facet, list) {
        if (facet != 'undefined') {
            for (var i=0; i < list.length; i++) {
                if (list[i].filterName.startsWith(facet)) {
                    $('.js-hook-facetButton[data-facetid=' + list[i].filterId + ']').prop("checked", false);
                    list.splice(i, 1);
                }
            }
        }
        return list;
    },
    removeAttrFromList: function(list) {
    	console.log(list);
        var i = list.length
        while (i--) {
            if (list[i].attributeFacet === true) {
                list.splice(i, 1);
            }
        }
        return list;
    },
    setPageSize: function(el) {
        $('.js-hook-spinner').show();
        if(!$(el).hasClass('selected')){
            $('.js-hook-pageSizeButton').removeClass('selected');
            $(el).addClass('selected');
        }
        catalogFilter.currentPageSize = $(el).data('pagesize');
        catalogFilter.replaceHistoryState();
        services.catalogView(catalogFilter.targetUrl, catalogFilter.currentFilterList, catalogFilter.currentPageSize, catalogFilter.catalogViewCallback, 0, catalogFilter.historyPV, catalogFilter.orderBy);
    },
    setOrderBy: function(el, orderBy) {
        $('.js-hook-spinner').show();
        if($(el).hasClass('selected')){
            if($(el).hasClass('js-hook-dFilter')){
                var btn = $(el).parent().find('.js-hook-uFilter'); 
                orderBy = $(btn).attr('data-orderBy');
                $(btn).addClass('selected');
                $(btn).show();
                $(el).parent().find('.js-hook-dFilter').hide();
            } else if($(el).hasClass('js-hook-uFilter')){
                var btn = $(el).parent().find('.js-hook-dFilter'); 
                orderBy = $(btn).attr('data-orderBy');
                $(btn).addClass('selected');
                $(btn).show();
                $(el).parent().find('.js-hook-uFilter').hide();
            }
        } else {
            $('.js-orderByButton').removeClass('selected');
            $(el).addClass('selected');
        }
        
        catalogFilter.orderBy = orderBy;
        catalogFilter.replaceHistoryState();
        services.catalogView(catalogFilter.targetUrl, catalogFilter.currentFilterList, catalogFilter.currentPageSize, catalogFilter.catalogViewCallback, 0, catalogFilter.historyPV, catalogFilter.orderBy);
    },
    catalogViewCallback: function(data) {
        $('.js-hook-catalog-ajax').html(data);
        $('.js-hook-spinner').hide();
        $('.js-hook-mobileSpinner').removeClass('show');
        //$(window).scrollTop(0);
        catalogFilter.setFacetValues();
        catalogFilter.replaceHistoryState();
        catalogFilter.countActiveFilters();
        catalogFilter.setTotalCount();
        header.lazyLoad.update();
    },
    catalogViewAttributeCallback: function(data) {
        catalogFilter.catalogViewCallback(data);
        catalogFilter.loadAttributeFacets(catalogFilter.facetCounter);
    },
    viewMoreProducts: function(beginIndex) {
        beginIndex = catalogFilter.currentPageSize * catalogFilter.currentPageNumber;
        $('.js-hook-loadMoreSpinner').show();
        services.catalogView(catalogFilter.targetUrl, catalogFilter.currentFilterList, catalogFilter.currentPageSize, catalogFilter.viewMoreProductsCallback, beginIndex, catalogFilter.historyPV, catalogFilter.orderBy);
    },
    viewMoreProductsCallback: function(data) {
        catalogFilter.replaceHistoryState(catalogFilter.currentPageNumber);
        $('.js-hook-catalog-ajax').append(data);
        $('.js-hook-loadMoreSpinner').hide();
        catalogFilter.setTotalCount();
        header.lazyLoad.update();
    },
    setTotalCount: function() {
        var pagingData = $('.js-pagingData').last();
        var totalCount = pagingData.data('totalcount');
        var showing = pagingData.data('showing');
        $('.js-hook-showing').html(showing);
        $('.js-hook-totalCount').html(totalCount);
        if(!(showing < totalCount)){
            $('.js-hook-catalog-view-more').hide();
        }else{
            $('.js-hook-catalog-view-more').show();
        }
    },
    setFacetCounter: function(facetCounter){
        catalogFilter.facetCounter = facetCounter;
    },
    setPageView: function(event) {    	
        if($(event).hasClass('inactive')){
        	var historyPV = $(event).data("mode");
        	catalogFilter.historyPV = historyPV;
            catalogFilter.replaceHistoryState();
            
            $('.js-hook-spinner').show();
            $('.js-grid-list').toggleClass('inactive');
            
            services.catalogView(catalogFilter.targetUrl, catalogFilter.currentFilterList, catalogFilter.currentPageSize, catalogFilter.setPageViewCallback, 0, catalogFilter.historyPV, catalogFilter.orderBy);
        }
    	
    },
    setPageViewCallback: function(data) {
        var list = $('.js-hook-catalog-ajax');
        if($(list).hasClass('flex__container_grid')){
            $(list).addClass('flex-table__main_catalog');
            $(list).removeClass('flex__container_grid');
        } else {
            $(list).addClass('flex__container_grid');
            $(list).removeClass('flex-table__main_catalog');
        }
        catalogFilter.catalogViewCallback(data);
    },
    replaceHistoryState: function(pageNumber) {
        if (pageNumber) {
            catalogFilter.currentPageNumber = pageNumber + 1;
        } else {
            catalogFilter.currentPageNumber = 1;
        }
        
        var url = location.search.substring(1);
        if (location.search.substring(1).length != 0) {
            var params = JSON.parse('{"' + decodeURI(url).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');
        } else {
            var params = {};
        }
        params.historyPN = catalogFilter.currentPageNumber;
        params.historyPS = catalogFilter.currentPageSize;
        params.historyPV = catalogFilter.historyPV;
        params.orderBy = catalogFilter.orderBy;
        
        window.history.replaceState(
            JSON.parse(JSON.stringify(catalogFilter)), //uses JSON parse and stringify to strip out functions
            '',
            location.origin + location.pathname + '?' + $.param(params)
        );
        // console.log("catalogFilter: " +JSON.stringify(catalogFilter));
        // console.log("param(params): " +$.param(params));
        catalogFilter.setFacetUrlParam(catalogFilter.currentFilterList);
    },
    loadHistoryState: function() {
        var state = window.history.state;
        if (state) {
            for (key in state) {
                catalogFilter[key] = state[key];
            }
            if(catalogFilter.facetCounter != null){
                catalogFilter.loadAttributeFacets(catalogFilter.facetCounter);
            }
            if(window.history.state.globalfacetsJS){
                catalogFilter.setFacetValues();
            }
            catalogFilter.updateProductGroupHelp();
        }else{
            if($('#searchTotalCount').length > 0){
                header.setSearchBarFocus(); 
            }
        }
    },
           
    getUrlParams: function() {
        var query = location.search.substr(1).split('&');
        var o = {};
        for (var i = 0; i < query.length; ++i) {
            var pair = query[i].split('=');
            var prop = pair[0];
            var val = pair[1];
            if (o[prop] instanceof Array) {
                o[prop].push(val);
            } else if (o[prop]) {
                o[prop] = [o[prop], val];
            } else {
                o[prop] = val;
            }
        }
        return o;
    },
    setUrlParam: function(prop, val) {
        var o = this.getUrlParams();
        o[prop] = val;
        var query = [];
        for (var p in o) {
            if (o[p] instanceof Array) {
                for (var i = 0; i < o[p].length; ++i) {
                    query.push(p + "=" + o[p][i]);
                }
            } else {
                query.push(p + "=" + o[p]);
            }
        }
        
        window.history.replaceState(
            JSON.parse(JSON.stringify(catalogFilter)), //uses JSON parse and stringify to strip out functions
            '',
            location.origin + location.pathname + '?' + query.join('&')
        );
    },
    setFacetUrlParam: function(filterList) {
        var facetIds = $.map(filterList, function(a) { return a.filterId });
        catalogFilter.setUrlParam('historyFacetId', facetIds);
        facetIds = $.map(filterList, function(a) { return a.filterName });
        catalogFilter.setUrlParam('historyfacet', facetIds);
    },
    /*loadFacetValues: function(){
        try{
            catalogFilter.globalfacetsJS = JSON.parse($('.js-hook-countObject').html());
            catalogFilter.setFacetValues();
        }catch(e){
            console.log(e); //error in the above string(in this case,yes)!
        }
    },*/
    setFacetValues: function(){
        $('.js-hook-facetCount').each(function(){
            var id = $(this).attr('id');
            var count = catalogFilter.globalfacetsJS[id];
            if(count !== undefined){
                $(this).closest('label').removeClass('filter-list__checkbox_disabled');
                $(this).html(count);
            }else {
                $(this).closest('label').addClass('filter-list__checkbox_disabled');
                $(this).html(0);
            }
        });
    },
    loadAttributeFacets: function(facetCounter){
        var ajaxUrl = catalogFilter.attributeFilterURL + '&facetCounter=' + facetCounter
        services.catalogView(ajaxUrl, catalogFilter.currentFilterList, 0, catalogFilter.attributeFacetsCallback, 0, catalogFilter.historyPV, catalogFilter.orderBy);
    },
    attributeFacetsCallback: function(data) {
        $('.js-hook-attribute-filters').html(data);
        catalogFilter.replaceHistoryState();
        catalogFilter.updateProductGroupHelp();
    },
    updateProductGroupHelp: function(){
        if($('#parentCatgroup_id_l1 input:checked').length > 0){
            $('.js-product-group-help').hide();
        }else{
            $('.js-product-group-help').show();
        }
    },
    resetFilter: function(){
        $('.js-hook-spinner').show();
        $('.js-hook-mobileSpinner').addClass('show');
        catalogFilter.currentFilterList = catalogFilter.hiddenFilterList;
        catalogFilter.globalfacetsJS = {};
        catalogFilter.facetCounter = null;
        services.catalogView(catalogFilter.targetUrl, catalogFilter.currentFilterList, catalogFilter.currentPageSize, catalogFilter.resetFilterCallback, 0, catalogFilter.historyPV, catalogFilter.orderBy);
    },
    resetFilterCallback: function(data) {
        $('.js-hook-attribute-filters').html('');
        $('.js-hook-facetButton').prop("checked", false);
        catalogFilter.catalogViewCallback(data);
        catalogFilter.updateProductGroupHelp();
    },

    /* Mobile specific functionality */

    toggleMobileSortMenu: function(show) {
        var sortContainer = $('.js-hook-mobileSortMenu');

        if (show === true) {
            sortContainer.addClass('show');
        } else if (show === false) {
            sortContainer.removeClass('show');
        } else {
            sortContainer.hasClass('show') ? sortContainer.removeClass('show') : sortContainer.addClass('show');
        }
    },

    setOrderMobile: function(orderBy) {
        catalogFilter.orderBy = orderBy;
        catalogFilter.replaceHistoryState();

        services.catalogView(catalogFilter.targetUrl, catalogFilter.currentFilterList, catalogFilter.currentPageSize, customCallback, 0, catalogFilter.historyPV, catalogFilter.orderBy);

        $('.js-hook-mobileSpinner').addClass('show');

        function customCallback(data) {
            catalogFilter.catalogViewCallback(data);
            $('.js-hook-mobileSpinner').removeClass('show');
        }
    },

    toggleMobileFilterMenu: function(show) {
        show = typeof show === 'boolean' ? show : null;

        var filterContainer = $('.js-hook-filterContainerWrapper');

        if (show === null) {
            filterContainer.hasClass('show') ? filterContainer.removeClass('show') : filterContainer.addClass('show');
        } else {
            show ? filterContainer.addClass('show') : filterContainer.removeClass('show');
        }

        if (!filterContainer.hasClass('show')) {
            $('.filter-container').removeClass('show');
            $('.js-hook-mobileFilterActions').removeClass('show');
            $('.js-hook-mobileFilterBackdrop').removeClass('show');
            $('body, html').removeClass('no-scroll');
        } else {
            catalogFilter.countActiveFilters();
            $('.js-hook-mobileFilterBackdrop').addClass('show');
            $('.js-hook-mobileFilterActions').addClass('show');
            $('body, html').addClass('no-scroll');
        }
    },

    toggleMobileFilterSubMenu: function(button) {
        var wrapper = button.closest('.filter-container');

        if (wrapper.hasClass('show')) {
            $('.filter-container').removeClass('show');
        } else {
            $('.filter-container').removeClass('show');
            wrapper.addClass('show');
        }
    },

    countActiveFilters: function() {
        var containers = $('.js-hook-filterContainer'),
            totalCount = 0;

        containers.each(function(i) {
            var container = $(containers[i]),
                count = container.find('.js-hook-facetButton:checked').length,
                filterCountMobile = container.find('.js-hook-filterCountMobile');
            
            filterCountMobile.html(count);

            totalCount += count;

            count > 0 ? container.addClass('show-count') : container.removeClass('show-count');
        });

        $('.js-hook-filterCountMobileTotal').html(totalCount > 0 ? '(' + totalCount + ')' : '');
    },

    toggleMobileProductGroups: function(show) {
        show = show || null;

        var productGroupsList = $('.js-hook-mobileProductGroups'),
            isVisible = productGroupsList.hasClass('show');

        if (show === null) {
            isVisible ? productGroupsList.removeClass('show') : productGroupsList.addClass('show');
        } else {
            show ? productGroupsList.removeClass('show') : productGroupsList.addClass('show');
        }
    }
};
