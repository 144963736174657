//-----------------------------------------------------------------
// Licensed Materials - Property of IBM
//
// WebSphere Commerce
//
// (C) Copyright IBM Corp. 2016 All Rights Reserved.
//
// US Government Users Restricted Rights - Use, duplication or
// disclosure restricted by GSA ADP Schedule Contract with
// IBM Corp.
//-----------------------------------------------------------------

/**
 * Will add property mouseLeftButton to the global scope and set event
 * listeners to document listening for mouse up and mouse down. The
 * property mouseLeftButton can be used to determine if left mouse button
 * is pressed or not, since mousemove event doesn't.
 *
 * (Should be moved to a universal location)
 */
(function(w, d) {
    w.mouseLeftButton = false;
    d.on('mousedown.detectLeftMouseButton', function(event) { w.mouseLeftButton = event.which === 1; });
    d.on('mouseup.detectLeftMouseButton',   function(event) { w.mouseLeftButton = false; });
})(window, $(document));


var product = {

    /**
     * Will initiate desktop image or mobile image functionality based on if the elements
     * are visible. This is to prevent the script from adding bindings and initiating
     * functions that aren't used.
     */
    initImage: function() {
        var self = this;

        _initImage();
        $(window).resize(_initImage);

        function _initImage() {
            if ($('#productImageDesktop').is(':visible')) self.imageDesktop();
            if ($('#productImageMobile').is(':visible'))  self.imageMobile();
        }
    },

    /**
     * Used for handling the product image functionality in desktop view.
     */
    imageDesktop: function() {
        var self = this;

        var initialImage,
            element = {},
            gallery = {},
            containerSize = 500,
            bigGallery = self.desktopBigGallery(),
            ezConfig = {
                zoomWindowPosition: 'productImageZoom',
                zoomWindowHeight: containerSize,
                zoomWindowWidth: containerSize,
                borderSize: 0,
                zoomWindowFadeIn: 200,
                zoomWindowFadeOut: 200,
                lensFadeIn: 200,
                lensFadeOut: 200,
                easing: true,
                scrollZoom: true
            };

        element.productImage = $('#productImageThumb');
        element.productImageWrapper = $('#productImageWrapper');
        element.gallery = $('#productImageGallery');
        element.galleryFirstItem = $('#productImageGalleryItems a:first-child');
        element.galleryItems = $('#productImageGalleryItems');
        element.galleryData = $('#productImageGalleryData');
        element.galleryThumbs = $('#productImageGallery a');
        element.galleryNext = $('#productImageGalleryNext');
        element.galleryPrev = $('#productImageGalleryPrev');

        element.bigGallery = $('.js-hook-bigGallery');
        element.bigGalleryClose = $('.js-hook-bigGalleryClose');
        element.bigGalleryList = $('.js-hook-bigGalleryList li a');

        gallery.size = element.galleryData.data('items-per-page');
        gallery.itemCount = element.galleryData.data('item-count');
        gallery.offset = 0;
        gallery.offestPx = 0;

        initialImage = {
            image: element.productImage.attr('src'),
            zoomImage: element.productImage.data('zoom-image')
        };

        element.productImageWrapper.on('click', function(event) {
            bigGallery.show(element.productImage.data('zoom-image'));
        });

        element.bigGalleryClose.on('click', bigGallery.hide);
        element.bigGalleryList.on('click', function(event) {
            bigGallery.set($(event.target).data('zoomImage'));
        });

        element.galleryThumbs.on('click', function(event) {
            element.galleryThumbs.removeClass('active');
            $(this).addClass('active');
            setProductImage($(this).data());
            return false;
        });

        if (gallery.itemCount > gallery.size) {
            element.galleryNext.on('click', paginateGallery(1));
            element.galleryPrev.on('click', paginateGallery(-1));
            element.galleryNext.removeClass('disabled');
        }

        $(window).resize(slideGalleryItems);
        
        setProductImage(initialImage);

        /**
         * Binds Elevate Zoom plugin on product image and cleans up previous bindings.
         */
        function setProductImage(data) {
            removeElevateZoom();

            // Update product image
            element.productImage.attr('src', data.image);
            element.productImage.data('zoom-image', data.zoomImage);

            self.loadImage(data.zoomImage, function(err, image) {
                if (err) return false;

                element.productImageWrapper.removeClass('has-zoom');

                if (image.width >= containerSize && image.height >= containerSize) {
                    element.productImageWrapper.addClass('has-zoom');

                    if (!util.userAgent.mobile) {
                        element.productImage.elevateZoom(ezConfig);
                    }
                }
            });
        }

        /**
         * Removes elevate zoom bindings and elements
         */
        function removeElevateZoom() {
            // Remove EZ
            $('.zoomWindowContainer').remove();
            $('.zoomContainer').remove();
            element.productImage.removeData('elevateZoom');
            element.productImage.removeData('zoomImage');

            // Remove previous bindings
            element.productImage.unbind();
        }

        /**
         * Pagination functions for the gallery.
         */
        function paginateGallery(value) {
            return function(event) {
                element.galleryNext.removeClass('disabled');
                element.galleryPrev.removeClass('disabled');

                if (value > 0) {
                    gallery.offset = Math.min(gallery.offset + 1, gallery.itemCount - gallery.size);
                    if (gallery.offset >= gallery.itemCount - gallery.size) {
                        element.galleryNext.addClass('disabled');
                    }
                } else {
                    gallery.offset = Math.max(gallery.offset - 1, 0);
                    if (gallery.offset <= 0) {
                        element.galleryPrev.addClass('disabled');
                    }
                }

                slideGalleryItems();
            }
        }

        /**
         * Calculates the gallery item offset and sets it.
         */
        function slideGalleryItems() {
            if (element.gallery.is(':visible')) {
                gallery.offestPx = (element.galleryFirstItem[0].getBoundingClientRect().width * gallery.offset) * -1;
                element.galleryFirstItem.css('margin-left', gallery.offestPx);
            }
        }

        self.imageDesktop = function() {};
    },

    /**
     * Big desktop gallery functions.
     */
    desktopBigGallery: function() {
        var self = this;

        var element = {},
            currentImage = null;

        element.productImageBigGallery = $('.js-hook-bigGallery');
        element.productImageBigGalleryImage = $('.js-hook-bigGalleryImage');
        element.productImageBigGalleryImageLink = $('.js-hook-bigGalleryImageLink');

        function show(image) {
            element.productImageBigGallery.addClass('show');
            set(image);
        }

        function hide() {
            element.productImageBigGallery.removeClass('show');
        }

        function set(image) {
            currentImage = image;
            element.productImageBigGalleryImage.css({ backgroundImage: 'url(' + currentImage + ')' });
            element.productImageBigGalleryImageLink.attr("href", currentImage);
        }

        return {
            show: show,
            hide: hide,
            set: set
        }
    },

    /**
     * Used for handling the product image functionality in mobile view.
     */
    imageMobile: function() {
        var self = this;

        var itemCount, minOffset, direction, swipeEvent, isDragging,
            element = {}
            sliderPos = {},
            itemWidth = 250;

        sliderPos.dragStart = 0;
        sliderPos.dragOffset = 0;
        sliderPos.dragOffsetPrev = 0;
        sliderPos.dragComplete = 0;
        sliderPos.offsetPercentage = 0;

        element.imageSlider = $('#productImageSlider');
        element.imageSliderList = $('#productImageSlider ul');
        element.imageSliderItems = $('#productImageSlider ul li');
        element.imageSliderFirstItem = $('#productImageSlider ul li:first-child');

        element.imageSlider.addClass('slider-inactive');

        element.imageSlider.unbind();
        element.imageSliderItems.unbind();

        itemCount = element.imageSliderItems.length;
        minOffset = -listWidth() + itemWidth;

        element.imageSliderList.css('width', listWidth());

        // Event methods
        swipeEvent = {
            start: function(event) {
                sliderPos.dragStart = pageX(event);

                sliderPos.dragOffset = 0;

                element.imageSlider.removeClass('slider-inactive');
            },
            end: function(event) {
                sliderPos.dragComplete += sliderPos.dragOffset;

                if (sliderPos.dragComplete > 0 || sliderPos.dragComplete < minOffset) {
                    sliderPos.dragComplete = Math.max(minOffset, Math.min(0, sliderPos.dragComplete));
                } else {
                    // Rounds the completed drag to next or previous item if given threshold is hit (offset in pixels).
                    sliderPos.dragComplete = roundOffset(50);
                }

                sliderPos.offsetPercentage = sliderPos.dragComplete / listWidth();

                element.imageSlider.addClass('slider-inactive');
                
                /* Added by Johan, 2017-01, to set active image for the print-css (might not be needed) */
                /*var pis = document.getElementById("productImageSlider");
                var dataItemCount = pis.getAttribute("data-item-count");
                var activeImage = Math.abs(dataItemCount * sliderPos.offsetPercentage);
                var lis = pis.getElementsByTagName('li');
                for (var i = 0; i < lis.length; i += 1) {
                	lis[i].removeAttribute("active-image");
                }
                for (var i = 0; i < lis.length; i += 1) {
                	if(lis[i].getAttribute("data-item-index") == activeImage) {
                		lis[i].setAttribute("active-image", activeImage);
                		break;
                	}
                }*/

                movePan();
            },
            move: function(event) {
                isDragging = event.type === 'mousemove' && window.mouseLeftButton;

                if (event.type === 'touchmove' || isDragging) {
                    sliderPos.dragOffset = (sliderPos.dragStart - pageX(event)) * -1;

                    if (sliderPos.dragOffset != sliderPos.dragOffsetPrev) {
                        sliderPos.offsetPercentage = (sliderPos.dragComplete + sliderPos.dragOffset) / listWidth();
                        direction = sliderPos.dragOffset > sliderPos.dragOffsetPrev ? 'prev' : 'next';
                        calcMoveOffset();
                        movePan();
                    }

                    sliderPos.dragOffsetPrev = sliderPos.dragOffset;
                }
            }
        };

        // Will slide to the clicked image
        element.imageSliderItems.on('click', function(event) {
            if (isDragging) return;

            var item = $(this);

            var previousPos = sliderPos.dragComplete;

            sliderPos.dragComplete = -Math.abs(itemWidth * item.data('item-index'));
            sliderPos.offsetPercentage = sliderPos.dragComplete / listWidth();

            if (previousPos === sliderPos.dragComplete) {
                item.find('.product__image-slider_pan_item_spinner').addClass('show-spinner');

                self.showPanImage(item.data('zoom-image'), function() {
                    item.find('.product__image-slider_pan_item_spinner').removeClass('show-spinner');
                });
            } else {
                movePan();
            }

            event.preventDefault();
        });

        // Prevent dragging images
        element.imageSliderItems.on('dragstart', function(event) {
            event.preventDefault();
        });

        // Image slider swipe related bindings
        element.imageSlider.bind('touchstart', swipeEvent.start);
        element.imageSlider.bind('touchmove', swipeEvent.move);
        element.imageSlider.bind('touchend', swipeEvent.end);
        element.imageSlider.bind('mousedown', swipeEvent.start);
        element.imageSlider.bind('mousemove', swipeEvent.move);
        element.imageSlider.bind('mouseup', swipeEvent.end);

        /**
         * Returns the pageX value of an event.
         */
        function pageX(event) {
            return typeof event.pageX === 'number' ? event.pageX : event.originalEvent.touches[0].pageX;
        }

        /**
         * Moves the pan.
         */
        function movePan() {
            element.imageSliderList.css('transform', 'translateX(' + sliderPos.offsetPercentage * 100 + '%)');
        }

        /**
         * Calculates list width.
         */
        function listWidth() {
            return itemCount * itemWidth;
        }

        /**
         * Calculates a reduced offset change when it's out of bounds to create
         * a more sluggish touch drag response.
         */
        function calcMoveOffset() {
            var lowOffset = sliderPos.offsetPercentage - (100 / itemCount) / 100;

            if (sliderPos.offsetPercentage > 0){
                sliderPos.offsetPercentage = sliderPos.offsetPercentage * 0.3;
            } else if (lowOffset < -1) {
                var offset = lowOffset + 1;

                sliderPos.offsetPercentage = (sliderPos.offsetPercentage - offset) + (offset / 3);
            }
        }

        /**
         * Rounds the offset to next, previous or current item position.
         */
        function roundOffset(threshold) {
            var centerItem = Math.round(Math.abs(sliderPos.dragComplete) / itemWidth),
                itemOffset = (itemWidth * centerItem),
                diff = itemOffset - Math.abs(sliderPos.dragComplete);

            if (direction === 'next') {
                if (diff < -Math.abs(threshold)) {
                    return -Math.abs(itemOffset + itemWidth);
                }
            } else {
                if (diff > threshold) {
                    return -Math.abs(itemOffset - itemWidth);
                }
            }

            return -Math.abs(itemOffset);
        }

        self.imageMobile = function() {};
    },

    /**
     * Will initiate the mobile zoomed product image viewer.
     */
    showPanImage: function(imageSrc, callback) {
        var self = this;

        var dragEvent,
            element = {},
            viewerPos = {},
            image = {
                src: imageSrc
            };

        viewerPos.dragCurrent = {};
        viewerPos.dragCursorStart = {};
        viewerPos.dragImageStart = {};

        element.imageSliderViewer = $('#productImageSliderViewer');
        element.imageSliderViewerZoom = $('#productImageSliderViewer .product__image-slider_viewer_zoom');
        element.imageSliderViewerImage = $('#productImageSliderViewerImage');

        // Loads large product image
        self.loadImage(image.src, function(err, data) {
            if (err) return false;

            element.imageSliderViewerZoom.css({ height: data.height,  width: data.width });

            image.height = data.height;
            image.width = data.width;
            image.minTop = -Math.abs(image.height) + element.imageSliderViewer.outerHeight();
            image.minLeft = -Math.abs(image.width) + element.imageSliderViewer.outerWidth();

            moveZoomView(-Math.abs(image.width / 2) + element.imageSliderViewer.outerWidth() / 2, -Math.abs(image.height / 2) + element.imageSliderViewer.outerHeight() / 2);

            toggleViewer(true);

            callback();
        });

        // Slider image viewer close
        element.imageSliderViewer.find('.product__image-slider_viewer_close').on('click', function() {
            toggleViewer(false);
        });

        // Event methods
        dragEvent = {
            start: function(event) {
                viewerPos.dragCursorStart = clientPos(event);
                viewerPos.dragImageStart.x = image.left;
                viewerPos.dragImageStart.y = image.top;
            },
            move: function(event) {
                if (event.type === 'touchmove' || (event.type === 'mousemove' && window.mouseLeftButton)) {
                    viewerPos.dragCurrent.x = viewerPos.dragImageStart.x + (clientPos(event).x - viewerPos.dragCursorStart.x);
                    viewerPos.dragCurrent.y = viewerPos.dragImageStart.y + (clientPos(event).y - viewerPos.dragCursorStart.y);

                    moveZoomView(viewerPos.dragCurrent.x, viewerPos.dragCurrent.y);

                    event.preventDefault();
                }
            }
        };

        // Clear element of previous bindings
        element.imageSliderViewer.unbind();

        // Image viewer drag related bindings
        element.imageSliderViewer.bind('touchstart', dragEvent.start);
        element.imageSliderViewer.bind('touchmove', dragEvent.move);
        element.imageSliderViewer.bind('mousedown', dragEvent.start);
        element.imageSliderViewer.bind('mousemove', dragEvent.move);

        // Prevent dragging image
        element.imageSliderViewerImage.on('dragstart', function(event) {
            event.preventDefault();
        });

        /**
         * Returns the client x and y value of an event (like pageX and pageY, but relative to viewport).
         */
        function clientPos(event) {
            return {
                y: typeof event.clientY === 'number' ? event.clientY : event.originalEvent.touches[0].clientY,
                x: typeof event.clientX === 'number' ? event.clientX : event.originalEvent.touches[0].clientX
            }
        }

        /**
         * Toggles the product image viewer.
         */
        function toggleViewer(show) {
            if (show) {
                element.imageSliderViewerImage.attr('src', image.src);
                element.imageSliderViewer.addClass('show-viewer');
            } else {
                element.imageSliderViewer.removeClass('show-viewer');
            }
        }

        /**
         * Moves image in product image viewer based on given values.
         */
        function moveZoomView(left, top) {
            var maxOffset = 30;

            // Centers position x/y if smaller than viewport,
            // else sets given position which is validated by a min and max value.
            image.left = image.width < element.imageSliderViewer.outerWidth()
                       ? element.imageSliderViewer.outerWidth() / 2 - image.width / 2
                       : Math.max(image.minLeft - maxOffset, Math.min(maxOffset, left));

            image.top  = image.height < element.imageSliderViewer.outerHeight()
                       ? element.imageSliderViewer.outerHeight() / 2 - image.height / 2
                       : Math.max(image.minTop - maxOffset, Math.min(maxOffset, top));

            element.imageSliderViewerZoom.css({ left: image.left, top: image.top });
        }
    },

    /**
     * Loads an image and returns its props when done.
     */
    loadImage: function(src, callback) {
        var img = new Image();

        img.onload = function() {
            callback(false, this);
        };

        img.onerror = function() {
            callback(true);
        };

        img.src = src;
    },

    toggleProductInfo: function(element) {
        var wrapper = $(element).closest('.js-hook-productInfo');

        if (wrapper.hasClass('show')) {
            $('.js-hook-productInfo').removeClass('show');
        } else {
            $('.js-hook-productInfo').removeClass('show');
            wrapper.addClass('show');
            $(document).scrollTop(wrapper.offset().top - 77);
            // $('html, body').animate({ scrollTop: wrapper.offset().top - 77 }, 300);
        }
    },

    toggleSpareParts: function(el) {
        if (el) {
            var list = $(el).closest('.js-table-explodedview');
            var uniqueId = $(el).data('sparepart');
            var table = $('.js-table-sparepart[data-sparepart="' + uniqueId + '"]');
            
            table.toggleClass('hide');
            list.toggleClass('active');
            
            $('.js-table-sparepart').not(table).addClass('hide');
            $('.js-table-explodedview').not(list).removeClass('active');
        } else {
            $('.js-table-sparepart').addClass('hide');
            $('.js-table-explodedview').removeClass('active');
        }
    },

    /**
     * Toggles 
     */
    toggleTabImage: function(el) {
        if (el) {
            var wrapper = $(el).closest('.js-product-tabImages'),
                modal = wrapper.find('.js-product-viewTabImage'),
                spinner = wrapper.find('.js-product-viewTabImage-spinner'),
                image = wrapper.find('.js-product-viewTabImage-image');

            spinner.show();
            image.html('<img src="' + $(el).data().fullImage + '" style="display:none;" onload="product.tabImageOnLoad(this);"/>');
            modal.show();
        } else {
            $('.js-product-viewTabImage').hide();
        }
    },

    tabImageOnLoad: function(el) {
        $(el).closest('.js-product-tabImages').find('.js-product-viewTabImage-spinner').fadeOut();
        $(el).fadeIn();
    },

    // Is bound in common header.init
    loadTabContent: function() {
        var button = $(this).closest('.js-hook-tab');
        var content = button.next('.tab__content');
        var label = button.find('.js-hook-tab-label').text();
        var loadingText = button.closest('.js-hook-tab-list').data('loading-text').replace('%%%', label);
        var command = button.data('ajax-command');
        var options = {};

        button.data('ajax-options').split(/\s*,\s*/).forEach(function(str) {
            options[str.split(/\s*:\s*/)[0]] = str.split(/\s*:\s*/)[1];
        });

        if (command && !button.is('.content-loaded, .fetching-content')) {
            button.addClass('fetching-content');
            content.html('<span class="loading-text-dots">' + _.capitalize(loadingText) + '</span>');

            services.tabContent(command, options, function(err, data) {
                button.removeClass('fetching-content');

                if (err) {
                    console.log('ERROR LOADING TAB', err);
                    button.addClass('content-error-loading');
                    return;
                }

                button.addClass('content-loaded');
                content.html(data);
            });

        }
    },

    autoLoadFirstTab: function() {
        var tab = $('.js-hook-tabsInitialState .js-hook-tab:first-child');
        this.loadTabContent.call(tab);
        console.log('Auto-loading first tab');
    },

    loadProperties: function() {
        var infoContainer = $('.js-hook-itemInfo');
        infoContainer.addClass('loading');

        services.productProperties(infoContainer.data(), function(err, data) {
            infoContainer.removeClass('loading');

            if (err) {
                console.log(err);
                infoContainer.addClass('error');
                return;
            }

            infoContainer.addClass('loaded');
            infoContainer.html(data);
        });
    },

    loadAdditionalArticleData: function(mobile) {
        var selector, rows, productIds = [];

        if (mobile) {
            selector = '.js-hook-row.is-buyable.is-expanded:not(.is-loaded):not(.is-loading)';
        } else {
            selector = '.js-hook-row.is-buyable:not(.is-loaded):not(.is-loading):visible';
        }

        rows = $(selector);

        if (!rows.length) return;

        rows.each(function() {
            productIds.push($(this).data('articleNumber').toString());
        });

        rows.addClass('is-loading');

        $('.js-hook-table').each(function() {
            $(this).find('.js-hook-tableLoadingData').toggle(!!$(this).find('.is-loading').length);
        });

        services.additionalArticleData(productIds, function(err, data) {
            rows.removeClass('is-loading').addClass('is-loaded');

            $('.js-hook-table').each(function() {
                $(this).find('.js-hook-tableLoadingData').toggle(!!$(this).find('.is-loading').length);
            });

            // If server return undefined
            if (!data) {
                productIds.forEach(function(productId) {
                    var row = $('.js-hook-row[data-article-number=' + productId + ']');

                    row.find('[data-article-info]').each(function(index) {
                        var cell = $(this);
                        var key = cell.data('articleInfo');

                        cell.html('–');
                    });
                });

                return;
            }
           
            data.productListItems.forEach(function(item, index) {
                var row = $('.js-hook-row[data-article-number=' + item.productId + ']');
                
                row.find('[data-article-info]').each(function(index) {
                    var cell = $(this);
                    var key = cell.data('articleInfo');
                    var value = typeof key !== 'undefined' ? item[key.toString()] : '–';
                    cell.html(value);
                });
            });

        });
    },
    

    initAddon: function() {
        var self = this;
        
        $('#productAddonDesktop').removeClass('hide');
        _initAddon();
        $(window).resize(_initAddon);

        function _initAddon() {
            if ($('#productAddonDesktop').is(':visible')) self.addonDesktop();
        }
    },
    /**
     * Used for handling the product image functionality in desktop view.
     */
    addonDesktop: function() {
        var self = this;

        var element = {},
            addonGallery = {};
        element.addonGallery = $('#productAddonGallery');
        element.addonGalleryItems = $('#productAddonGalleryItems');
        element.addonGalleryData = $('#productAddonGalleryData');
        element.addonGalleryNext = $('#productAddonGalleryNext');
        element.addonGalleryPrev = $('#productAddonGalleryPrev');
        element.addonGalleryFirstItem = $('#slide_1');

        addonGallery.size = element.addonGalleryData.data('items-per-page');
        addonGallery.itemCount = element.addonGalleryData.data('item-count');
        addonGallery.offset = 0;
        addonGallery.offestPx = 0;
        
        if (addonGallery.itemCount > addonGallery.size) {
            element.addonGalleryNext.on('click', paginateAddonGallery(1));
            element.addonGalleryPrev.on('click', paginateAddonGallery(-1));
            element.addonGalleryNext.removeClass('disabled');
        }
        else {
            element.addonGalleryNext.addClass('hide');
            element.addonGalleryPrev.addClass('hide');
        }

        $(window).resize(slideAddonGalleryItems);
        
        /**
         * Pagination functions for the gallery.
         */
        function paginateAddonGallery(value) {
            return function(event) {
                element.addonGalleryNext.removeClass('disabled');
                element.addonGalleryPrev.removeClass('disabled');

                if (value > 0) {
                	addonGallery.offset = Math.min(addonGallery.offset + 1, addonGallery.itemCount - addonGallery.size);
                    if (addonGallery.offset >= addonGallery.itemCount - addonGallery.size) {
                        element.addonGalleryNext.addClass('disabled');
                    }
                } else {
                	addonGallery.offset = Math.max(addonGallery.offset - 1, 0);
                    if (addonGallery.offset <= 0) {
                        element.addonGalleryPrev.addClass('disabled');
                    }
                }

                slideAddonGalleryItems();
            }
        }

        /**
         * Calculates the gallery item offset and sets it.
         */
        function slideAddonGalleryItems() {
            if (element.addonGallery.is(':visible')) {
            	addonGallery.offestPx = (element.addonGalleryFirstItem[0].getBoundingClientRect().width * addonGallery.offset) * -1;
                element.addonGalleryFirstItem.css('margin-left', addonGallery.offestPx);
            }
        }

        self.addonDesktop = function() {};
    }
};
