//-----------------------------------------------------------------
// Licensed Materials - Property of IBM
//
// WebSphere Commerce
//
// (C) Copyright IBM Corp. 2016 All Rights Reserved.
//
// US Government Users Restricted Rights - Use, duplication or
// disclosure restricted by GSA ADP Schedule Contract with
// IBM Corp.
//-----------------------------------------------------------------

var util = {

    /**
     * Returns an incremental value each time called.
     */
    increment: (function() {
        var incrementValue = 0;

        return function() {
            return incrementValue++;
        };
    })(),

    // User agent checks
    userAgent: {

        // Mobile
        iOS: /iPad|iPod|iPhone/i.test(navigator.userAgent) && !window.MSStream,
        windowsPhone: /Windows Phone/i.test(navigator.userAgent),
        android: /Android/i.test(navigator.userAgent),        

        // Internet Exploder
        ie11: !!window.MSInputMethodContext && !!document.documentMode,
        ie10: /MSIE 10/i.test(navigator.userAgent),
        ie9: /MSIE 9/i.test(navigator.userAgent)

    },

    _googleMapsInitCallbacks: [],

    onGoogleMapsInit: function(cb) {
        util._googleMapsInitCallbacks.push(cb);
    },

    googleMapsInit: function() {
        $(function() {
            for (var i = 0; i < util._googleMapsInitCallbacks.length; i++) {
                util._googleMapsInitCallbacks[i]();
            }
        });
    }
};

util.userAgent.mobile = util.userAgent.iOS || util.userAgent.windowsPhone || util.userAgent.android;

// Adds user agent specific class to <html>
$('html').toggleClass('ua-iOS', util.userAgent.iOS);
$('html').toggleClass('ua-windowsPhone', util.userAgent.windowsPhone);
$('html').toggleClass('ua-android', util.userAgent.android);
$('html').toggleClass('ua-ie11', util.userAgent.ie11);
$('html').toggleClass('ua-ie10', util.userAgent.ie10);
$('html').toggleClass('ua-ie9', util.userAgent.ie9);

// Sets templating interpolation settings for Lodash. Default settings doesn't work with JSTL.
_.templateSettings.interpolate = /{{([\s\S]+?)}}/g;
_.templateSettings.evaluate = /{%([\s\S]+?)%}/g;
