//-----------------------------------------------------------------
// Licensed Materials - Property of IBM
//
// WebSphere Commerce
//
// (C) Copyright IBM Corp. 2016 All Rights Reserved.
//
// US Government Users Restricted Rights - Use, duplication or
// disclosure restricted by GSA ADP Schedule Contract with
// IBM Corp.
//-----------------------------------------------------------------

//$('.departmentButton').each(function() {
//  $(this).on(departmentButton, "keydown", function(e) {
//      if (e.keyCode == 9 || (e.keyCode == 9 && e.shiftKey)) {
//          $('#' + $(this).getData('toggle')).toggle();
//      }
//  });
//});

var header = {
    rootUrl: "",
    buyerSearchURL: "",
    ShopOnBehalfURL: "",
    homeURL: "",
    initMegaMenu: true,

    
    init: function(urlRoot) {
    	
    	$(function (){
    		var r = getCookie("ResponsiveSwitch");
    	    console.log(r);
    	    if (r === "true"){
    	    	$('.js-toggle-visible').show();
    	    }
    	});
    	
        services.megaMenuView($('.js-nav-MegaMenu').data('categoryid'), function(data){
            $('.js-hook-spinner').hide();
            $('.js-hook-megamenu').html(data)
            header.initMegaMenu = false;
            header.lazyLoad.update();
        });

        services.mobileMenuView(urlRoot, function(data){
            $('.js-hook-mobileSpinner').removeClass('show');
            $('.js-hook-mobilemenu').html(data);
            $('.js-hook-closeOverlay').removeClass('has-spinner');
            header.initMegaMenu = false;
        });

        services.loginView(urlRoot, function(data) {
            $('.js-hook-headerMenuDrop-login').html(data);
            $('.js-hook-closeOverlay').removeClass('has-spinner');
        });

        //bind search input listers
        $('.js-hook-headerSearch').on('input', function(){
            header.autoSuggest();
        });
        $('.header .js-hook-sob-logonIdSearch').on('input', function(){
            header.shopOnBehalfAutoSuggest();
        });
        $('.header .js-hook-sob-firstNameSearch').on('input', function(){
            header.shopOnBehalfAutoSuggest();
        });
        $('.header .js-hook-sob-lastNameSearch').on('input', function(){
            header.shopOnBehalfAutoSuggest();
        });

        $('.js-hook-searchSubmit').on('click', function() {
            if ($('.js-hook-headerSearch').val().length > 0) {
                $('#searchBox').submit();
            }
        });
        $('.js-hook-headerLogin').on('click', function() {
            header.loginView();
        });
        $('.js-hook-closeOverlay').on('click', function() {
            $('.js-hook-closeOverlay').hide();
            $('.js-hook-sliderImage').hide();
            $('.js-hook-reqListContainer').addClass('hide');
            $('.js-hook-toggleTable').addClass('hide');
            $(".js-hook-table-menu").addClass("hide");
            $('body, html').removeClass('no-scroll');
        });
        $('.js-hook-headerSearchContainer').submit(function(event) {
            var searchTerm = $('.js-hook-headerSearch').val().trim();

            if (searchTerm.length > 0) {
                $('.js-hook-headerSearch').val(searchTerm);
                return true;
            } else {
                event.preventDefault();
            }
        });

        $('html').on('click', function(event) {
            var target = $(event.target);

            if (!$('.js-hook-headerSearch').is(':focus')) {
            	$('.js-hook-autoSuggestResult').hide();
            }

            if (!$(event.target).parents('.js-nav-MegaMenu').length && !$(event.target).parents('.js-hook-megamenu').length) {
 	         	$('.js-hook-megamenu').hide();
 	        }

            if (!$(event.target).is('.js-hook-table-links') && !$(event.target).parents('.js-hook-table-links').length && !$(event.target).parents('.js-hook-table-menu').length) {
 	         	$('.js-hook-table-menu').addClass("hide");
 	        }

	        if (!$(event.target).parents('.js-autocomplete-org-hook').length && !$(event.target).is(".jsUser-autocomplete-org") && !$(event.target).is(".jsBecome-autocomplete-org")) {
	         	$(".jsUser-autocomplete-org-result").hide();
	         	$(".jsBecome-autocomplete-org-result").hide();
	        }

        });

        $('.js-hook-headerSearch').on('focus', function(event) {
            $('.js-hook-autoSuggestResult').show();
            event.stopPropagation();
        });

        $('.js-hook-headerSearch').on('keydown', function(e) {
            if (e.keyCode == 40){
                e.preventDefault();
                $('.header__searchLink_suggestion').first().focus();
            }
        });

        var ckeFormValidator = new FormValidator('.js-cke-submit', '.js-cke-input');

        $('.js-cke-submit').on('click', function() {
        	var form = {};
        	var formId = $(this).data('form');

            $('.js-cke-input[data-form="' + formId + '"]').each(function(i) {
            	form[i + '-' + this.name] = this.value;
            });
            form.messageType = $(this).data('messagetype');
        	form.subject = $(this).data('subject');

            if (ckeFormValidator.validate(formId)) {
                services.sendEmail(form);
            }
        });

        /**
         * Binds navbar drop menus.
         */
        $('.js-hook-headerMenuDropButton').on('click', function(event) {
            var overlay = $('.js-hook-closeOverlay'),
                button = $(event.currentTarget).not(overlay),
                body = $('body, html'),
                dropMenu = button.data('dropMenu'),
                menu, menu, menus;

            if (dropMenu) {
                buttons = $('.js-hook-headerMenuDropButton').not(button);
                targetMenu = button.data('dropMenu');
                menu = $('.js-hook-headerMenuDrop-' + dropMenu);
                menus = $('.js-hook-headerMenuDrop').not(menu);

                buttons.removeClass('show');
                button.toggleClass('show');
                menus.removeClass('show');
                menu.toggleClass('show');

                if ($('.js-hook-headerMenuDropButton').hasClass('show')) {
                    setTimeout(function() {

                        overlay.show();
                        body.addClass('no-scroll_tablet');

                        if (dropMenu !== 'become'){
                        	body.addClass('no-scroll_mobile');
                        }

                        header.dropMenuFn[dropMenu]();

                        $('html').off('click.headerDropMenu').on('click.headerDropMenu', function(event) {
                            if (!$(event.target).is(menu) && !$(event.target).parents('.js-hook-headerMenuDrop-' + button.data('dropMenu')).length) {
                                _close(button, menu);
                            }
                        });
                    });
                } else {
                    _close();
                }
            } else {
                buttons = $('.js-hook-headerMenuDropButton');
                menus = $('.js-hook-headerMenuDrop');

                buttons.removeClass('show');
                menus.removeClass('show');

                _close();
            }

            function _close(button, menu) {
                if (button) {
                    button.removeClass('show');
                }

                if (menu) {
                    menu.removeClass('show');
                }

                overlay.hide();
                overlay.removeClass('has-spinner');
                overlay.removeClass('hide_mobile');
                body.removeClass('no-scroll_mobile no-scroll_tablet');
                $('html').off('click.headerDropMenu');
            }
        });

        $('body').on('click', '.js-hook-tab[data-ajax-command] > a', product.loadTabContent);

        this.lazyLoad.update();

        this.rootUrl = urlRoot;
    },

    lazyLoad: new LazyLoad({
        elements_selector: '.js-hook-lazy'
    }),

    dropMenuFn: {
        nav: function() {
            if ($('.js-hook-mobilemenu').is(':empty')) {
                $('.js-hook-closeOverlay').addClass('has-spinner');
            }
        },
        search: function() {
            $('.js-hook-headerSearch').focus();
        },
        login: function() {
            if ($('.js-hook-loginViewResult').is(':empty')) {
                $('.js-hook-closeOverlay').addClass('has-spinner');
            } else {
                $('.js-hook-loginUsername').focus();
            }

        },
        account: function() {},
        language: function() {
            console.log('langlang');
        },
        become: function() {
        	$('.js-hook-closeOverlay').addClass('hide_mobile');
        },
    },

    navigateAutoSuggest: function(e, i){
        if (e.keyCode == 40){
            e.preventDefault();
            i += 1;
            $('.header__searchLink_suggestion[data-index="' + i + '"]').focus();
        } else if (e.keyCode == 38){
            e.preventDefault();
            i -= 1;
            $('.header__searchLink_suggestion[data-index="' + i + '"]').focus();
        }
    },

    toggleNavbarShadow: function() {
        if ($(document).scrollTop() > 0) {
            $('#header').addClass('header__has-shadow');
        } else {
            $('#header').removeClass('header__has-shadow');
        }
    },

    stickyNavbar: (function() {
        var threshold = 50; // Amount of pixels that needs to be scrolled before nav shows/hides.

        var prevVal = 0;
        var showAt = 50;
        var hideAt = 0;

        this.hasMegaMenu = false;

        this.onScroll = function() {
            var currentVal = $(document).scrollTop();
            var headerContainerHeight = $('.header__container').height();
            var headerDesktopNavHeight = $('.header__desktopNav').height();

            if (currentVal > prevVal) {
                showAt = Math.max(currentVal - threshold, 0);
                hideAt = currentVal > hideAt ? currentVal : hideAt;
            } else {
                hideAt = currentVal + threshold;
                showAt = currentVal <= showAt ? currentVal : showAt;
            }

            if (currentVal >= headerContainerHeight) {
                $('.header__desktopNav').addClass('header__desktopNav_fixed');
                $('.header__desktopNav_spacer').css('height', headerDesktopNavHeight);

                if (currentVal === showAt || currentVal < headerContainerHeight + headerDesktopNavHeight) {
                    $('.header__desktopNav').css('top', 0);
                } else if (currentVal === hideAt && !this.hasMegaMenu)  {
                    $('.header__desktopNav').css('top', -Math.abs(headerDesktopNavHeight + 5));
                }
            } else {
                $('.header__desktopNav').removeClass('header__desktopNav_fixed');
                $('.header__desktopNav_spacer').css('height', 0);
            }

            prevVal = currentVal;
        }.bind(this);

        return this;
    }),
    setHomeURL:function(homeURL) {
    	this.homeURL = homeURL;
    },
    setBuyerSearchURL: function(buyerSearchURL){
        this.buyerSearchURL = buyerSearchURL;
    },
    autoSuggest: function(){
    	var term= $('.js-hook-headerSearch').val().trim();        
    	if (term.length > 2) {
        	var searchBass = WCParamJS.bass;
        	if($('.js-hook-headerSearchBass').length){
        		searchBass = $('.js-hook-headerSearchBass').val().trim();	
            }
        	services.headerSearch(term, searchBass, header.autoSuggestCallback);
        } else {
            header.autoSuggestClear();
        }
    },
    autoSuggestCallback: function(returnedAjaxData) {
        $('.js-hook-autoSuggestResult').html(returnedAjaxData);
    },
    autoSuggestClear: function(){
        $('.js-hook-autoSuggestResult').html('');
    },

    shopOnBehalfAutoSuggest: function(){
        var logonIdTerm= $('.header .js-hook-sob-logonIdSearch').val().trim();
        var firstNameTerm= $('.header .js-hook-sob-firstNameSearch').val().trim();
        var lastNameTerm= $('.header .js-hook-sob-lastNameSearch').val().trim();
        if (logonIdTerm.length > 2 || firstNameTerm.length > 2 || lastNameTerm.length > 2) {
            // console.log("Begin shopOnBehalfAutoSuggest logonIdTerm=\""+logonIdTerm+"\" firstNameTerm=\""+firstNameTerm+"\" lastNameTerm=\""+lastNameTerm+"\"");
            services.shopOnBehalfSearch(logonIdTerm, firstNameTerm, lastNameTerm, header.shopOnBehalfAutoSuggestCallback);
        } else {
            header.shopOnBehalfAutoSuggestClear();
        }
    },
    shopOnBehalfAutoSuggestCallback: function(returnedJsonData){
        // console.log("Begin shopOnBehalfAutoSuggestCallback - returnedJsonData: "+returnedJsonData);
        var resultAsHtml = '';
        if(returnedJsonData != null && returnedJsonData.userDataBeans != null && returnedJsonData.userDataBeans.length > 0){
            resultAsHtml += '<div id="suggestedUserResults"><div class="list_section"><ul class="header__sugestionList">';
            for(var i = 0; i < returnedJsonData.userDataBeans.length; i++){
                var userEntry = returnedJsonData.userDataBeans[i];
                userEntry.displayName = '';
                var userFirstName = '';
                var userLastName = '';
                if(userEntry.firstName != null){
                    userFirstName = userEntry.firstName + " ";
                }
                if(userEntry.lastName != null){
                    userLastName = userEntry.lastName;
                }
                userEntry.displayName = userFirstName + userLastName;
                //userEntry.fullName = userEntry.displayName;

                userEntry.displayName += ' (' + userEntry.logonId + ')';
                resultAsHtml += '<li tabindex="-1" role="listitem" id="suggestionItem_';
                resultAsHtml += i;
                resultAsHtml += '" class="header__sugestionListItem"><a id="autoSelectOption_';
                resultAsHtml += i;
                resultAsHtml += '" data-userId="';
                resultAsHtml += userEntry.userId;
                if(userEntry.businessProfile != null){
                    resultAsHtml += '" data-organizationId="';
                    resultAsHtml += userEntry.businessProfile.organizationId;
                }
                resultAsHtml += '" onclick="javascript:header.shopOnBehalfSetUser(\'';
                resultAsHtml += userEntry.userId;
                resultAsHtml += '\');return false;" href="#">';
                resultAsHtml += userEntry.displayName;
                resultAsHtml += '</a></li>';
            }
            resultAsHtml += '</ul></div></div>';
        }
        $('.js-hook-autoShopOnBehalfResult').html(resultAsHtml);
    },
    shopOnBehalfAutoSuggestClear: function(){
        $('.js-hook-autoShopOnBehalfResult').html('');
    },
    shopOnBehalfSetUser: function(runAsUserId){
        var authToken = $('#shopOnBehalf_AuthToken').val();
        services.runAsUserSetInSessionService(this.rootUrl, runAsUserId, authToken, header.shopOnBehalfOnUserSetInSession);
    },
    shopOnBehalfOnUserSetInSession: function(serviceResponse){
        // console.log("shopOnBehalfOnUserSetInSession serviceResponse: "+serviceResponse);
        if(WCParamJS.commandContextCommandName == 'AjaxOrderItemDisplayView' ||
                WCParamJS.commandContextCommandName == 'CategoryDisplay' ||
                WCParamJS.commandContextCommandName == 'ProductDisplay' ||
                WCParamJS.commandContextCommandName == 'SearchDisplay' ||
                WCParamJS.commandContextCommandName == 'CompareProductsDisplayView'){
            document.location.reload();
        } else {
            window.location = header.ShopOnBehalfURL;
        }
    },
    shopOnBehalfClearUserSetInSession: function(){
        services.clearUserSetInSession(this.rootUrl, header.shopOnBehalfonRestoreUserSetInSession);
    },
    shopOnBehalfonRestoreUserSetInSession: function(serviceResponse){
        // console.log("onRestoreUserSetInSession serviceResponse: "+serviceResponse);
        document.location.reload();
    },
    shopOnBehalfDropDown: function(){
        $('.js-hook-shopOnBehalf').toggleClass('hide');
        /*$('html').on('click', function() {
        	$('.js-hook-shopOnBehalf').addClass('hide');
        });
        event.stopPropagation();*/
        //$('.js-hook-closeOverlay').show();
    },
    loginView: function() {
        if ($('.js-hook-loginViewResult').html().length > 0) {
            header.loginViewClear();
            $('.js-hook-closeOverlay').hide();
        } else {
            services.loginView(this.rootUrl, header.loginViewCallback);
        }

    },
    loginViewCallback: function(returnedAjaxData) {
        $('.js-hook-closeOverlay').show();
        $('.js-hook-loginViewResult').html(returnedAjaxData);
    },
    loginViewClear: function() {
        $('.js-hook-loginViewResult').html('');
    },

    mobileMenuView: function() {
        if ($('.js-hook-mobilemenu').is(':visible')) {
            $('.js-hook-mobilemenu').hide();
            $('.js-hook-closeOverlay').hide();
            $('body, html').removeClass('no-scroll');
        } else if ($('.js-hook-mobilemenu').html().trim().length > 0) {
            $('.js-hook-mobilemenu').show();
            $('.js-hook-closeOverlay').show();
            $('.js-hook-headerSearchContainer').addClass('hide_mobile');
            $('body, html').addClass('no-scroll');
        } else {
            $('.js-hook-mobileSpinner').addClass('show');
            $('.js-hook-spinner').show();
            services.mobileMenuView(this.rootUrl, header.mobileMenuCallback);
        }
    },
    mobileMenuCallback: function(returnedAjaxData) {
        $('.js-hook-mobileSpinner').removeClass('show');
        $('.js-hook-spinner').hide();
        $('.js-hook-closeOverlay').show();
        $('.js-hook-mobilemenu').html(returnedAjaxData);
        $('.js-hook-mobilemenu').show();
        $('.js-hook-headerSearchContainer').addClass('hide_mobile');
        $('body, html').addClass('no-scroll');
        //var catId = $('#mobileMegaMenu').data('catid');
        //services.megaMenuViewSub(catId, function (data) { $('#mobileMegaMenu').html(data) });
    },
    mobileMenuClear: function() {
        $('.js-hook-mobilemenu').hide();
    },

    megaMenuView: function(catId) {
        if($('.js-hook-megamenu').html().length === 0){
            $('.js-hook-spinner').show();
            $('.js-hook-megamenu').show();
            services.megaMenuView(catId, header.megaMenuViewCallback);
            header.stickyNavbar.hasMegaMenu = true;
        } else if ($('.js-hook-megamenu').is(':visible')) {
            $('.js-hook-megamenu').hide();
            header.stickyNavbar.hasMegaMenu = false;
        } else {
            $('.js-hook-megamenu').show();
            header.stickyNavbar.hasMegaMenu = true;
            header.lazyLoad.update();
        }
        /*if ($('.js-hook-megamenu').html().length > 0) {
            header.megaMenuViewClear();
            header.stickyNavbar.hasMegaMenu = false;
        } else {
            var timeOut = setTimeout(function() {
                $('.js-hook-spinner').show();
            }, 200);
            services.megaMenuView(catId, header.megaMenuViewCallback.bind(null, timeOut));
            header.stickyNavbar.hasMegaMenu = true;
        }*/
    },
    megaMenuViewCallback: function(returnedAjaxData){
        $('.js-hook-spinner').hide();
        if(header.initMegaMenu){
            $('.js-hook-megamenu').html(returnedAjaxData);
            header.lazyLoad.update();
        }
    },
    megaMenuViewClear: function() {
        $('.js-hook-megamenu').html('');
    },
    megaMenuViewSub: function(catId) {
        services.megaMenuViewSub(catId, header.megaMenuViewSubCallback);
    },
    megaMenuViewSubCallback: function(returnedAjaxData){
        if(header.initMegaMenu){
            $('#js-mega-menu-categories').html(returnedAjaxData);
        }
    },
    megaMenuMoreToggle: function(catName){
        $('#mega-menu__categories_breadcrumb ul[data-categoryName="' + catName + '"] .js-hook-hidden').toggleClass("mega-menu__show-hidden");
    },
    megaMenuCategoryToggle: function(categoryId){
        $('.js-megaMenu-img').hide();
        $('.js-megaMenu-img[data-catgpId="' + categoryId + '"]').show();
        header.lazyLoad.update();
    },
    mobileItemToggle: function(el, categoryId){
        $('.js-mobileMegaMenu[data-catid="' + categoryId + '"]').slideToggle('fast');
        $(el).find('.fa').toggleClass('hide');
    },
    navListToggle: function(catId){
        $('.header__navContainer').toggleClass("hide_mobile");
    },
    toggleCatList: function(el) {
        var groupItem = $(el).closest('.js-hook-catGroup');
        $(groupItem).find('.js-hook-catExpanded').toggleClass('hide');
        $(groupItem).find('.js-hook-catMinified').toggleClass('hide');
        $(groupItem).find('.js-hook-catList').toggleClass('mega-menu__hidden-mobile');
    },
    toggleMyPagesList: function(el) {
        var groupItem = $(el).closest('.js-hook-myPagesCatGroup');
    },
    toggleSubMenu: function(){
        $('.js-hook-headerSubMenu').toggle();
        //$('.js-hook-closeOverlay').show();
    },
    setShopOnBehalfURL: function(url){
        header.ShopOnBehalfURL = url;
    },
    setSearchBarFocus: function(){
        $('.js-hook-headerSearch').focus();
    },
    toggleNetPrice: function(display){
    	var url = location.search.substring(1);
        if (location.search.substring(1).length != 0) {
            var params = JSON.parse('{"' + decodeURI(url).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');
        } else {
            var params = {};
        }
        params.hideNetPrice = display;
        window.location = location.origin + location.pathname + '?' + $.param(params);
    },
    toggleCustomerAssortment: function(enableCass){
    	
    	var targetLocation = location.origin + location.pathname;
    	
    	//if we have js-hook-assortment elements on the page and none of them has data-isinass=true 
    	//then redirect to homepage when enabling customer assortment
    	if(enableCass && $(".js-hook-assortment").length && !$(".js-hook-assortment[data-isinass=true]").length){
    		targetLocation = header.homeURL;
    	}
    	
    	var url = location.search.substring(1);
        if (location.search.substring(1).length != 0) {
            var params = JSON.parse('{"' + decodeURI(url).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');
        } else {
            var params = {};
        }
        params.cass = enableCass;
        window.location.assign(targetLocation + '?' + $.param(params));
    },
    menuCollapseAndSelect: function (el) {
		if($(el).parent().parent().find("ul").is(":hidden")){
			$(el).parent().parent().parent().find("ul").each( function() {
				if($(this).is(":visible")){
					$(this).slideToggle("fast");
					$(this).parent().find(".fa").toggleClass("hide");
				}
	    	});
		}
		$(el).parent().parent().find("ul").slideToggle("fast");
		$(el).find('.fa').toggleClass('hide');
	},
	activateHighRunnerLink : function(){
		// Compare the category IDs in the crumb trail with those in highrunners. On match, mark highrunner link as active.
		if($( ".js-hook-crumb-list" ).length && $( ".js-hook-crumbrunners" ).length){
	  		var crumbList = $(".js-hook-crumb-list > li").map(function(){
	  				return $(this).attr("data-crumbcategoryid");
	  		}).get();
	  		
			$(".js-hook-crumbrunners > li").each(function(){
					if(crumbList.includes($(this).attr("data-categoryId"))){
						$(this).find("span").removeClass("header__navItemText").addClass("header__navItemText_active");
					}
			});
		}
	}
};
