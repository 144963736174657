//-----------------------------------------------------------------
// Licensed Materials - Property of IBM
//
// WebSphere Commerce
//
// (C) Copyright IBM Corp. 2016 All Rights Reserved.
//
// US Government Users Restricted Rights - Use, duplication or
// disclosure restricted by GSA ADP Schedule Contract with
// IBM Corp.
//-----------------------------------------------------------------

var _formValidatorSettings = {

    // These values should be overwritten in the header with strings from customer.properties. 
    messages: {
        invalidEmail: 'Invalid email.' ,
        invalidNumber: 'Must be a number' ,
        invalidPasswordLength: 'Password is too short' ,
        invalidPasswordChar: 'Password have too few alphabetical characters' ,
        invalidPasswordInt: 'Password have too few numbers' ,
        invalidConfirmPassword: 'Passwords do not match' ,
        fieldRequired: 'This field is required.',
        confirmMismatch: 'Field does not match $0'
    },

};

/**
 * Class FormValidator
 *
 * Specify a data-form attribute on each input and the submit element in the
 * form you wish to bind.
 *
 * @constructor
 *
 * @params {string} submit  HTML class name of form submit button.
 * @params {string} input   HTML class name of form input elements.
 */
function FormValidator(submit, input) {
    var self = this;

    if (typeof submit !== 'string' || typeof input !== 'string') {
        throw new Error('FormValidator requires two arguments {string} submit and {string} input.');
    }

    self.submitClass = submit;
    self.inputClass = input;

    self.activeMessages = [];
    self.messages = _formValidatorSettings.messages;

    self.const = {
        errorClass: 'form-validation-error',
        errorMessageClass: 'form-validation-error-message'
    };

    self.validators = [
        {
            name: 'required',
            fn: function(element) {
                if (element.hasClass('validate-required')) {
                    if (!element.val() || element.val() === '' || typeof element.val() === 'undefined') {
                        self.appendMessage(element, 'fieldRequired');
                        return false;
                    }
                }

                return true;
            }
        },
        {
            name: 'email',
            fn: function(element) {
                if (element.attr('type') === 'email') {
                    var RFC2822 = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;
                    
                    if (!RFC2822.test(element.val())) {
                        self.appendMessage(element, 'invalidEmail');
                        return false;
                    }
                }

                return true;
            }
        },
        {
            name: 'number',
            fn: function(element) {
                if (element.attr('type') === 'number' || element.hasClass('validate-number')) {
                    var number = /^[0-9]*$/;

                    if (!number.test(element.val().toString())) {
                        self.appendMessage(element, 'invalidNumber');
                        return false;
                    }
                }

                return true;
            }
        },
        {
            name: 'confirm',
            fn: function(element) {
                if (element.data('confirm')) {
                    var confirmElement = $('#' + element.data('confirm'));

                    if (confirmElement && confirmElement.val() !== element.val()) {
                        self.appendMessage(element, 'confirmMismatch', ['']);
                        confirmElement.addClass(self.const.errorClass);
                        return false;
                    }
                }

                return true;
            }
        },
        {
            name: 'password',
            fn: function(element) {
                var valid = true;

                if (element.attr('type') === 'password') {
                    var password = element.val(),
                        minLength = element.data("length"),
                        minChar = element.data("char"),
                        minInt = element.data("int");
                    
                    if (!element.hasClass('validate-required') && password.length > 0 || element.hasClass('validate-required')) {
                        if (password.length < minLength) {
                        	self.messages.invalidPasswordLength = self.messages.invalidPasswordLength.replace("{0}", minLength);
                            self.appendMessage(element, 'invalidPasswordLength');
                            valid = false;
                        }

                        var match = password.match(/[0-9]/g);

                        if ((match === null && minInt > 0) || (match !== null && match.length < minInt)) {
                        	self.messages.invalidPasswordInt = self.messages.invalidPasswordInt.replace("{0}", minInt);
                            self.appendMessage(element, 'invalidPasswordInt');
                            valid = false;
                        }

                        match = password.match(/[a-zA-Z]/g);

                        if ((match === null && minChar > 0) || (match !== null && match.length < minChar)) {
                        	self.messages.invalidPasswordChar = self.messages.invalidPasswordChar.replace("{0}", minChar);
                            self.appendMessage(element, 'invalidPasswordChar');
                            valid = false;
                        }
                        
                        if(element.hasClass('validate-password')){
                        	if (password !== $('.validate-password[data-form="' + element.data("form") + '"]').val()){
                        		self.appendMessage(element, 'invalidConfirmPassword');
                                valid = false;
                        	}
                        }
                    }
                }

                return valid;
            }
        }
    ];

    $(self.submitClass).each(function() {
        var id = $(this).data('form');

        $(self.inputClass + '[data-form="' + id + '"]').on('input.formValidator', function() {
            self.clear($(this));
        });
    });
}

/**
 * Loops though the elements with the given ID and validates the content of them.
 *
 * @params {string} id
 *
 * @returns boolean
 */
FormValidator.prototype.validate = function(id) {
    var self = this,
        submit = $(self.submitClass + '[data-form="' + id + '"]'),
        elements = $(self.inputClass + '[data-form="' + id + '"]');

    elements.each(function() {
        var element = $(this);

        for (var i = 0; i < self.validators.length; i++) {
            if (!self.validators[i].fn(element)) {
                return;
            }
        }

        // Validation passed - clears all previous messages.
        self.removeMessages(element);
    });

    if (!self.isValid(id)) {
        submit.prop('disabled', true);
        return false;
    }

    return true;
};

/**
 * Removes errors and messages from given element.
 *
 * @params {jquery} element
 */
FormValidator.prototype.clear = function(element) {
    var self = this, id;

    console.log('CLEAR!');

    if (element.hasClass(self.const.errorClass)) {
        id = element.data('form');

        element.removeClass(self.const.errorClass);
        self.removeMessages(element);

        $(self.submitClass + '[data-form="' + id + '"]').prop('disabled', !self.isValid(id));
    }
};

/**
 * Loops though the elements with the given ID and checks if the contains any errors.
 *
 * @params {string} id
 *
 * @returns boolean
 */
FormValidator.prototype.isValid = function(id) {
    var self = this,
        isValid = true;

    $(self.inputClass + '[data-form="' + id + '"]').each(function() {
        if ($(this).hasClass(self.const.errorClass)) {
            isValid = false;
            return false;
        }
    });

    return isValid;
};

/**
 * Appends an error message after given element
 *
 * @params {jquery} element
 * @params {string} message
 */
FormValidator.prototype.appendMessage = function(element, message, params) {
    params = params || [];

    var self = this,
        id = element.data('form'),
        msgId = 'formErrorMessage_' + _.camelCase(id) + '_' + util.increment(),
        message = self.messages[message],
        messageElement,
        activeMessages = element.data('__activeMessages') || [];

    for (var i = 0; i < params.length; i++) {
        message = message.replace(new RegExp('\\$' + i, 'g'), params[i]);
    }

    if (params.length) {
        console.log(params, message);
        console.log(element);
    }

    messageElement = $('<div id="' + msgId + '" class="' + self.const.errorMessageClass + '"><i class="fa fa-remove" aria-hidden="true"></i>' + message + '</div>');

    activeMessages.push(msgId);

    element.addClass(self.const.errorClass);
    element.data('__activeMessages', activeMessages);

    messageElement.insertAfter(element.next().hasClass('fa') ? element.next() : element);
};

/**
 * Removes all error messages from given element.
 *
 * @params {jquery} element
 */
FormValidator.prototype.removeMessages = function(element) {
    var activeMessages = element.data('__activeMessages') || [];

    for (var i = 0; i < activeMessages.length; i++) {
        $('#' + activeMessages[i]).remove();
    }

    element.data('__activeMessages', []);
};

/**
 * Validates element with given validator.
 *
 * @params {string} validatorName
 * @params {string} element
 *
 * @returns boolean
 */
FormValidator.prototype.validateOne = function(validatorName, element) {
    var validator = this.validators.filter(function(_validator) { return _validator.name === validatorName });

    return validator ? validator.fn(element) : null;
};
