//-----------------------------------------------------------------
// Licensed Materials - Property of IBM
//
// WebSphere Commerce
//
// (C) Copyright IBM Corp. 2016 All Rights Reserved.
//
// US Government Users Restricted Rights - Use, duplication or
// disclosure restricted by GSA ADP Schedule Contract with
// IBM Corp.
//-----------------------------------------------------------------

var universal = {

    toggleTableRow: function(el, callback) {
        var delay = 250;

        $(el).closest('.js-hook-row').toggleClass('standard-table__expand-row is-expanded', 250);

        if (typeof callback !== 'function') return;

        setTimeout(callback, delay);
    },

    adjustFullWidth: function() {

        var resizeDebounce,
            debounceTime = 50; // Milliseconds to debounce function on resize.

        $(document).on('ready', function() {
            _adjustWrapperWidth();
            $(window).resize(_onResize);
        });

        /**
         * This is a debounces wrapper function for _adjustWrapperWidth that throttles the resize function
         * to prevent it from being spammed on gradually resizes.
         */
        function _onResize() {
            clearTimeout(resizeDebounce);
            resizeDebounce = setTimeout(_adjustWrapperWidth, debounceTime);
        }

        function _adjustWrapperWidth() {
            var windowWidth = $(window).width();

            $('.js-hook-universalWrapperFullWidth').css({
                width: windowWidth,
                left: 'calc(50% - ' + windowWidth / 2 + 'px)'
            });
        }
    },

    storeTable: function(tableName) {
        var table, rows;

        $(document).ready(function() {
            table = $('.' + tableName + '-table');
            rows = table.children('.' + tableName + '-row');

            table.data('originalRows', rows);
            table.data('filter', { from: null, to: null });
        });
    },

    /**
     * Table sort function
     *
     * Example of select drop for sorting
     *
     * (change <table-name> to something unique)

    <select class="input_select" data-table="<table-name>" onchange="universal.sortTable(this);">
        <option value="" disabled selected>Sort rows</option>
        <option value="date:date#asc">Datum (stigande)</option>
        <option value="date:date#desc">Datum (fallande)</option>
        <option value="price:number#asc">Pris (stigande)</option>
        <option value="price:number#desc">Pris (fallande)</option>
        <option value="status:string#asc">Status (stigande)</option>
        <option value="status:string#desc">Status (fallande)</option>
    </select>

     * The value of the option provides the settings for the sorting.
     * The syntax is: <key>:<type(date, string, number)>#<order(asc, desc)>
     * Example:       price:number#asc
     *
     * For this to work the following classes for the table are needed:
     * - the table needs        .<table-name>-table
     * - the sortable rows      .<table-name>-row
     * - the sortable cells     .<table-name>_<key>
     *   (the element containing the value)
     */
    sortTable: function(el) {
        var sortedRows = [],

            optionDrop = $(el),
            tableName = optionDrop.data('table'),

            sortBy = /^([a-zA-Z0-9§\-_]*)/.exec(optionDrop.val()),
            sortOrder = /#([a-z]*)/.exec(optionDrop.val()),
            sortType = /:([a-z]*)/.exec(optionDrop.val()),

            table = $('.' + tableName + '-table'),
            rows = table.children('.' + tableName + '-row');

        sortBy    = sortBy    ? sortBy[1]    || null : null;
        sortOrder = sortOrder ? sortOrder[1] || null : null;
        sortType  = sortType  ? sortType[1]  || null : null;

        sortedRows = rows.sort(function(row1, row2) {
            var row1Val = $(row1).find('.' + tableName + '_' + sortBy).text().trim(),
                row2Val = $(row2).find('.' + tableName + '_' + sortBy).text().trim();

            if (sortType === 'date') {
                var date1 = new Date(row1Val).getTime(),
                    date2 = new Date(row2Val).getTime();

                return sortOrder === 'asc' ? date1 - date2 : date2 - date1;

            } else if (sortType === 'number') {
                row1Val = parseInt(row1Val.replace(/[^0-9]/g, ''));
                row2Val = parseInt(row2Val.replace(/[^0-9]/g, ''));

                return sortOrder === 'asc' ? row1Val - row2Val : row2Val - row1Val;

            } else if (sortType === 'string') {
                row1Val = row1Val.toLowerCase();
                row2Val = row2Val.toLowerCase();

                if (sortOrder === 'asc') {
                    return row1Val !== row2Val ? row1Val > row2Val ? 1 : -1 : 0;
                } else {
                    return row1Val !== row2Val ? row1Val < row2Val ? 1 : -1 : 0;
                }
            }

            return 0;
        });

        rows.remove();
        table.append(sortedRows);
    },

    filterTable: function(el, settings, edge) {
        var isValidDate, filterFrom, filterTo,
            filteredRows = [],
            input = $(el),
            dateStr = input.val(),
            table = $('.' + input.data('table') + '-table'),
            filter = table.data('filter'),
            originalRows = table.data('original-rows'),

            key = settings.split(':')[0] || null,
            type = settings.split(':')[1] || null;

        if (!/^(from|to)$/.test(edge)) {
            return;
        }

        if (type === 'date') {
            isValidDate = /(19|20)[0-9]{2}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])/.test(dateStr);

            filter[edge] = isValidDate ? dateStr : null;

            filterTo   = filter.to   ? new Date(filter.to)   : null;
            filterFrom = filter.from ? new Date(filter.from) : null;

            filteredRows = originalRows.filter(function(index) {
                var rowDate = new Date($(originalRows[index]).find('.' + input.data('table') + '_' + key).text().trim());

                if (filterFrom && filterTo) {
                    return rowDate >= filterFrom && rowDate <= filterTo;
                } else if (filterFrom && !filterTo) {
                    return rowDate >= filterFrom;
                } else if (!filterFrom && filterTo) {
                    return rowDate <= filterTo;
                }

                return true;
            });

            table.data('filtered-rows', filteredRows);
        }

        // Add 'else if' to add other filter options, such as number range for example.

        table.children('.' + input.data('table') + '-row').remove();
        table.append(filteredRows);
    },
    
    loadFormData: function(form, key){
        var dataRetrieved = sessionStorage.getItem(key);
        var dataArray = JSON.parse(dataRetrieved);
        //console.log(dataArray);
    	$.each(dataArray, function(index, field) {
    		var element = $(form).find('[name= ' + field.name +']');
    	    if($(element).is('input')) {
    	    	if($.trim(field.value).length > 0) {
    	    		element.val(field.value);
    	    		//console.log(field.name + ' ' +$.trim(field.value).length + ': ' + field.value );
    	    	}
    	        //console.log("input");
    	    } else if($(element).is('check')) {
    	    	console.log("checkbox not working. add support here");
    	    } else if($(element).is('radio')) {
    	    	console.log("radio not working. add support here");
    	    } else if($(element).is('select')) {
    	    	if($.trim(field.value).length > 0) {
    	    		element.val(field.value);
    	    		//console.log(field.name + ' ' +$.trim(field.value).length + ': ' + field.value );
    	    	}
    	    } else {
    	    	//console.log('Unkown');
    	    }
    	});
    },

    saveFormData: function(form, key){
    	// find(":visible")
    	var dataArray = $(form).serializeArray();
    	console.log(dataArray);
        sessionStorage.setItem(key, JSON.stringify(dataArray));
    }
};
