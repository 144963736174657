/**
 * 
 */

var PunchOut = {
	    createLogonLink: function(baseURL) {
	    	var dpe = document.getElementById("punchoutSpecialDropdown");
	    	var username = dpe.value;
	    	const queryString = window.location.search;
	    	const urlParams = new URLSearchParams(queryString);
	    	var password = "logonPassword=" + urlParams.get("logonPassword");
	    	var HOOKURL = "HOOK_URL=" + urlParams.get("HOOK_URL");
	    	var logonId = "logonId=" + username;
	    	var poStoreId = "storeId=" + urlParams.get("storeId");
	    	var poCatalogId = "catalogId=" + urlParams.get("catalogId");
	    	var poLangId = "langId=" + urlParams.get("langId");
	    	var punchOutURL = baseURL + "/PunchOutLogon?" + poCatalogId + "&" + poLangId + "&" + poStoreId + "&" + logonId + "&" + password + "&" + HOOKURL;
	    	document.getElementById("punchOutGoButton").href=punchOutURL; 
	    },
}; 